import { combineReducers } from 'redux'
import reduceReducers from 'reduce-reducers'
import update from 'immutability-helper'
import { emptyObject } from '../../common/v5/constants'
import {
  keyDeleteAdminAgentsAccessKeys,
  keyGetAdminAgentsAccessKeys,
  keySaveAdminAgentsAccessKeys
} from '../constants/keys'
import {
  asyncInitState,
  asyncDoneActionType,
  createReducer,
  createReducerSubBranch,
  defAsyncReducer,
  normalizeRemoverUpdater
} from '../util'
import {
  BRANCH_KEYS,
  adminAsyncMap,
  adminStateName
} from '../selectors/agentAccessKey'

const done = key => asyncDoneActionType(adminAsyncMap[key])

const _listReducers = {
  [done(keyDeleteAdminAgentsAccessKeys)]: (
    state,
    { payload: { data: { access_key_id, deleted }, param: { id, key } } }
  ) => {
    if (access_key_id !== key) {
      console.log(`error as access key id ${access_key_id} not match ${key}`)
      return state
    } else if (!deleted) {
      console.log(`can't delete agent ${id} ${key} server return false deleted`)
      return state
    }
    const keys = state[id]
    if (!keys) {
      console.log(`error no keys data for agent ${id}`)
      return state
    }
    const updater = normalizeRemoverUpdater(access_key_id, keys)
    if (!updater) {
      return state
    }
    return update(state, { [id]: updater })
  },
  [done(keyGetAdminAgentsAccessKeys)]: (
    state,
    { payload: { data, param: { id } } }
  ) => update(state, { [id]: { $set: data } }),
  [done(keySaveAdminAgentsAccessKeys)]: (
    state,
    { payload: { data: serverData, param: { id, key } } }
  ) => {
    const { access_key_id } = serverData
    if (typeof key === 'undefined') {
      // create
      return update(
        state, {
          [id]: {
            allIds: { $push: [access_key_id] },
            byId: { [access_key_id]: { $set: serverData } }
          }
        }
      )
    }
    return state
  }
}

export const admin = combineReducers({
  [adminStateName(keyDeleteAdminAgentsAccessKeys)]: defAsyncReducer(
    keyDeleteAdminAgentsAccessKeys,
    asyncInitState
  ),
  [adminStateName(keyGetAdminAgentsAccessKeys)]: defAsyncReducer(
    keyGetAdminAgentsAccessKeys,
    asyncInitState
  ),
  [adminStateName(keySaveAdminAgentsAccessKeys)]: defAsyncReducer(
    keySaveAdminAgentsAccessKeys,
    asyncInitState
  ),
  [BRANCH_KEYS]: createReducer(emptyObject, _listReducers)
})
