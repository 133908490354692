import update from 'immutability-helper';
import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';
import each from 'lodash/each';
import { connectRouter} from 'connected-react-router';
import { orgArea, orgCompleteListsArea } from '../../common/globals';
import call from './call';
import {
	DOMAIN_COLLABORATION_LIST,
	DOMAIN_HISTORY,
	createDataSyncReducer,
	createSetupCondtionReducer
} from './wire';
import workflow, {
	app as workflowApp
	, domain as workflowDomain
	, rootReducer as workflowRoot
} from './workflow';
import errand, {
	app as errandApp,
	domain as errandDomain
} from './errand';
import { admin } from './admin';
import search from './search';
import internalMessage from './internalMessage';
import chat from './chat';
import ckeditor from './cke';
import notification from './notification';
import print from './print';
import errand_export from './export';
import {
	knowledgeBaseReducer
	, searchAnswer
} from './knowledgebase';
import {
	organisationReducer
} from './organisations';
import statistics from './statistics';
import { header, menu as menus, footer } from './hmf';
import {
	addNormalizedDomain,
	createReducer,
	createReducerSubBranch,
	createSetPayloadReducer,
	NEVER_EXPIRED_CACHE,
	DO_NOT_CACHE
} from '../util';
import {
	ADD_DOMAIN_DATA,
	INVALIDATE_DOMAIN_CACHE,
	NORMALIZED_DOMAIN_OPERATIONS,
	SHOW_INSERT_PROTECT,
	SHOW_EMAIL_TEST_POPUP,
	SET_UP_APP_CONSTANTS,
} from '../constants/constants';
import { hasPrefix, isV5page } from '../../common/helpers';
import { V5 } from '../../common/path';
import {
	ACT_ANSWERED
	, ACT_CLOSED
	, ACT_FORWARDED
	, ACT_SAVE
	, ACT_UNKNOWN
	, D_ERRAND_NOTES
	, D_BASIC_ERRANDS
	, D_CLIENT_AVATAR
	, D_AREAS
	, D_EE_EDIT
	, D_EXTENDED
	, D_FORWARD_AGENTS
	, D_HISTORY
	, D_TAGS_INFO
	, M_PREFERENCES
	, MM_ADMINISTRATION
	, MM_EXPORT
	, MM_MESSAGE
	, MM_SEARCH
	, MM_SEARCHANSWERS
	, MM_STATISTICS
	, MM_WORKFLOW
	, RC_CHAT
	, RC_EMAIL
	, RC_FACEBOOK
	, RC_INSTAGRAM
	, RC_LINE
	, RC_LINKEDIN
	, RC_SMS
	, RC_VOICE
	, RC_TWITTER
	, RC_VK
	, RC_YOUTUBE
	, RC_WHATSAPP
	, RC_TELEGRAM
	, RC_VIBER
	, RC_GOOGLEPLAY
	, RC_FORM
	, RC_CHATBOT
	, RC_SLACK
	, RC_TRUSTPILOT
	, RC_MSTEAMS
	, RC_JIRA
	, RC_GOOGLEREVIEW
	, RC_GOOGLECHAT
	, MM_EXPORT_CALL
	, MM_LIVE_REPORTS
} from '../../common/v5/constants';
import { BRANCH_NAME as CONSTANTS_BRANCH } from '../selectors/c3constants';
import { agentStore } from './agents';
import { app as appC3constants } from './c3constants';


const ONE_SECOND = 1000
	, ONE_MINUTE = ONE_SECOND*60
	, ONE_HOUR = ONE_MINUTE*60
	, LONG_CACHE_DURATION = ONE_HOUR // 1 hour
	, MID_CACHE_DURATION = 10 * ONE_MINUTE // 10 minutes
	, SHORT_CACHE_DURATION = ONE_MINUTE // 1 minute
	;
// Domain data - https://redux.js.org/docs/recipes/reducers/BasicReducerStructure.html#basic-state-shape
// There isn't any specific rule to use this branch of state. However, any
// domain data that has the possible to be modified by different branch of state
// then domain data branch is the way to go as it uses reduceReducers to
// combine the state branch. For example, many MCAM AJAX calls from errand state
// branch carry the counters that can be used to update existing my/new counters
// and the same goto workflow state branch which also trigger MCAM AJAX. So,
// counters should be put under domain branch state and update using the domain
// reducers. The only disadvantage I am aware of when using domain branch is
// the code will lack of seperation as multipart of branches can update the
// domain branch.
const initDomain = {
	counters: {
		new: {count: 0, colour: '#DC80000', total: 0},
		my: {count: 0, colour: '#DC80000', total: 0}
	}
	, [D_AREAS]: {
		byId: {},
		expiry: {},
		cacheTerm: MID_CACHE_DURATION,
		allIds: []
	}
	, [D_BASIC_ERRANDS]: {
		// this basic errands cached all basic errands like errand data
		// structure which in Golang backend generate with GenerateErrandData.
		// This include load basic errand, errand list, other contacts, etc.
		// However, it is known there even though they all share the same
		// generation code but they do not share same result because
		// GenerateErrandData requires errands to pass into the function and
		// those errands sometime created with OSM function with Complete view
		// but sometime with ErrandInfo. This field 'answeredDate' can not be
		// reliably obtained because both timestampClosed and
		// timestampLastAnswered that are needed to generate answeredDate field
		// are not appear in ErrandInfo view.
		byId: {},
		expiry: {},
		cacheTerm: SHORT_CACHE_DURATION,
		allIds: []
	}
	, [D_ERRAND_NOTES]: {
		byId: {},
		expiry: {},
		cacheTerm: SHORT_CACHE_DURATION,
		allIds: []
	}
	, [D_EXTENDED]: {
		byId: {},
		expiry: {},
		cacheTerm: MID_CACHE_DURATION,
		allIds: []
	}
	, [D_HISTORY]: {
		byId: {} // by errand-thread-id instead of errand-id.
		, expiry: {}
		, cacheTerm: SHORT_CACHE_DURATION
		, allIds: []
	}
	, [D_TAGS_INFO]: {
		byId: {},
		expiry: {},
		cacheTerm: NEVER_EXPIRED_CACHE,
		allIds: []
	}
	, expertQueries: {
		byId: {}, // normalized by errand ID
		expiry: {},
		cacheTerm: MID_CACHE_DURATION,
		allIds: []
	}
	, externalExpertList: {
		byId: {}, // normalized by errand ID
		expiry: {},
		cacheTerm: SHORT_CACHE_DURATION,
		allIds: []
	}
	, externalExpertThreads: {
		byId: {}, // normalized by EE's thread ID
		expiry: {},
		cacheTerm: MID_CACHE_DURATION,
		allIds: []
	}
	, [D_EE_EDIT]: {
		byId: {}, // normalized by 'errandID:queryID:answerID'
		expiry: {},
		cacheTerm: SHORT_CACHE_DURATION,
		allIds: []
	}
	, currentErrandList: { // TODO: redundant temporary can be removed
		opr: {},
		allSelected: false, // mak: TODO remove
		order: [],
		totalErrandCount: 0,
		totalUpperCount: 0,
		totalGroupedErrands: 0,
		totalVIPCount: 0,
		context: ''
	}
	, errandKnowledgeBase: {
		byId: {}, // normalized by errand ID
		expiry: {},
		cacheTerm: MID_CACHE_DURATION,
		allIds: []
	}
	, previewData: {
		byId: {}, //normalized by errand ID
		expiry: {},
		cacheTerm: MID_CACHE_DURATION,
		allIds: []
	}
	, [D_CLIENT_AVATAR]: {
		byId: {}, // normalized by workflow_mailorigin_id
		expiry: {},
		cacheTerm: NEVER_EXPIRED_CACHE,
		allIds: []
	}
	, [D_FORWARD_AGENTS]: {
		byId: {}, // normalized by area ID
		expiry: {},
		cacheTerm: LONG_CACHE_DURATION,
		allIds: []
	}
};

const domainOperationsReducer = (state, action) => {
	if(action.type === NORMALIZED_DOMAIN_OPERATIONS) {
	} else if(action.type === ADD_DOMAIN_DATA) {
		const {domain, time, id, data} = action.payload;
		return addNormalizedDomain(state, domain, id, data, time);
	}
	return state;
};

const invalidateCacheReducer = (state, action) => {
	if(action.type === INVALIDATE_DOMAIN_CACHE) {
		const {id, domain} = action.payload;
		return update(state, {[domain]: {expiry: {[id]: {$set: 0}}}});
	}
	return state;
};

const domain = reduceReducers(
	createReducer(initDomain, workflowDomain),
	createReducer(initDomain, errandDomain),
	invalidateCacheReducer,
	domainOperationsReducer,
	createReducerSubBranch(
		(state, value) => update(state, { externalExpertList: { $set: value } }),
		state => state.externalExpertList,
		createDataSyncReducer(createSetupCondtionReducer(
			(state, action, checker, changer) => {
				const result = checker(state, action)
				if (!result) {
					return state
				}
				return changer(state, result.index)
			},
			DOMAIN_COLLABORATION_LIST
		))
	),
	createReducerSubBranch(
		(state, value) => update(state, { [D_HISTORY]: { $set: value } }),
		state => state[D_HISTORY],
		createDataSyncReducer(createSetupCondtionReducer(
			(state, action, checker, changer) => {
				const result = checker(state, action)
				if (!result) {
					return state
				}
				return changer(state, result.index)
			},
			DOMAIN_HISTORY
		))
	)
);

// modals
const initModals = {
	showInsertProtect: false,
	showEmailTestPopup: false
};

const _modalReducers = {
	[SHOW_INSERT_PROTECT]: createSetPayloadReducer("showInsertProtect"),
	[SHOW_EMAIL_TEST_POPUP]: createSetPayloadReducer("showEmailTestPopup")
};

const servicesLink = 
	{
		[Workflow.Errand.SERVICE_EMAIL]: RC_EMAIL,
		[Workflow.Errand.SERVICE_MANUAL]: RC_EMAIL,
		[Workflow.Errand.SERVICE_CHAT]: RC_CHAT,
		[Workflow.Errand.SERVICE_FACEBOOK]: RC_FACEBOOK,
		[Workflow.Errand.SERVICE_TWITTER]: RC_TWITTER,
		[Workflow.Errand.SERVICE_LINKEDIN]: RC_LINKEDIN,
		[Workflow.Errand.SERVICE_SMS]: RC_SMS,
		[Workflow.Errand.SERVICE_MANUAL_SMS]: RC_SMS,
		[Workflow.Errand.SERVICE_MANUAL_FACEBOOK]: RC_FACEBOOK,
		[Workflow.Errand.SERVICE_LINE]: RC_LINE,
		[Workflow.Errand.SERVICE_MANUAL_LINE]: RC_LINE,
		[Workflow.Errand.SERVICE_VKONTAKTE]: RC_VK,
		[Workflow.Errand.SERVICE_MANUAL_VKONTAKTE]: RC_VK,
		[Workflow.Errand.SERVICE_VOICE]: RC_VOICE,
		[Workflow.Errand.SERVICE_MANUAL_VOICE]: RC_VOICE,
		[Workflow.Errand.SERVICE_INSTAGRAM]: RC_INSTAGRAM,
		[Workflow.Errand.SERVICE_FORM]: RC_FORM,
		[Workflow.Errand.SERVICE_MANUAL_TWITTER]: RC_TWITTER,
		[Workflow.Errand.SERVICE_MANUAL_YOUTUBE]: RC_YOUTUBE,
		[Workflow.Errand.SERVICE_YOUTUBE]: RC_YOUTUBE,
		[Workflow.Errand.SERVICE_WHATSAPP]: RC_WHATSAPP,
		[Workflow.Errand.SERVICE_GOOGLEPLAY]: RC_GOOGLEPLAY,
		[Workflow.Errand.SERVICE_CHATBOT]: RC_CHATBOT,
		[Workflow.Errand.SERVICE_SLACK]: RC_SLACK,
		[Workflow.Errand.SERVICE_TELEGRAM]: RC_TELEGRAM,
		[Workflow.Errand.SERVICE_TRUSTPILOT]: RC_TRUSTPILOT,
		[Workflow.Errand.SERVICE_VIBER]: RC_VIBER,
		[Workflow.Errand.SERVICE_MICROSOFT_TEAMS]: RC_MSTEAMS,
		[Workflow.Errand.SERVICE_GOOGLECHAT]: RC_GOOGLECHAT,
		[Workflow.Errand.SERVICE_JIRA]: RC_JIRA,
		[Workflow.Errand.SERVICE_GOOGLEREVIEW]: RC_GOOGLEREVIEW,
		[Workflow.Errand.SERVICE_MANUAL_LINKEDIN]: RC_LINKEDIN,
		[Workflow.Errand.SERVICE_MANUAL_WHATSAPP]: RC_WHATSAPP,
	} ;

// NOTE: to reduce boiler-plate, using object key as ordering is NOT correct as
// JS specification mentioned object key order can be randomized. BUT want to
// avoid writing twice and so far no browser show the randomized order.
const servicesOrder = () => {
	const order = []
	each(servicesLink, (_, type) => { order.push(parseInt(type, 10)) })
	return order
};

let byId = {}
	, byType = {}
	, byName = {}
	, actionsLink
	, mainMenuById = {}
	, menuAdmin
	, serverConstants
	;
if (isV5page()) {
	serverConstants = constants;
	// $.each(servicesLink, (k, channel) => {
	// 	const { id, name } = ServicesName[k]
	// 		, service = {name, channel} // TODO: use i18n.L here?
	// 		;
	// 	byId[id] = service;
	// 	byType[k] = service;
	// 	byName[name.toLowerCase()] = parseInt(k, 10);
	// });
	const wfActions = Workflow.Action;
	actionsLink = {
		[wfActions.CHAT_FINISH]: ACT_CLOSED
		, [wfActions.CHAT_EXPIRE]: ACT_CLOSED
		, [wfActions.CLOSE]: ACT_CLOSED
		, [wfActions.CLOSE_NO_ANSWER]: ACT_CLOSED
		, [wfActions.SAVE]: ACT_SAVE
		, [wfActions.ANSWER]: ACT_ANSWERED
		, [wfActions.EXTERNAL_FORWARD]: ACT_FORWARDED
	};
	// normalized menu
	const wantedMenus = {
			[MM_ADMINISTRATION]: true
			, [MM_EXPORT]: true
			, [MM_EXPORT_CALL]: true
			, [MM_MESSAGE]: true
			, [MM_SEARCH]: true
			, [MM_SEARCHANSWERS]: true
			, [MM_STATISTICS]: true
			, [MM_WORKFLOW]: true
			, [MM_LIVE_REPORTS]: true
		}
		, checkAndAssign = (menu, result) => {
			const { id } = menu;
			if (wantedMenus[id]) {
				result[id] = menu;
			}
		}
		, normaliseMenus = (menus, mainMenu, result) => {
			let admin;
			$.each(menus, (i, v) => {
				let rootMenu;
				if (mainMenu) {
					rootMenu = mainMenu;
				} else {
					rootMenu = v;
				}
				if (v.id === MM_ADMINISTRATION) {
					admin = v;
				}
				checkAndAssign(v, result);
				if (v.sub && v.sub.length > 0) {
					normaliseMenus(v.sub, rootMenu, result);
				}
			});
			return admin;
		}
		;
	menuAdmin = normaliseMenus(menu.leftMenu, null, mainMenuById);
	normaliseMenus(menu.rightMenu, null, mainMenuById);
}

const services = {
	allTypes: servicesOrder()
	, byId
	, byType
	, byName
	, constants: {Workflow: Workflow}
	, manual: {
		[Workflow.Errand.SERVICE_MANUAL]: Workflow.Errand.SERVICE_EMAIL
		, [Workflow.Errand.SERVICE_MANUAL_SMS]: Workflow.Errand.SERVICE_SMS
		, [Workflow.Errand.SERVICE_MANUAL_FACEBOOK]: Workflow.Errand.SERVICE_FACEBOOK
		, [Workflow.Errand.SERVICE_MANUAL_LINE]: Workflow.Errand.SERVICE_LINE
		, [Workflow.Errand.SERVICE_MANUAL_VKONTAKTE]: Workflow.Errand.SERVICE_VKONTAKTE
		, [Workflow.Errand.SERVICE_MANUAL_VOICE]: Workflow.Errand.SERVICE_VOICE
		, [Workflow.Errand.SERVICE_MANUAL_TWITTER]: Workflow.Errand.SERVICE_TWITTER
		, [Workflow.Errand.SERVICE_MANUAL_YOUTUBE]: Workflow.Errand.SERVICE_YOUTUBE
		, [Workflow.Errand.SERVICE_MANUAL_LINKEDIN]: Workflow.Errand.SERVICE_LINKEDIN
		, [Workflow.Errand.SERVICE_MANUAL_WHATSAPP]: RC_WHATSAPP
	}
};

//Perhaps with the changes this should not be named "chatConfig"
let chatConfigChannels = [
	Workflow.Errand.SERVICE_CHAT,
	Workflow.Errand.SERVICE_FACEBOOK,
	Workflow.Errand.SERVICE_TWITTER,
	Workflow.Errand.SERVICE_LINE,
	Workflow.Errand.SERVICE_WHATSAPP,
	Workflow.Errand.SERVICE_VIBER,
	Workflow.Errand.SERVICE_TELEGRAM,
	Workflow.Errand.SERVICE_MAIL,
	Workflow.Errand.SERVICE_SIP_VOICE,
	Workflow.Errand.SERVICE_INSTAGRAM
];

// App data
const initServer = {
	actionsLink
	, menu
	, menuAdmin
	, mainMenuById
	, orgArea
	, orgCompleteListsArea
	, features //This come as template variable `features`
	, services
	, constants: serverConstants
	, errandViewOnly: typeof errandViewOnly !== "undefined" ? errandViewOnly : false
	, initialChatData: typeof initialChatData !== "undefined" ? initialChatData : null
	, defaultChatServices: chatConfigChannels,
};

const server = (state = initServer, action) => {
	return state;
};

let app = combineReducers({
	agentStore,
	call,
	[CONSTANTS_BRANCH]: appC3constants,
	header,
	menu: menus,
	modals: createReducer(initModals, _modalReducers),
	admin,
	ckeditor,
	workflow,
	errand,
	search,
	searchAnswer,
	internalMessage,
	export: errand_export,
	notification,
	print,
	footer,
	statistics,
	knowledgebase: knowledgeBaseReducer,
	organisations: organisationReducer,
	defaultChatServices: chatConfigChannels,
});

app = reduceReducers(
	app
	, createReducer({}, errandApp)
	, createReducer({}, workflowApp)
);

const initExternal = {
	externalManual: false,
	openedByExternal: ''
};

const external = (state = initExternal, action) => {
	return state;
};

const createRootReducer = history => {
	const r = combineReducers({
		app
		, chat
		, domain
		, external
		, router: connectRouter(history)
		, server: (state = initServer, {type, payload}) =>{
			//TODO(mujibur): It could be a good place in future to refactor for avoiding the global variables
			//for now to make it work, I gone through this way. because here the variables are re-introduced with
			//state mutation
			//console.log('type =', type);
			switch (type) {
				case SET_UP_APP_CONSTANTS:
					payload.loading = true;
					cflag = payload.cflag;
					cflag.IsActive = function( flag ){
						var c = cflag
						, chunks
						, i
						, v
						;
						if ( !flag ) {
							return false;
						}
						chunks = flag.split(".");
						for (i=0; i<chunks.length; i++) {
							v = c[chunks[i]];
							switch (typeof v) {
							case 'object':
								c = v;
								break;
							case 'boolean':
								return v;
							default:
								return false;
							}
						}
						return false;
					}
					payload.cflag = cflag;
					//TODO(mujib): need to fetch it via API call
					payload.errandViewOnly = (typeof errandViewOnly !== "undefined" ? errandViewOnly : false);
					const servicesLink = 
					{
						[Workflow.Errand.SERVICE_EMAIL]: RC_EMAIL,
						[Workflow.Errand.SERVICE_MANUAL]: RC_EMAIL,
						[Workflow.Errand.SERVICE_CHAT]: RC_CHAT,
						[Workflow.Errand.SERVICE_FACEBOOK]: RC_FACEBOOK,
						[Workflow.Errand.SERVICE_TWITTER]: RC_TWITTER,
						[Workflow.Errand.SERVICE_LINKEDIN]: RC_LINKEDIN,
						[Workflow.Errand.SERVICE_SMS]: RC_SMS,
						[Workflow.Errand.SERVICE_MANUAL_SMS]: RC_SMS,
						[Workflow.Errand.SERVICE_MANUAL_FACEBOOK]: RC_FACEBOOK,
						[Workflow.Errand.SERVICE_LINE]: RC_LINE,
						[Workflow.Errand.SERVICE_MANUAL_LINE]: RC_LINE,
						[Workflow.Errand.SERVICE_VKONTAKTE]: RC_VK,
						[Workflow.Errand.SERVICE_MANUAL_VKONTAKTE]: RC_VK,
						[Workflow.Errand.SERVICE_VOICE]: RC_VOICE,
						[Workflow.Errand.SERVICE_MANUAL_VOICE]: RC_VOICE,
						[Workflow.Errand.SERVICE_INSTAGRAM]: RC_INSTAGRAM,
						[Workflow.Errand.SERVICE_FORM]: RC_FORM,
						[Workflow.Errand.SERVICE_MANUAL_TWITTER]: RC_TWITTER,
						[Workflow.Errand.SERVICE_MANUAL_YOUTUBE]: RC_YOUTUBE,
						[Workflow.Errand.SERVICE_YOUTUBE]: RC_YOUTUBE,
						[Workflow.Errand.SERVICE_WHATSAPP]: RC_WHATSAPP,
						[Workflow.Errand.SERVICE_GOOGLEPLAY]: RC_GOOGLEPLAY,
						[Workflow.Errand.SERVICE_CHATBOT]: RC_CHATBOT,
						[Workflow.Errand.SERVICE_SLACK]: RC_SLACK,
						[Workflow.Errand.SERVICE_TELEGRAM]: RC_TELEGRAM,
						[Workflow.Errand.SERVICE_TRUSTPILOT]: RC_TRUSTPILOT,
						[Workflow.Errand.SERVICE_VIBER]: RC_VIBER,
						[Workflow.Errand.SERVICE_MICROSOFT_TEAMS]: RC_MSTEAMS,
						[Workflow.Errand.SERVICE_GOOGLECHAT]: RC_GOOGLECHAT,
						[Workflow.Errand.SERVICE_JIRA]: RC_JIRA,
						[Workflow.Errand.SERVICE_GOOGLEREVIEW]: RC_GOOGLEREVIEW,
						[Workflow.Errand.SERVICE_MANUAL_LINKEDIN]: RC_LINKEDIN,
						[Workflow.Errand.SERVICE_MANUAL_WHATSAPP]: RC_WHATSAPP,
					} ;

					const servicesOrder = () => {
						const order = []
						each(servicesLink, (_, type) => { order.push(parseInt(type, 10)) })
						return order
					};
					let menu = payload.menu;
					
					let manual = {
						[Workflow.Errand.SERVICE_MANUAL]: Workflow.Errand.SERVICE_EMAIL
						, [Workflow.Errand.SERVICE_MANUAL_SMS]: Workflow.Errand.SERVICE_SMS
						, [Workflow.Errand.SERVICE_MANUAL_FACEBOOK]: Workflow.Errand.SERVICE_FACEBOOK
						, [Workflow.Errand.SERVICE_MANUAL_LINE]: Workflow.Errand.SERVICE_LINE
						, [Workflow.Errand.SERVICE_MANUAL_VKONTAKTE]: Workflow.Errand.SERVICE_VKONTAKTE
						, [Workflow.Errand.SERVICE_MANUAL_VOICE]: Workflow.Errand.SERVICE_VOICE
						, [Workflow.Errand.SERVICE_MANUAL_TWITTER]: Workflow.Errand.SERVICE_TWITTER
						, [Workflow.Errand.SERVICE_MANUAL_YOUTUBE]: Workflow.Errand.SERVICE_YOUTUBE
						, [Workflow.Errand.SERVICE_MANUAL_LINKEDIN]: Workflow.Errand.SERVICE_LINKEDIN
						, [Workflow.Errand.SERVICE_MANUAL_WHATSAPP]: RC_WHATSAPP
					}
					let byId = {}
					, byType = {}
					, byName = {}
					, actionsLink
					, mainMenuById = {}
					, menuAdmin
					;
					const wfActions = Workflow.Action;
					actionsLink = {
						[wfActions.CHAT_FINISH]: ACT_CLOSED
						, [wfActions.CHAT_EXPIRE]: ACT_CLOSED
						, [wfActions.CLOSE]: ACT_CLOSED
						, [wfActions.CLOSE_NO_ANSWER]: ACT_CLOSED
						, [wfActions.SAVE]: ACT_SAVE
						, [wfActions.ANSWER]: ACT_ANSWERED
						, [wfActions.EXTERNAL_FORWARD]: ACT_FORWARDED
					};
					$.each(servicesLink, (k, channel) => {
						const { id, name } = ServicesName[k]
							, service = {name, channel} // TODO: use i18n.L here?
							;
						byId[id] = service;
						byType[k] = service;
						byName[name.toLowerCase()] = parseInt(k, 10);
					});
					const wantedMenus = {
						[MM_ADMINISTRATION]: true
						, [MM_EXPORT]: true
						, [MM_MESSAGE]: true
						, [MM_SEARCH]: true
						, [MM_SEARCHANSWERS]: true
						, [MM_STATISTICS]: true
						, [MM_WORKFLOW]: true
						, [MM_LIVE_REPORTS]: true
					}
					, checkAndAssign = (m, result) => {
						const { id } = m;
						if (wantedMenus[id]) {
							result[id] = m;
						}
					}
					, normaliseMenus = (menus, mainMenu, result) => {
						let admin;
						$.each(menus, (i, v) => {
							let rootMenu;
							if (mainMenu) {
								rootMenu = mainMenu;
							} else {
								rootMenu = v;
							}
							if (v.id === MM_ADMINISTRATION) {
								admin = v;
							}
							checkAndAssign(v, result);
							if (v.sub && v.sub.length > 0) {
								normaliseMenus(v.sub, rootMenu, result);
							}
						});
						return admin;
					}
					;
					menuAdmin = normaliseMenus(menu.leftMenu, null, mainMenuById);
					normaliseMenus(menu.rightMenu, null, mainMenuById);

					services.allTypes = servicesOrder();
					services.byId = byId;
					services.byType = byType;
					services.byName = byName;
					services.manual = manual;
					services.actionsLink = actionsLink;
					services.mainMenuById = mainMenuById;
					services.menuAdmin = menuAdmin;
					services.serverConstants = constants;
					services.constants = {Workflow: Workflow}
					payload.services = services;
					payload.actionsLink = actionsLink;
					payload.menuAdmin = menuAdmin;
					payload.mainMenuById = mainMenuById;
					activeUserId = payload.activeUserId;
					//TODO(mujibur): defaultChatServices better bring from backend. So, it can single
					//source.
					payload.defaultChatServices = [Workflow.Errand.SERVICE_CHAT,
						Workflow.Errand.SERVICE_FACEBOOK,
						Workflow.Errand.SERVICE_TWITTER,
						Workflow.Errand.SERVICE_LINE,
						Workflow.Errand.SERVICE_WHATSAPP,
						Workflow.Errand.SERVICE_VIBER,
						Workflow.Errand.SERVICE_TELEGRAM,
						Workflow.Errand.SERVICE_INSTAGRAM,
						Workflow.Errand.SERVICE_EMAIL,
						Workflow.Errand.SERVICE_SIP_VOICE];
					//console.log('Upadted  =', state);
					const newState = Object.assign({}, state, payload);
					console.log('newState =', newState);
					return newState;
				default:
					return state;
			}
		}
	});
	return reduceReducers(r, workflowRoot);
};

export default createRootReducer;
