import update from 'immutability-helper';
import {
    createReducer,
} from '../util';
import {
	WS_EVENT,
    evtNOTIFICATION_MESSAGE,
    evtNOTIFICATION_MESSAGE_DISMISS,
    evtNOTIFICATION_MESSAGE_READ,
    evtNOTIFICATION_MESSAGE_UNREAD,
    SHOW_ALL_NOTIFICATION,
    SHOW_POPUP_NOTIFICATION,
    HIDE_POPUP_NOTIFICATION,
    ADD_NEW_POPUP_NOTIFICATION,
    SET_NOTIFICATION_TAB,
    SHOW_ANNOUNCEMENT,
    UPDATE_ANNOUNCEMENT,
} from '../constants/constants';
import {
    NOTIFICATION_WITH_KEEP,
    NOTIFICATION_WITHOUT_KEEP,
    ANM_TAB_NOTIFICATION
} from '../../common/v5/constants';
import {
	getExtQueueType
	, notifyOS
	, getNotificationMessage
} from '../../common/v5/helpers';

const handleNewNotificationMessage = (state, action) => {
    const {messages, blueBubbleNotification, msgToSkip} = action.payload;
    let newNoticeCount = state.uiData.noticeCount
    , maxMessageId = state.maxMessageId
    , newMessages = {}
    , newMsgIdByErrand = {}
    , newPendingPopupMessage = [...state.uiData.pendingPopupMessage];

    $.each(messages, (i, msg) => {
        if (state.messages[msg.id]) {
            return;
        }
		let skipThisMsg = false;
		if (typeof msgToSkip !== 'undefined' && msgToSkip){
			for (var i = 0; i < msgToSkip.length; i++) {
				if(msgToSkip[i] == msg.id) {
					skipThisMsg = true;
					break;
				}
			}
		}
        if (msg.id > maxMessageId) {
            maxMessageId = msg.id;
        }
        let msgObj = JSON.parse(msg.text);
        if (!msgObj.page && msgObj.linkTo) {
            newMsgIdByErrand[msgObj.linkTo] = msg.id;
        }
        newMessages[msg.id] = Object.assign(msg, {msg: msgObj});
        if (!msg.read) {
            newNoticeCount++;
            if ((typeof blueBubbleNotification === "undefined" || blueBubbleNotification || getExtQueueType() !== "") && skipThisMsg == false) {
                // Add new arrived notification messages into queue to be display in blue bubble box
				if(features.browserOsNotify == true) {
					notifyOS("Cention",getNotificationMessage(msgObj),
						msg.sent);
				} else {
                newPendingPopupMessage.push({
                    id: msg.id,
                    msg: msgObj,
                    msgType: NOTIFICATION_WITH_KEEP,
                })
				}
            }
        }
    });
    return update(state, {
        messages: {$merge: newMessages},
        msgIdByErrand: {$merge: newMsgIdByErrand},
        maxMessageId: {$set: maxMessageId},
        uiData: {
            noticeCount: {$set: newNoticeCount},
            pendingPopupMessage: {$set: newPendingPopupMessage},
        }
    });
}

const handleNotificationMessageDismissed = (state, action) => {
    let ids = JSON.parse("[" + action.payload.ids + "]")
    , newMessages = Object.assign({}, state.messages)
    , newMsgIdByErrand = Object.assign({}, state.msgIdByErrand)
    , newNoticeCount = state.uiData.noticeCount;

    $.each(ids, (i, id) => {
        if (newMessages[id]) {
            if (!newMessages[id].read) {
                newNoticeCount--;
            }
            let msg = newMessages[id].msg;
            if (msg && !msg.page && msg.linkTo) {
                delete newMsgIdByErrand[msg.linkTo];
            }
        }
        delete newMessages[id];
    });
    return update(state, {
        messages: {$set: newMessages},
        msgIdByErrand: {$set: newMsgIdByErrand},
        uiData: {noticeCount: {$set: newNoticeCount}}
    });
}

const handleNotificationMessageRead = (state, action) => {
    let ids = JSON.parse("[" + action.payload.ids + "]")
    , newMessages = Object.assign({}, state.messages)
    , newNoticeCount = state.uiData.noticeCount;

    if(Object.entries(newMessages).length === 0){
        return state;
    }
    $.each(ids, (i, id) => {
        if (newMessages[id] && !newMessages[id].read) {
            newMessages = update(newMessages, {[id]: {read: {$set: true}}});
            newNoticeCount--;
        }
    });
    return update(state, {
        messages: {$set: newMessages},
        uiData: {noticeCount: {$set: newNoticeCount}}
    });
}

const handleNotificationMessageUnread = (state, action) => {
    let ids = JSON.parse("[" + action.payload.ids + "]")
    , newMessages = Object.assign({}, state.messages)
    , newNoticeCount = state.uiData.noticeCount;

    if(Object.entries(newMessages).length === 0){
        return state;
    }
    $.each(ids, (i, id) => {
        if (newMessages[id] && newMessages[id].read) {
            newMessages = update(newMessages, {[id]: {read: {$set: false}}});
            newNoticeCount++;
        }
    });
    return update(state, {
        messages: {$set: newMessages},
        uiData: {noticeCount: {$set: newNoticeCount}}
    });
}

const handleNotification = (state, action) => {
	if (action.type != WS_EVENT) {
		return state;
	}
	let packet = action.packet
	, args = packet.args
	;
	if(typeof args !== 'undefined'){
		switch (packet.event) {
			case evtNOTIFICATION_MESSAGE:
				return handleNewNotificationMessage(state, {payload: args[0]});
			case evtNOTIFICATION_MESSAGE_DISMISS:
				return handleNotificationMessageDismissed(state, {payload: args[0]});
			case evtNOTIFICATION_MESSAGE_READ:
				return handleNotificationMessageRead(state, {payload: args[0]});
			case evtNOTIFICATION_MESSAGE_UNREAD:
				return handleNotificationMessageUnread(state, {payload: args[0]});
  		}
	}
	return state;
};

const handleAddNewPopupMessage = (state, action) => {
    const {text, msgType, type} = action.payload;
    let lastPopupMessageId = state.lastPopupMessageId
    , newPendingPopupMessage = [...state.uiData.pendingPopupMessage];

    ++lastPopupMessageId;

    // Handle blue bubble message wihtout insert into notification list
    newPendingPopupMessage.push({
        id: 's' + lastPopupMessageId,
        msg: JSON.parse(text),
        msgType: msgType,
        type: type
    })
    return update(state, {
        lastPopupMessageId: {$set: lastPopupMessageId},
        uiData: {
            pendingPopupMessage: {$set: newPendingPopupMessage},
        }
    });
};

const handleHidePopupMessage = (state, action) => {
    let {id, currentTime} = action.payload
    , newCurrentPopupMessage = state.uiData.currentPopupMessage.filter(msg =>
        ((id !== 0 && msg.id !== id) || // by specific id
        (id === 0 && (msg.expireTime == 0 || msg.expireTime > currentTime)))); // by expire timeout

    return update(state, {
        uiData: {
            currentTime: {$set: currentTime},
            currentPopupMessage: {$set: newCurrentPopupMessage},
        }
    });
}

const handleShowPopupMessage = (state, action) => {
    let {timeout, currentTime} = action.payload
    , expireTime = currentTime + timeout
    , newPendingPopupMessage = [...state.uiData.pendingPopupMessage]
    , newCurrentPopupMessage = [...state.uiData.currentPopupMessage];

    // Max to show 2 blue bubble boxes at a time
    if (newCurrentPopupMessage.length < 2) {
        let slot = 2 - newCurrentPopupMessage.length;

        // More messages than the available slot
        if (newPendingPopupMessage.length > slot) {
            newPendingPopupMessage.forEach(msg => {
                if (msg.msgType == NOTIFICATION_WITHOUT_KEEP) {
                    let newMessage = Object.assign({}, msg, {expireTime: expireTime});
                    newCurrentPopupMessage.push(newMessage);
                }
            });
            // Update the queue by remove the processed message
            newPendingPopupMessage = newPendingPopupMessage.filter(msg => (msg.msgType != NOTIFICATION_WITHOUT_KEEP));

            if (newPendingPopupMessage.length == 1) {
                let newMessage = Object.assign({}, newPendingPopupMessage[0], {expireTime: expireTime});
                newCurrentPopupMessage.push(newMessage);

            // Lump all other notification into one single notification
            } else if (newPendingPopupMessage.length > 1) {
                let text = '{"text": "MsgSystemWaitingCount", "count": ' + newPendingPopupMessage.length.toString() + '}';
                newCurrentPopupMessage.push({
                    id: newPendingPopupMessage[0].id,
                    msg: JSON.parse(text),
                    msgType: newPendingPopupMessage[0].msgType,
                    expireTime: expireTime,
                    sum: true
                });
            }
        } else {
            // otherwise show each blue bubble box per notification message
            for(let i=0; i<newPendingPopupMessage.length; i++) {
                let newMessage = Object.assign({}, newPendingPopupMessage[i], {expireTime: expireTime});
                newCurrentPopupMessage.push(newMessage);
            }
        }
        // Reset the queue upon all messages processed
        newPendingPopupMessage = [];
    } else {
        // Always show notification that do not keep into notification list
        newPendingPopupMessage.forEach(msg => {
            if (msg.msgType == NOTIFICATION_WITHOUT_KEEP) {
                let newMessage = Object.assign({}, msg, {expireTime: expireTime});
                newCurrentPopupMessage.push(newMessage);
            }
        });
        // Update the queue by remove the processed message
        newPendingPopupMessage = newPendingPopupMessage.filter(msg => (msg.msgType != NOTIFICATION_WITHOUT_KEEP));
    }
    return update(state, {
        uiData: {
            currentTime: {$set: currentTime},
            pendingPopupMessage: {$set: newPendingPopupMessage},
            currentPopupMessage: {$set: newCurrentPopupMessage},
        }
    });
}

const handleToggleShowAllNotification = (state, action) => {
    let show = action.payload
    return update(state, {
        uiData: {
            showAll: {$set: show}
        }
    });
} 

const handleSetNotificationTab = (state, action) => {
    let tab = action.payload
    return update(state, {
        uiData: {
            tab : {$set: tab}
        }
    });
}

const handleToggleShowAnnouncement = (state, action) => {
    let show = action.payload
    return update(state, {
        uiData: {
            showAnnouncement: {$set: show}
        }
    });
}

const handleUpdateAnnouncement = (state, action) => {
        if ( action.type === UPDATE_ANNOUNCEMENT) {
        let p = action.payload;

        if (p.obj && p.obj.announcement){
            let announcement = p.obj.announcement
            let newSt = update(state, {
                uiData: {
                    announcement: {$set: announcement}
                }
            });

            return newSt
        }
    }
    return state;
}

const notificationReducers = {
    [WS_EVENT]: handleNotification,
    [ADD_NEW_POPUP_NOTIFICATION]: handleAddNewPopupMessage,
    [SHOW_ALL_NOTIFICATION]: handleToggleShowAllNotification,
    [SHOW_POPUP_NOTIFICATION]: handleShowPopupMessage,
    [HIDE_POPUP_NOTIFICATION]: handleHidePopupMessage,
    [SET_NOTIFICATION_TAB]: handleSetNotificationTab,
    [SHOW_ANNOUNCEMENT]: handleToggleShowAnnouncement,
    [UPDATE_ANNOUNCEMENT]: handleUpdateAnnouncement,
};

const notificationInitState = {
    messages: {},
    msgIdByErrand: {},
    maxMessageId: 0,
    lastPopupMessageId: 0,
    uiData: {
        currentTime: 0,
        pendingPopupMessage: [],
        currentPopupMessage: [],
        noticeCount: 0,
        showAll: false,
        tab: ANM_TAB_NOTIFICATION,
        showAnnouncement: false,
        announcement: []
    },
};

let notificationReducer = createReducer(notificationInitState, notificationReducers);

export default notificationReducer;
