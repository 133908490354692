import update from 'immutability-helper';
import {
    asyncDoneActionType
    , asyncRequestActionType
    , createReducer
} from '../util';
import { admin as AdminsMap } from '../selectors/admin';
import {
    KB_CHANGE_ACTIVE_TAB
    , KB_CREATE_NEW
    , KB_DELETE_LIST
    , KB_EDIT_CATEGORY
    , KB_EDIT_LIBRARY
    , KB_EDIT_QUESTION
    , KB_EDIT_SUGGESTION
    , KB_INSERT_NODE
    , KB_LOADING_LIBRARY
    , KB_REMOVE_NODE
    , KB_RESET_EDIT
    , KB_SAVE_ID
    , KB_SELECT_TREE_ROOT
    , SA_CHANGE_INPUT
    , SA_CHANGE_SEARCH_STATE
    , SA_CHANGE_SOURCE
    , KB_UPDATE_CHATBOTS
} from '../constants/constants';
import {
    keyGetKnowledgeBase
    , keyGetKnowledgeBaseCategory
    , keyGetKnowledgeBaseRoot
    , keyGetAdminLibrary
    , keyGetAdminLibraryCategory
    , keyGetAdminLibraryQuestion
    , keyGetAdminLibrarySuggestion
    , keyGetAdminLibraryRating
    , keyFetchLibraryList
    , keyFetchCategoryList
    , keyFetchQuestionList
    , keyFetchSuggestionList
    , keyFetchRatingList
    , keyFetchMoreLibraryList
    , keyFetchMoreCategoryList
    , keyFetchMoreQuestionList
    , keyFetchMoreSuggestionList
    , keyFetchMoreRatingList
} from '../constants/keys';
import {
    emptyArray
    , emptyObject
} from '../../common/v5/constants';

const request = key => asyncRequestActionType(AdminsMap[key]);
const done = key => asyncDoneActionType(AdminsMap[key]);

const initKnowledgeBase = {
    activeTab: 0,
    deletedId: 0,
    edit: {
        show: false,
        title: '',
        data: emptyObject,
        category: 0,
        library: 0,
        question: 0,
        suggestion: 0,
        chatbotIds: emptyArray,
        intentId: ""
    },
    insertedNode: 0,
    fetchingQuestion: false,
    knowledgeBaseCategory: emptyArray,
    knowledgeBaseTreeList: emptyArray,
    showMore: {
        library: false
        , category: false
        , question: false
        , rating: false
        , suggestion: false
    },
    removedNode: 0,
    roots: emptyArray,
    savedId: 0,
    selectedTreeRoot: 0,
    selectedTreeRootCategory: 0,
    selectedTreeRootLibrary: 0,
    tableList: {
        libraryList: []
        , categoryList: []
        , questionList: []
        , suggestionList: []
        , ratingList: []
    }
    , fetchingLibrary: false
};

const knowledgeBaseReducer = (st = initKnowledgeBase, act) => {
    if (act.type === KB_CHANGE_ACTIVE_TAB) {
        return update(st, {
            activeTab: { $set: act.payload }
        });
    } else if (act.type === KB_CREATE_NEW) {
        return update(st, {
            edit: {
                show: { $set: true },
                title: { $set: act.payload },
                data: { $set: {} }
            }
        });
    } else if (act.type === KB_DELETE_LIST) {
        return update(st, {
            deletedId: { $set: act.payload.id }
        });
    } else if (act.type === KB_SAVE_ID) {
        return update(st, {
            savedId: { $set: act.payload.id }
        });
    } else if (act.type === KB_RESET_EDIT) {
        return update(st, {
            edit: {
                show: { $set: false },
                title: { $set: '' },
                data: { $set: {} }
            }
        });
    } else if (act.type === KB_EDIT_LIBRARY) {
        return update(st, {
            edit: {
                show: { $set: true },
                category: { $set: 0 },
                library: { $set: act.payload },
                question: { $set: 0 },
                suggestion: { $set: 0 },
            }
        });
    } else if (act.type === KB_EDIT_CATEGORY) {
        return update(st, {
            edit: {
                category: { $set: act.payload },
                library: { $set: 0 },
                question: { $set: 0 },
                suggestion: { $set: 0 },
            }
        });
    } else if (act.type === KB_EDIT_QUESTION) {
        return update(st, {
            edit: {
                category: { $set: 0 },
                library: { $set: 0 },
                question: { $set: act.payload },
                suggestion: { $set: 0 },
            }
        });
    } else if (act.type === KB_EDIT_SUGGESTION) {
        return update(st, {
            edit: {
                category: { $set: 0 },
                library: { $set: 0 },
                question: { $set: 0 },
                suggestion: { $set: act.payload },
            }
        });
    } else if (act.type === KB_SELECT_TREE_ROOT) {
        let id = act.payload;
        if (id[0] === "l") {
            return update(st, {
                selectedTreeRoot: { $set: id },
                selectedTreeRootLibrary: { $set: parseInt(id.substring(1), 10) },
                selectedTreeRootCategory: { $set: 0 },
            });
        } else if (id[0] === "c") {
            return update(st, {
                selectedTreeRoot: { $set: id },
                selectedTreeRootLibrary: { $set: 0 },
                selectedTreeRootCategory: { $set: parseInt(id.substring(1), 10) },
            });
        }
        return st;
    } else if (act.type === KB_INSERT_NODE) {
        return update(st, {
            insertedNode: { $set: act.payload }
        });
    } else if (act.type === KB_REMOVE_NODE) {
        return update(st, {
            removedNode: { $set: act.payload }
        });
    } else if (act.type === done(keyGetKnowledgeBaseRoot)) {
        return update(st, {
            roots: { $set: act.payload.data.roots }
        });
    } else if (act.type === done(keyGetKnowledgeBase)) {
        return update(st, {
            knowledgeBaseTreeList: { $set: act.payload.data.list }
            ,  fetchingLibrary: { $set: false }
        })
    } else if (act.type === done(keyGetKnowledgeBaseCategory)) {
        return update(st, {
            knowledgeBaseTreeList: { $set: act.payload.data.list }
            , fetchingLibrary: { $set: false }
        });
    } else if (act.type === done(keyGetAdminLibrary)) {
        return update(st, {
            edit: {
                show: { $set: true },
                title: { $set: 'Edit Knowledge Base' },
                data: { $set: act.payload.data.data }
            }
        });
    } else if (act.type === done(keyGetAdminLibraryCategory)) {
        return update(st, {
            edit: {
                show: { $set: true },
                title: { $set: 'Edit Category' },
                data: { $set: act.payload.data.data }
            }
        });
    } else if (act.type === done(keyGetAdminLibraryQuestion)) {
        if (st.tableList.ratingList.length > 0) {
            let question = act.payload.data.data;
            let rating = st.tableList.ratingList.find(r => r.id === question.id);
            if (rating) {
                question.voteUp = rating.voteUp;
                question.voteDown = rating.voteDown;
                question.userComment = rating.userComment;
            }
            return update(st, {
                edit: {
                    show: { $set: true },
                    title: { $set: 'Edit Question' },
                    data: { $set: question }
                }
            });
        } else {
            return update(st, {
                edit: {
                    show: { $set: true },
                    title: { $set: 'Edit Question' },
                    data: { $set: act.payload.data.data }
                }
            });
        }
    } else if (act.type === done(keyGetAdminLibrarySuggestion)) {
        return update(st, {
            edit: {
                show: { $set: true },
                title: { $set: 'Edit Suggestion' },
                data: { $set: act.payload.data.data }
            }
        });
    } else if (act.type === done(keyGetAdminLibraryRating)) {
        return update(st, {
            edit: {
                show: { $set: true },
                title: { $set: 'Edit Rating' },
                data: { $set: act.payload.data.data }
            }
        });
    } else if (act.type === done(keyFetchLibraryList)) {
        return update(st, {
            tableList: { libraryList: { $set: act.payload.data.list } }
        });
    } else if (act.type === done(keyFetchCategoryList)) {
        return update(st, {
            tableList: { categoryList: { $set: act.payload.data.list } }
        });
    } else if (act.type === done(keyFetchQuestionList)) {
        return update(st, {
            fileArchiveImages:{ $set: act.payload.data.fa },
            tableList: { questionList: { $set: act.payload.data.list } },
            showMore: {question: {$set: act.payload.data.more}}
        });
    } else if (act.type === done(keyFetchSuggestionList)) {
        return update(st, {
            tableList: { suggestionList: { $set: act.payload.data.list } },
            showMore: {suggestion: {$set: act.payload.data.more}}
        });
    } else if (act.type === done(keyFetchRatingList)) {
            let questionList = st.tableList.questionList;
            let ratingList = act.payload.data.list;
            if(questionList && questionList.length > 0 && ratingList && ratingList.length > 0) {
                questionList = questionList.map(q => {
                    let rating = ratingList.find(r => r.id === q.id);
                    if (rating) {
                        q.voteUp = rating.voteUp;
                        q.voteDown = rating.voteDown;
                        q.userComment = rating.userComment;
                    }
                    return q;
                });
                return update(st, {
                    tableList: {
                        ratingList: { $set: ratingList },
                        questionList: { $set: questionList }
                    }
                });
            } else {
                return update(st, {
                    tableList: { ratingList: { $set: ratingList } }
                });
            }
    } else if (act.type === done(keyFetchMoreLibraryList)) {
        return update(st, {
            tableList: { libraryList: { $push: act.payload.data.list } },
            showMore: {library: {$set: act.payload.data.more}}
        });
    } else if (act.type === done(keyFetchMoreCategoryList)) {
        return update(st, {
            tableList: { categoryList: { $push: act.payload.data.list } },
            showMore: {category: {$set: act.payload.data.more}}
        });
    } else if (act.type === request(keyFetchMoreQuestionList)) {
        return update(st, {
            fetchingQuestion:{ $set: true }
        });
    } else if (act.type === done(keyFetchMoreQuestionList)) {
        return update(st, {
            fileArchiveImages:{ $set: act.payload.data.fa },
            fetchingQuestion: { $set: false },
            tableList: { questionList: { $push: act.payload.data.list } },
            showMore: {question: {$set: act.payload.data.more}}
        });
    } else if (act.type === done(keyFetchMoreSuggestionList)) {
        return update(st, {
            tableList: { suggestionList: { $push: act.payload.data.list } },
            showMore: {suggestion: {$set: act.payload.data.more}}
        });
    } else if (act.type === done(keyFetchMoreRatingList)) {
        return update(st, {
            tableList: { ratingList: { $push: act.payload.data.list } },
            showMore: {rating: {$set: act.payload.data.more}}
        });
    } else if (act.type == KB_LOADING_LIBRARY) {
        return update(st, {
            fetchingLibrary: { $set: act.payload }
        });
    } else if (act.type === KB_UPDATE_CHATBOTS) {
        const chatbotIds = [...st.edit.chatbotIds];
        const { id, iid } = act.payload;
        if (!chatbotIds.includes(id)) {
            chatbotIds.push(id);
        }
        return update(st, {
            edit: {
                chatbotIds: { $set: chatbotIds },
                intentId: { $set: iid }
            }
        });
    }
    return st;
};

const initSearchAnswer = {
    source: "either"
    , input: ""
    , searchState: false
};

const searchAnswerReducer = {
    [SA_CHANGE_SOURCE]: (st, act) => {
        return update(st, {
            source: { $set: act.payload }
        });
    },
    [SA_CHANGE_INPUT]: (st, act) => {
        return update(st, {
            input: { $set: act.payload }
        });
    },
    [SA_CHANGE_SEARCH_STATE]: (st, act) => {
        return update(st, {
            searchState: { $set: act.payload }
        })
    }
};

const searchAnswer = createReducer(initSearchAnswer, searchAnswerReducer);

export { searchAnswer, knowledgeBaseReducer};
