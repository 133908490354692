import { combineReducers } from 'redux'
import update from 'immutability-helper'
import { emptyObject } from '../../common/v5/constants'
import { keyAllConstants, keyConstants } from '../constants/keys'
import {
  asyncInitState,
  asyncDoneActionType,
  createReducer,
  defAsyncReducer
} from '../util'
import {
  BRANCH_BY_NAME,
  appAsyncMap,
  appStateName
} from '../selectors/c3constants'

const done = key => asyncDoneActionType(appAsyncMap[key])

const _byNameReducers = {
  [done(keyAllConstants)]: (_, { payload: { data } }) => data,
  [done(keyConstants)]: (
    state,
    { payload: { data, param: { name } } }
  ) => update(state, { [name]: { $set: data } })
}

export const app = combineReducers({
  [appStateName(keyAllConstants)]: defAsyncReducer(
    keyAllConstants,
    asyncInitState
  ),
  [appStateName(keyConstants)]: defAsyncReducer(keyConstants, asyncInitState),
  [BRANCH_BY_NAME]: createReducer(emptyObject, _byNameReducers)
})
