import {
  AEO_ADD,
  AEO_DELETE,
  AEO_EDIT,
  AEO_MANUAL,
  emptyObject
} from '../../common/constants'
import { actionReducerCreator } from '../util'

const getFinishState = state => typeof state === 'undefined' ? null : state

export const createEditActionReducer = (
  start,
  edit,
  finish,
  initState
) => actionReducerCreator([
  [start, ({ data, which }) => ({ $set: { original: data, data, which } })],
  [
    edit,
    ({ field, operand, value }) => {
      if (operand === AEO_ADD) {
        return { data: { [field]: { $push: [value] } } }
      } else if (operand === AEO_DELETE) {
        return { data: { [field]: { $splice: [[value, 1]] } } }
      } else if (operand === AEO_EDIT) {
        return { data: { [field]: { $set: value } } }
      } else if (operand === AEO_MANUAL) {
        return { data: { [field]: value } }
      }
      return emptyObject
    }
  ],
  [finish, () => ({ $set: getFinishState(initState) })]
])
