import { combineReducers } from 'redux'
import each from 'lodash/each'
import indexOf from 'lodash/indexOf'
import reduceReducers from 'reduce-reducers'
import update from 'immutability-helper'
import {
  keyAdminChatbots,
  keyAdminChatbotsQuery,
  keyAdminDeleteChatbots,
  keyAdminDeleteChatbotsIntents,
  keyAdminEditChatbots,
  keyAdminEditChatbotsIntents,
  keyAdminGetChatbotsIntents,
  keyAdminGetOneChatbot,
  keyAdminSyncChatbot,
  keyEditChatbotsTrainingphrases,
  keyInsertKnowledgeBaseNode,
  keyRemoveAdminLibraryCategory,
  keyRemoveAdminLibrary,
  keyRemoveAdminLibraryQuestion,
  keyRemoveKnowledgeBaseNode,
  keySaveAdminLibraryQuestion
} from '../constants/keys'
import {
  CHATBOTS_ADD_QUESTION,
  CHATBOTS_CLEAR_ALL_QUESTIONS,
  CHATBOTS_EDIT_FIELD,
  CHATBOTS_FINISH_EDIT,
  CHATBOTS_START_EDIT,
  CHATBOTS_UPDATE_UI_FIELD
} from '../constants/constants'
import {
  emptyArray,
  emptyObject
} from '../../common/v5/constants'
import {
  actionReducerCreator,
  createReducer,
  defAsyncReducer,
  asyncInitState,
  asyncDoneActionType
} from '../util'
import { admin as AdminsMap } from '../selectors/admin'
import { adminAsyncMap, adminStateName } from '../selectors/chatbot'
import { createEditActionReducer } from './common'

const adminDone = key => asyncDoneActionType(AdminsMap[key])

const emptyNormalized = { list: emptyArray, byId: emptyObject }

const done = key => asyncDoneActionType(adminAsyncMap[key])

const updateAgents = id => updater => ({ agents: { byId: { [id]: updater } } })

const updateQuestions = chatbotId => updater => updateAgents(chatbotId)({ questions: updater })

const [_adminActionMap, _adminReducerMap] = actionReducerCreator([
  [CHATBOTS_ADD_QUESTION, ({ id, qid, question }) => updateQuestions(id)({
    byId: { [qid]: { $set: { question } } },
    list: { $push: [qid] }
  })],
  [CHATBOTS_CLEAR_ALL_QUESTIONS, chatbotId => updateQuestions(chatbotId)({ $set: emptyNormalized })]
])

export const adminActionMap = _adminActionMap

export const createCommonEditActionReducer = createEditActionReducer

const [
  _adminEditActionMap,
  _adminEditReducerMap
] = createCommonEditActionReducer(
  CHATBOTS_START_EDIT,
  CHATBOTS_EDIT_FIELD,
  CHATBOTS_FINISH_EDIT
)

export const adminEditActionMap = _adminEditActionMap

const getIntents = (intents, previousIntents) => {
  const byId = {}
  const list = []
  each(intents, intent => {
    const { id } = intent
    intent = { intent }
    if (previousIntents && previousIntents.byId[id]) {
      const { fullView } = previousIntents.byId[id]
      if (fullView) {
        intent.fullView = fullView
      }
    }
    byId[id] = intent
    list.push(id)
  })
  return { list, byId }
}

const getPreviousIntents = (need, state, id) => {
  if (!need || !state.byId[id]) {
    return
  }
  return state.byId[id].intents
}

const getChatbotAgents = (state, chatbotAgents) => {
  const list = []
  const byId = {}
  each(chatbotAgents, agent => {
    const { id } = agent
    agent = { agent }
    const previousAgent = state.byId[id]
    if (previousAgent) {
      agent.intents = previousAgent.intents
      agent.questions = previousAgent.questions
      if (previousAgent.fullView) {
        agent.fullView = previousAgent.fullView
      }
    } else {
      agent.questions = emptyNormalized
    }
    byId[id] = agent
    list.push(id)
  })
  return { list, byId }
}

const normalizeRemoverUpdater = (id, array) => {
  const index = indexOf(array, id)
  if (index < 0) {
    console.log('error can not find id:', id)
    return
  }
  return { byId: { $unset: [id] }, list: { $splice: [[index, 1]] } }
}

const normalizeCreationUpdater = (id, data, field) => ({
  byId: { [id]: { $set: { [field]: data } } },
  list: { $push: [id] }
})

const checkIntentExist = (state, id, iid) => {
  const chatbot = state.byId[id]
  if (!chatbot || !chatbot.intents) {
    return false
  }
  return !!chatbot.intents.byId[iid]
}

const clearChatbotIntentsCacheReducer = state => {
  const { byId, list } = state
  if (!list.length) {
    return state
  }
  const updater = {}
  each(byId, (chatbot, id) => {
    updater[id] = { $unset: ['intents'] }
  })
  return update(state, { byId: updater })
}

const createDataViewUpdater = (field, data, replaceBasicView, isAgent) => {
  const dataUpdater = { $set: data }
  const fullViewUpdater = { fullView: dataUpdater }
  if (replaceBasicView) {
    fullViewUpdater[field] = dataUpdater
  }
  if (isAgent && data.questions) {
    fullViewUpdater.$unset = ['intents']
  }
  return fullViewUpdater
}

const createAgentDataViewUpdater = (
  field,
  data
) => createDataViewUpdater(field, data, false, true)

const updateChatbotView = (state, data) => update(
  state,
  { byId: { [data.id]: createAgentDataViewUpdater('agent', data) } }
)

const updateOneChatbotReducer = (
  state,
  { payload: { data } }
) => updateChatbotView(state, data)

const updateIntentView = (state, id, iid, data, replaceBasicView) => update(
  state,
  {
    byId: {
      [id]: {
        intents: {
          byId: {
            [iid]: createDataViewUpdater('intent', data, replaceBasicView)
          }
        }
      }
    }
  }
)

const updateFullViewIntent = (...args) => updateIntentView(...args)

const _agentsReducers = {
  [done(keyAdminChatbots)]: (state, { payload: { data } }) => getChatbotAgents(
    state,
    data
  ),
  [done(keyAdminChatbotsQuery)]: (
    state,
    { payload: { data, param: { id, qid } } }
  ) => update(
    state, {
      byId: {
        [id]: { questions: { byId: { [qid]: { answer: { $set: data } } } } }
      }
    }
  ),
  [done(keyAdminDeleteChatbots)]: (
    state,
    { payload: { data: { id: serverId }, param: { id } } }
  ) => {
    if (serverId !== id) {
      console.log('error as chatbot id not match:', serverId, id)
    }
    const updater = normalizeRemoverUpdater(serverId, state.list)
    if (!updater) {
      return state
    }
    return update(state, updater)
  },
  [done(keyAdminDeleteChatbotsIntents)]: (
    state,
    { payload: { data: { id: serverIid }, param: { id, iid } } }
  ) => {
    if (serverIid !== iid) {
      console.log('error as intent id not match:', serverIid, iid)
    }
    const chatbot = state.byId[id]
    if (!chatbot || !chatbot.intents) {
      console.log('error chatbot data not found:', id)
      return state
    }
    const updater = normalizeRemoverUpdater(serverIid, chatbot.intents.list)
    if (!updater) {
      return state
    }
    return update(state, { byId: { [id]: { intents: updater } } })
  },
  [done(keyAdminEditChatbots)]: (
    state,
    { payload: { data, param: { id, updater } } }
  ) => {
    const { id: serverId } = data
    if (typeof id === 'undefined') {
      // create chatbot
      return update(state, normalizeCreationUpdater(serverId, data, 'agent'))
    }
    if (serverId.toString() !== id.toString()) {
      console.log('chatbot id not match:', serverId, id)
      return state
    }
    // edit chatbot
    return updateChatbotView(state, data)
  },
  [done(keyAdminEditChatbotsIntents)]: (
    state,
    { payload: { data: serverData, param: { id, iid, data } } }
  ) => {
    const { id: serverIid } = serverData
    if (typeof iid === 'undefined') {
      // create intent
      const intent = { intent: serverData, fullView: serverData }
      return update(
        state, {
          byId: {
            [id]: {
              intents: {
                list: { $push: [serverIid] },
                byId: { [serverIid]: { $set: intent } }
              }
            }
          }
        }
      )
    }
    if (serverIid !== iid) {
      console.log('intent id not match, not to give wrong state:', id, iid)
      return state
    }
    data = update(data, { $merge: serverData })
    const intent = { intent: data, fullView: data }
    // edit intent
    return update(
      state,
      { byId: { [id]: { intents: { byId: { [iid]: { $set: intent } } } } } }
    )
  },
  [done(keyAdminGetChatbotsIntents)]: (
    state,
    { payload: { data, param: { id, iid, keepPreviousFullView } } }
  ) => {
    if (typeof iid === 'undefined') {
      // many intents - will wipe all full view intents
      return update(
        state,
        {
          byId: {
            [id]: {
              intents: {
                $set: getIntents(
                  data,
                  getPreviousIntents(keepPreviousFullView, state, id)
                )
              }
            }
          }
        }
      )
    }
    // one intent
    return updateIntentView(state, id, iid, data, true)
  },
  [done(keyAdminGetOneChatbot)]: updateOneChatbotReducer,
  [done(keyAdminSyncChatbot)]: updateOneChatbotReducer,
  // [done(keyAdminSyncChatbot)]: (state, { payload: { data } }) => {
  //   const { id } = data
  //   const dataUpdater = { agent: data, fullView: data }
  //   const { questions } = state.byId[id]
  //   if (questions) {
  //     dataUpdater.questions = { questions }
  //   }
  //   return update(state, { byId: { [id]: { $set: dataUpdater } } })
  // },
  [done(keyEditChatbotsTrainingphrases)]: (state, { payload: { data } }) => {
    if (!data || !data.length) {
      return state
    }
    each(data, ({ id, intents }) => {
      each(intents, intent => {
        const { id: iid } = intent
        if (checkIntentExist(state, id, iid)) {
          state = updateFullViewIntent(state, id, iid, intent)
        }
      })
    })
    return state
  },
  [adminDone(keyInsertKnowledgeBaseNode)]: clearChatbotIntentsCacheReducer,
  [adminDone(keyRemoveAdminLibraryCategory)]: clearChatbotIntentsCacheReducer,
  [adminDone(keyRemoveAdminLibrary)]: clearChatbotIntentsCacheReducer,
  [adminDone(keyRemoveAdminLibraryQuestion)]: clearChatbotIntentsCacheReducer,
  [adminDone(keyRemoveKnowledgeBaseNode)]: clearChatbotIntentsCacheReducer,
  [adminDone(keySaveAdminLibraryQuestion)]: clearChatbotIntentsCacheReducer
}

const _editReducers = { ..._adminEditReducerMap }

const uiInitState = {
  searchText: '',
  filterType: 0
}

const uiReducers = (state = uiInitState, { type, payload }) => {
  if (type == CHATBOTS_UPDATE_UI_FIELD) {
    return update(state, { [payload.field]: { $set: payload.value } })
  }
  return state
}

const _adminReducers = { ..._adminReducerMap }

export const admin = reduceReducers(
  combineReducers({
    [adminStateName(keyAdminChatbots)]: defAsyncReducer(
      keyAdminChatbots,
      asyncInitState
    ),
    [adminStateName(keyEditChatbotsTrainingphrases)]: defAsyncReducer(
      keyEditChatbotsTrainingphrases,
      asyncInitState
    ),
    [adminStateName(keyAdminChatbotsQuery)]: defAsyncReducer(
      keyAdminChatbotsQuery,
      asyncInitState
    ),
    [adminStateName(keyAdminEditChatbots)]: defAsyncReducer(
      keyAdminEditChatbots,
      asyncInitState
    ),
    [adminStateName(keyAdminEditChatbotsIntents)]: defAsyncReducer(
      keyAdminEditChatbotsIntents,
      asyncInitState
    ),
    [adminStateName(keyAdminGetChatbotsIntents)]: defAsyncReducer(
      keyAdminGetChatbotsIntents,
      asyncInitState
    ),
    // [adminStateName(keyAdminGetOneChatbot)]: defAsyncReducer(
    //   keyAdminGetOneChatbot,
    //   asyncInitState
    // ),
    // [adminStateName(keyAdminSyncChatbot)]: defAsyncReducer(
    //   keyAdminSyncChatbot,
    //   asyncInitState
    // ),
    agents: createReducer(emptyNormalized, _agentsReducers),
    edit: createReducer(null, _editReducers),
    ui: uiReducers
  }),
  createReducer(emptyObject, _adminReducers)
)
