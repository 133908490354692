import { DATA_SYNC } from '../constants/constants'
import { popErrorOnly } from '../actions/hmf'

// socketError
const socketError = store => next => action => {
  if (action.type !== DATA_SYNC || !action.payload.error) {
    return next(action)
  }
  return store.dispatch(popErrorOnly(error))
}

export default socketError
