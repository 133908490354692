import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';
import update from 'immutability-helper';
import {
	keyChangeFavouriteChatAgent,
	keyFavouriteChatAgent,
	keyAllChatAgent,
	// todo
	// keyAgentAPIAgentlist,
	// keyAgentAPIAnswerinvite,
	// keyAgentAPIForward,
	// keyAgentAPIGetAreas,
	// keyAgentAPIGetAgents,
	keyAgentAPICanClose,
	keyAgentAPIQueues,
	// keyAgentAPISessiondata,
	keyGetClosedChatErrand,
	keyGetAgentAPIChatStatus
} from '../constants/keys';
import {
	CHAT_ACQUIRE_ERRAND,
	CHAT_GET_MCOUNT,
	CHAT_NEW_SESSION_RECEIVED,
	CHAT_ON_ACCEPT_CHAT,
	CHAT_ON_ACTIVATE,
	CHAT_ON_AGENT_PRESENCE,
	CHAT_ON_ASSOCIATE_ERRAND,
	CHAT_ON_CLIENT_PATHS,
	CHAT_ON_CONNECT_STATUS,
	CHAT_ON_DEAD_SESSION,
	CHAT_ON_FINISH_SESSION,
	CHAT_ON_MESSAGE_ACKED,
	CHAT_ON_PREVIEW,
	CHAT_ON_QUEUE_SEND,
	CHAT_DO_SEND,
	CHAT_ON_SESSION_INIT,
	CHAT_ON_TAGS,
	CHAT_ON_UNSENT_MESSAGES,
	CHAT_ON_UPDATE_TAGS,
	CHAT_SHOW_SYSTEM_ERROR_MESSAGE,
	CHAT_UPDATE_CLIENT_STATUS,
	CHAT_UPDATE_MESSAGE,
	CHAT_UPDATE_NEW_MESSAGE_STATUS,
	CHAT_SHOW_CONVERSATIONS,
	CHAT_SHOW_FAVOURITES,
	CHAT_SHOW_ALL,
	CHAT_RESET_NEWMESSAGE_COUNT,
	evtCHAT_REGISTER,
    evtCHAT_SET_AGENT,
	evtCHAT_CHAT_MESSAGE,
	evtCHAT_INVITED_CHAT_MESSAGE,
	evtCHAT_FINISH_SESSION,
	evtCHAT_AGENT_PRESENCE,
	evtCHAT_ACCEPT_INTERNAL_CHAT,
	WS_EVENT,
	UPDATE_CHAT_TYPING_STATUS,
	CHAT_AGENT_UNABLE_ANSWER_VIDEO,
	CLIENT_REJECT_VIDEO_CALL,
	CLIENT_VID_CALLING,
	CHAT_VIDEO_REQUEST_WIP,
	CHAT_AGENT_SCREEN_SHARING,
	CHAT_AGENT_SCREEN_SHARING_OFFER,
	CHAT_CLIENT_SCREEN_SHARING,
	CHAT_CLIENT_SCREEN_SHARING_OFFER,
	CHAT_CLIENT_COBROWSE_OFFER,
	CHAT_CLIENT_SELECTED_DISPLAY,
	CHAT_TOGGLE_MOUSE_CONTROL,
	CHAT_TOGGLE_KEYBOARD_CONTROL,
	SAVE_WHATSAPP_TEMPLATE_ID
} from '../constants/constants';
import {
	asyncInitState,
	asyncDoneActionType,
	createReducer,
	defAsyncReducer,
	defMCAMReducer,
	getStateName,
	initWithOpr
} from '../util';
import {
	getMcount
} from '../../common/v5/helpers';
import { chat as chatMap } from '../actions/async/chat';
import { playSoundNotif } from '../actions/async/echat';

const stateName = key => getStateName(chatMap[key]);

const done = key => asyncDoneActionType(chatMap[key]);

const favouriteList = () => defAsyncReducer(keyFavouriteChatAgent, asyncInitState, (state, action) => {
	let newAgentPresence = $.extend({},state.agentPresence);
	$.each(newAgentPresence, (id, agent) => {
		if(agent.favourite) {
			newAgentPresence = update(newAgentPresence, {[agent.id]: {favourite: {$set: false}}});
		} else {
			newAgentPresence = update(newAgentPresence, {[agent.id]: {$merge: {favourite: false}}});
		}
	});
	$.each(action.payload.data.agentList, (index, id) => {
		if(newAgentPresence[id] && Object.keys(newAgentPresence[id]).length > 0) {
			newAgentPresence = update(newAgentPresence, {[id]: {favourite: {$set: true}}});
		}
	})
	return update(state, {agentPresence: {$merge: newAgentPresence}});
});

const chatAgentList = () => defAsyncReducer(keyAllChatAgent, asyncInitState, (state, action) => {
	let newAgentPresence = $.extend({},state.agentPresence);
	$.each(action.payload.data.list, (index, agent) => {
		newAgentPresence = update(newAgentPresence, {$merge: {[agent.id]: agent}});
	})
	return update(state, {agentPresence: {$set: newAgentPresence}});
});

const updateFavourite = () => defAsyncReducer(keyChangeFavouriteChatAgent, asyncInitState, (state, action) => {
	let {id, favourite} = action.payload.data;
	return update(state, {agentPresence: {[id]: {favourite: {$set: favourite}}}});
});

const handleChatMessage = (state, cm) => {
	if(!cm) return state; // Don't process empty chat

	var msg;
	msg = cm.session;
	msg.chat = cm.messages;
	// msg.ack = ack;
	msg.hasUnacked = cm.hasUnacked;
	msg.isInvited = cm.isInvited;
	// msg.autoDisplayIfActive = self.autoDisplayIfActive;
	msg.startedHuman = cm.startedHuman;
	if (!msg.dead) {
		// For invited agent
		msg.dead = cm.dead;
	}
	const previousChat = msg.chat, cmaxId = msg.cmaxId;
	for(let i=0; i<previousChat.length; i++) {
		msg = update(msg, {chat: {[i]: {read: {
			$set: previousChat[i].id <= cmaxId}}}});
	}
	let newState = handleNewSessionReceived(state, {payload: msg});

	// notification
	// if(msg.hasUnacked){
	// 	self.displayNotification(msg);
	// }

	// sound notification
	if(msg.hasUnacked){
		if(initialChatData.playSound){
			playSoundNotif();
		}
	}
	return newState;
}

const handleUpdateNewMessageStatus = (state, action) => {
	const {sessionId, status} = action.payload;
	if(state.chatSessions[sessionId]) {
		if (state.chatSessions[sessionId].session) {
			return update(state, {chatSessions: {[sessionId]: {session: { newMessage: {
				$set: status}}}}});
		}
	}
	return state;
}
// const agentlist = () => defAsyncReducer(keyAgentAPIAgentlist, asyncInitState, (state, action) => {
// 	return update(state, {data: {$set: action.payload.data.list}});
// });

// todo
// const answerinvite = () => defAsyncReducer(keyAgentAPIAnswerinvite, asyncInitState);
// const forward = () => defAsyncReducer(keyAgentAPIForward, asyncInitState);
// const getareas = () => defAsyncReducer(keyAgentAPIGetAreas, asyncInitState);
// const getagents = () => defAsyncReducer(keyAgentAPIGetAgents, asyncInitState);
// const canclose = () => defAsyncReducer(keyAgentAPICanClose, asyncInitState);
// const sessiondata = () => defAsyncReducer(keyAgentAPISessiondata, asyncInitState);

function updateNewLauncher(sessions, feature) {
	// TODO: never direct mutate DOM use react way update the state.
	return;

	var withClient = 0
	, withAgent = 0
	, session
	, i
	, text
	, fgcwa = feature['chat.group-chat-with-agent']
	, fgcwc = feature['chat.group-chat-with-client']
	;
	for(i in sessions) {
		if (!sessions.hasOwnProperty(i)) {
			continue;
		}
		session = sessions[i].session;
		if (!session) {
			// this is only chat tags, not the full session.
			continue;
		}
		if (session.errandId && session.client) {
			withClient++;
		} else {
			withAgent++;
		}
	}
	if (window.parent.$('#globalChatLauncherNew')) {
		// If you change this text, please change the one in header.js as well
		if (withAgent == 0 && withClient == 0) {
			text = I('0 incoming chat requests');
		} else {
			// There are four possible combos
			if(fgcwa && fgcwc) {
				text = I('{WITH_AGENT} agent chat(s), {WITH_CLIENT} client chat(s).')
					.replace("{WITH_AGENT}", withAgent)
					.replace("{WITH_CLIENT}", withClient)
			} else if(fgcwa && !fgcwc) {
				text = I('{WITH_AGENT} agent chat(s), {WITH_CLIENT} client chat(s).')
					.replace("{WITH_AGENT}", withAgent)
					.replace("{WITH_CLIENT}", withClient)
			} else if(!fgcwa &&  fgcwc) {
				text = I('{WITH_CLIENT} incoming chat requests(s).')
					.replace("{WITH_CLIENT}", withClient)
			} else if(!fgcwa && !fgcwc) {
				text = I('{WITH_CLIENT} incoming chat request(s).')
					.replace("{WITH_CLIENT}", withClient)
			} else {
				// We should not reach here
				text = "Internal group chat error. Please report this problem.";
			}
		}
		window.parent.$('#globalChatLauncherNew').text(text);
	}
}

function isInt(v) {
	return v === parseInt(v, 10);
}

// NOTE: seem useless
function handleDoSend(state, action) {
	return state;

	const socketCallBack = action.payload;
	if(socketCallBack) {
		socketCallBack([]);
	}
	return state;
}

const defaultMsg = {index: -1, msg: ""};

// NOTE: can NOT mutate the returned result.
function getLastPreviewMsg(chats) {
	for(let index=chats.length-1; index>=0; index--) {
		if(chats[index].preview && chats[index].fromClient) {
			return {index, msg: chats[index]};
		}
	}
	return defaultMsg;
}

// NOTE: can NOT mutate the returned result.
function findUnsentMsg(state, sessionId, umid) {
	const chatSession = state.chatSessions[sessionId];
	if(chatSession) {
		const chats = chatSession.session.chat;
		for(let i=0; i<chats.length; i++) {
			if(chats[i].preview && !chats[i].fromClient && chats[i].id == umid) {
				return {index: i, msg: chats[i]};
			}
		}
	}
	return defaultMsg;
}

const handleShowSystemErrorMessage = (state, action) => {
	const {sessionId, msg} = action.payload;
	if(state.chatSession[sessionId]) {
		return update(state, {chatSessions: {[sessionId]: {notification: {
			$set: {header: '', text: msg}}}}});
	}
	return state;
};

// NOTE: this return new updated state or unmodified state.
function maybeSetMcount(state, sessionId, messageId) {
	if(!isInt(messageId)) {
		return state;
	}
	const mcount = state.chatSessions[sessionId].mcount;
	if(!mcount) {
		return update(state, {chatSessions: {[sessionId]: {mcount: {$set: {
			[messageId]: true}}}}});
	} else if(!mcount[messageId]) {
		return update(state, {chatSessions: {[sessionId]: {mcount: {
			[messageId]: {$set: true}}}}});
	}
	return state;
}

// TODO: handle side effects with redux-thunks and update UI state through React
// flow.
const handleNewSessionReceived = (state, action) => {
	let msg = action.payload, {sessionId} = msg,
		chatSession = state.chatSessions[sessionId], session;
	// // CHAT-01: move this read flag modification to action portion.
	// const previousChat = msg.chat, cmaxId = msg.cmaxId;
	// for(let i=0; i<previousChat.length; i++) {
	// 	msg = update(msg, {chat: {[i]: {read: {
	// 		$set: previousChat[i].id <= cmaxId}}}});
	// }
	if(chatSession) {
		session = chatSession.session;
		if(session) {
			const lastMsg = getLastPreviewMsg(session.chat);
			if(lastMsg.index >= 0) {
				session = update(session, {chat: {$splice: [[lastMsg, 1]]}});
			}
			let agentIds = (typeof msg.agentIds === 'undefined') ? [] : msg.agentIds;
			session = update(session, {
				agentIds: {$set: agentIds},
				invitedAgentIds: {$set: msg.invitedAgentIds},
				isInvited: {$set: msg.isInvited},
				area: {$set: msg.area},
				clientStatus: {$set: msg.clientStatus},
				ipaddress: {$set: msg.ipaddress},
				externalData: {$set: msg.externalData},
				// newMessage: {$set: msg.hasUnacked}
			});
			let newChats = [];
			for(let j=0; j<msg.chat.length; j++) {
				const chat = msg.chat[j];
				if(chatSession.mcount[chat.id]) {
					continue;
				}
				newChats.push(chat);
			}
			if(newChats.length) {
				session = update(session, {
					chat: {$push: newChats},
					newMessageCount: {$set: session.newMessageCount + newChats.length}
				});
			}
		} else {
			session = update(msg, {$merge: {
				newMessageCount: msg.chat.length,
				mcount: {},
				unsentMessagesArray: []
			}});
		}
		session = update(session, {
			isDirty: {$set: msg.hasUnacked},
			newMessage: {$set: msg.hasUnacked}
		});
	} else {
		session = update(msg, {$merge: {
			newMessageCount: msg.chat.length,
			mcount: {},
			unsentMessagesArray: [],
			isDirty: msg.hasUnacked,
			newMessage: msg.hasUnacked
		}});
		chatSession = update({}, {mcount: {$set: {}}});
	}
	if(msg.errandId > 0) {
		// mark previously not marked as "read by
		// client" messages from agent as "read".
		// These are messages that was sent by agent
		// but not received by the client.
		const chats = session.chat;
		for(let j=0; j<chats.length; j++) {
			if(chats[j].aid>0) {
				// message from agent
				if(!chats[j].read) {
					// unread by client, mark is as read.
					session = update(session, {chat: {[j]: {read: {
						$set: chats[j].id <= cmaxId}}}});
				}
			}
		}
	}
	session = update(session, {
		dead: {$set: msg.dead},
		ownerId: {$set: msg.ownerId}
	});
	chatSession = update(chatSession, {
		session: {$set: session},
		notification: {$set: {header: "", text: ""}}
	});
	let newState = update(state, {
		chatSessions: {$merge: {[sessionId]: chatSession}}
	});
	for(let i=0; i<msg.chat.length; i++) {
		newState = maybeSetMcount(newState, sessionId, msg.chat[i].id);
	}
	// // TODO: CHAT-02: handle all side effects using redux-thunks and use React state
	// // flow through the UI.
	// chatSession = state.chatSessions[sessionId], session = chatSession.session;
	// updateNewLauncher(chatSession, state.feature);
	// if(msg.autoDisplayIfActive) {
	// 	msg.autoDisplayIfActive(session.dead, session.isDirty,
	// 		session.newMessage);
	// }
	// msg.ack(getMcount(newState, msg.sessionId), msg.chat.length);
	return newState;
};

const handleOnMessageAcked = (state, action) => {
	const {sessionId, ids} = action.payload,
	chatSession = state.chatSessions[sessionId];
	if(!chatSession || !chatSession.session) {
		return state;
	}
	const s = chatSession.session;
	let changed, i, j, newSess = s;
	for(j=0; j<ids.length; j++) {
		for(i=s.chat.length-1; i>=0; i--) {
			if(s.chat[i].id != ids[j]) {
				continue;
			}
			newSess = update(newSess, {chat: {[i]: {read: {$set: true}}}});
			if(!changed) {
				changed = true;
			}
			break;
		}
	}
	if(changed) {
		return update(state, {chatSessions: {[sessionId]: {session: {
			$set: newSess}}}});
	}
	return state;
};

const getMcountReducer = (state, action) => {
	const sessionId = action.payload,
		chatSession = state.chatSessions[sessionId];
	if(!chatSession || chatSession.mcount) {
		return state;
	}
	return update(state, {chatSessions: {[sessionId]: {mcount: {$set: {}}}}});
};

// DONE: On q send for redux can not use Date in reducer.
const handleOnQueueSend = (state, action) => {
	const p = action.payload, {sessionId} = p,
		chatSession = state.chatSessions[sessionId];
	if(chatSession) {
		const initialChatData = p.initialChatData,
			agentID = initialChatData.agentID,
			{preview, errandId, callback} = p,
			lastMessage = [{
				fromClient: false,
				id: p.newMessageId,
				aid: agentID,
				agent: initialChatData.agentName,
				read: false,
				sent: 0,
				sentHuman: "",
				text: preview,
				preview: true,
				chatIndex: chatSession.session.chat.length
			}];
		let newStateObject = {chatSessions: {[sessionId]: {session: {
			chat: {$push: lastMessage},
			unsentMessagesArray: {$push: lastMessage}
		}}}};
		if(agentID > 0) {
			// do not update unsentMessageId when it is invalid agent ID.
			newStateObject.unsentMessageId = {$set: state.unsentMessageId + 1};
		}
		const newState = update(state, newStateObject);
		// // CHAT-03 below part should only done on action portion using
		// // redux-thunk.
		// if(newState.chatSessions[sessionId].session.unsentMessagesArray.length == 1) {
		// 	const doCallback = o => {
		// 		setTimeout(() => {
		// 			if(callback) {
		// 				callback(o);
		// 			}
		// 		});
		// 	};
		// 	doCallback({
		// 		um: newState.chatSessions[sessionId].session.unsentMessagesArray,
		// 		sessionId: sessionId,
		// 		errandId: errandId,
		// 		mCount: getMcount(msg.sessionId)
		// 	});
		// }
		return newState;
	}
	return state;
};

const handleOnUnsentMessages = (state, action) => state;

const handleUpdateMessage = (state, action) => {
	const {sessionId, okToRemove, msg, errorNote} = action.payload,
		chatSession = state.chatSessions[sessionId];
	if(chatSession && okToRemove) {
		const sent = chatSession.session.unsentMessagesArray[0];
		if(!sent) {
			return update(state, {chatSessions: {[sessionId]: {
				session: {
					unsentMessagesArray: {$splice: [[0, 1]]}
				}}}});
		} else {
			return maybeSetMcount(update(state, {chatSessions: {[sessionId]: {
				session: {
					unsentMessagesArray: {$splice: [[0, 1]]},
					chat: {[sent.chatIndex]: {
						id: {$set: msg.id},
						preview: {$set: false},
						sent: {$set: msg.sent},
						sentHuman: {$set: msg.sentHuman},
						error: {$set: errorNote}
					}}
				}}}}), sessionId, msg.id);
		}
	}
	return state;
};

const handleOnPreview = (state, action) => {
	const {sessionId, preview} = action.payload,
		chatSession = state.chatSessions[sessionId];
	if(chatSession && chatSession.session) {
		const lastMessage = getLastPreviewMsg(chatSession.session.chat);
		if(lastMessage.index >= 0) {
			return update(state, {chatSessions: {[sessionId]: {session: {
				chat: {[lastMessage.index]: {text: {$set: preview}}}}}}});
		} else {
			if(preview != "") {
				return update(state, {chatSessions: {[sessionId]: {session: {
					chat: {$push: [{
						fromClient: true,
						id: 0,
						read: false,
						sent: 0,
						sentHuman: "",
						text: preview,
						preview: true
					}]}}}}});
			}
		}
	}
	return state;
};

const handleUpdateClientStatus = (state, action) => {
	const {sessionId, status} = action.payload,
		chatSession = state.chatSessions[sessionId];
	if(chatSession && !chatSession.session.dead) {
		return update(state, {chatSessions: {[sessionId]: {session: {clientStatus: {
			$set: status}}}}});
	}
	return state;
};

const handleOnDeadSession = (state, action) => {
	const {sessionId} = action.payload,
		chatSession = state.chatSessions[sessionId];
	if(chatSession && !chatSession.session.dead) {
		return update(state, {chatSessions: {[sessionId]: {session: {
			dead: {$set: true},
			clientStatus: {$set: 'Offline'}
		}}}});
	}
	return state;
};

const handleOnActivate = (state, action) => {
	const sessionId = action.payload;
	if(state.chatSessions[sessionId]) {
		return update(state, {chatSessions: {[sessionId]: {session: {
			isDirty: {$set: false},
			newMessageCount: {$set: 0}
		}}}});
	}
	return state;
};

// DONE: update the UI count for launcher using React way.
const handleOnFinishSession = (state, action) => {
	const {sessionId} = action.payload,
		chatSession = state.chatSessions[sessionId];
	if(chatSession) {
		const {isStale, error, isOwner, guestLeaving, expired, closedBy, needTag } = action.payload;
		if(error) {
			// CHAT-05 - error alert popup
			return state;
		}
		if(isOwner) {
			// CHAT-06 - close errands
		}
		if(isOwner || guestLeaving || isStale) {
			return update(state, {chatSessions: {$unset: [sessionId]}});
		} else {
			let notice;
			if(expired) {
				notice = I("This chat has expired.");
			} else if(closedBy) {
				notice = I("{NAME} has closed this chat")
					.replace("{NAME}", closedBy);
			} else if(needTag) {
				notice = I("Please tag this chat to close it");
			} else {
				notice = I("This chat has ended.");
			}
			if(!needTag) {
				return update(state, {chatSessions: {[sessionId]: {
					session: {dead: {$set: true}},
					notification: {header: {$set: ""}, text: {$set: notice}}
				}}});
			} else {
				return update(state, {chatSessions: {[sessionId]: {
					notification: {header: {$set: ""}, text: {$set: notice}}
				}}});
			}
		}
	}
	return state;
};

const handleOnTags = (state, action) => {
	const {sessionId, errandTags, tags} = action.payload,
		sortedTags = tags.slice().sort((a, b) => {
			return a.value.localeCompare(b.value);
		});
	return update(state, {chatSessions: {[sessionId]: {
		tags: {$set: sortedTags},
		errandTags: {$set: errandTags}
	}}});
};

const handleOnUpdateTags = (state, action) => {
	const {sessionId, tags, selectedTags} = action.payload,
		chatSession = state.chatSessions[sessionId];
	let updated, newSession = chatSession, newTags;
	if(newSession && newSession.tags) {
		const chatTags = newSession.tags;
		let newTags = chatTags;
		// previous tags value is used for this outer loop mainly to avoid
		// ambiguous looping condition and updated new tags should not affect
		// the looping condition.
		for(let j=0; j<chatTags.length; j++) {
			if(chatTags[j].selected == true) {
				// default tags selections to false.
				newTags = update(newTags, {[j]: {selected: {$set: false}}});
				if(!updated) {
					updated = true;
				}
			}
			for(let i=0; i<tags.length; i++) {
				// use updated tags here for more accurately respresent the
				// state of the tags.
				if(newTags[j].id == tags[i]) {
					newTags = update(newTags, {[j]: {selected: {$set: true}}});
					if(!updated) {
						updated = true;
					}
				}
			}
		}
		if(updated) {
			newSession = update(newSession, {[sessionId]: {
				tags: {$set: newTags},
				selectedTags: {$set: selectedTags}
			}});
		} else {
			// NOTE: this seem useless as without updated flag true the
			// state.chatSessions will never get the updated value. Seem like
			// selectedTags is dependent on tags which is not something right to
			// do.
			newSession = update(newSession, {[sessionId]: {
				selectedTags: {$set: selectedTags}
			}});
		}
	}
	if(updated) {
		return update(state, {chatSessions: {[sessionId]: {$set: newSession}}});
	}
	return state;
};

const handleOnClientPaths = (state, action) => {
	const {sessionId} = action.payload;
	let chatSession = state.chatSessions[sessionId];
	if(!chatSession) {
		chatSession = {session: {clientPaths: payload}};
	} else {
		chatSession = update(chatSession, {session: {
			clientPaths: {$set: payload}}});
	}
	return update(state, {chatSessions: {$merge: {[sessionId]: chatSession}}});
};

const updateAgentPresence = (state, action) => {
	const {id, acceptChat, acceptInternalChat, chatName, online, userName} = action.payload;
	let agent = Object.assign({}, state.agentPresence[id]);
	if (Object.keys(agent).length > 0) {
		agent = update(agent, {
			id: {$set: id},
			acceptInternalChat: {$set: acceptInternalChat},
			chatName: {$set: chatName},
			online: {$set: online},
			userName: {$set: userName}
		});
		return update(state, {agentPresence: {[id]: {$merge: agent}}});
	}
	return state;
};

const handleOnConnectStatus = (state, action) => update(state, {$merge: action.packet.args[0]}); // payload

const handleOnAcceptChat = (state, action) => {
	return update(state, {acceptInternalChat: {$set: action.payload}});
};

const handleUpdateAgentNotAvailForChat = (state, action) => {
	return update(state, {onVideoCall: {$set: action.payload}});
}

const handleAssociateErrand = (state, action) => {
	const {sessionId, associatedErrands} = action.payload;
	if(state.chatSessions[sessionId]) {
		return update(state, {chatSessions: {[sessionId]: {associatedErrands: {
			$set: associatedErrands}}}});
	}
	return state;
};

const handleAcquireErrand = (state, action) => {
	const {sessionId, errand} = action.payload;
	if(state.chatSessions[sessionId]) {
		return update(state, {chatSessions: {[sessionId]: {
			acquiredRelatedErrands: {acquired: {$push: [errand]}}}}});
	}
	return state;
};

const handleOnSessionInit = (state, action) => {
	const {
		sessionId,
		acquiredRelatedErrands,
		chatNotesCounter,
		customerNotesCounter,
		sessionSecret
	} = action.payload;
	if(state.chatSessions[sessionId]) {
		return update(state, {chatSessions: {[sessionId]: {
			acquiredRelatedErrands: {$set: acquiredRelatedErrands},
			chatNotesCounter: {$set: chatNotesCounter},
			customerNotesCounter: {$set: customerNotesCounter},
			associatedErrands: {$set: []},
			sessionSecret: {$set: sessionSecret}
		}}});
	}
	return state;
};

// V5 chat events
const handleInternalChat = (state, action) => {
	if (action.type != WS_EVENT) {
		return state;
	}
	let packet = action.packet
	, args = packet.args
	;
	switch (packet.event) {
		case evtCHAT_REGISTER:
			return handleOnConnectStatus(state, action);

		case evtCHAT_CHAT_MESSAGE:
			return handleChatMessage(state, args[0]);

		case evtCHAT_INVITED_CHAT_MESSAGE:
			return handleChatMessage(state, update(args[0], {$merge: {isInvited: true}}));

		case evtCHAT_AGENT_PRESENCE:
			return updateAgentPresence(state, {payload: args[0]});

		case evtCHAT_FINISH_SESSION:
			return handleOnFinishSession(state, {payload: args[0]});

		case evtCHAT_ACCEPT_INTERNAL_CHAT:
			return handleOnAcceptChat(state, {payload: args[0]});

		/* Set agent's in video call status in general, when receives answer and hangup from client */
		case "video-answer":
			if(args[0].init) {
				return handleUpdateAgentNotAvailForChat(state, {payload: true});
			}

		//case "hang-up":
			//return handleUpdateAgentNotAvailForChat(state, {payload: false});

	}
	return state;
};

const handleResetNewMessageCount = (state, action) => {
	const {sessionId} = action.payload,
		chatSession = state.chatSessions[sessionId];
	if(chatSession) {
		return update(state, {chatSessions: {[sessionId]: {session: {newMessageCount: {$set: 0}}}}});
	}
	return state;
};

const handleSetAgentUnableToAnswerVideo = (state, action) => {
	return update(state, {onVideoCall: {$set: action.payload}});
}

const handleVideoCallRequestWIP = (state, action) => {
	return update(state, {onVideoCallRequest: {$set: action.payload}});
}

const handleAgentSharingScreen = (state, action) => {
	return update(state, {agentSharingScreen: {$set: action.payload}});
}

const handleAgentSharingScreenOfferInProgress = (state, action) => {
	return update(state, {agentScreenSharingOfferInProgress: {$set: action.payload}});
}

const handleClientOfferCoBrowse = (state, action) => {
	return update(state, {
		showCoBrowseFrame: {$set: action.payload.status}
	});
}

const handleClientSelectedDisplay = (state, action) => {
	return update(state, {
		clientSelectedDisplay: {$set: action.payload}
	});
}

const handleAgentMouseAccess = (state, action) => {
	return update(state, {
		agentMouseControl: {$set: action.payload}
	});
}

const handleAgentKeyboardAccess = (state, action) => {
	return update(state, {
		agentKeyboardControl: {$set: action.payload}
	});
}

const handleClientSharingScreen = (state, action) => {
	return update(state, {clientSharingScreen: {$set: action.payload}});
}

const handleClientSharingScreenOfferInProgress = (state, action) => {
	return update(state, {clientScreenSharingOfferInProgress: {$set: action.payload}});
}

const handleClientRejectVideoCall = (state, action) => {
	return update(state, {videoCallRejected: {$set: action.payload}});
}

const handleClientIsVidCalling = (state, action) => {
	return update(state, {clientVidCalling: {$set: action.payload}});
}

const handleShowConversations = (state, action) => {
	return update(state, {showConversations: {$set: action.payload}});
};

const handleShowFavourites = (state, action) => {
	return update(state, {showFavourites: {$set: action.payload}});
};

const handleShowAll = (state, action) => {
	return update(state, {showAll: {$set: action.payload}});
};

const setCanClose = (state, action) => {
	return update(state, {canClose: {$set: action.payload.canClose}});
};

//
// redux code
//
const globalReducers = {
	// todo
	// [done(keyAgentAPIAnswerinvite)]: state => state,
	// [done(keyAgentAPIForward)]: state => state,
	// [done(keyAgentAPIGetAreas)]: state => state,
	// [done(keyAgentAPIGetAgents)]: state => state,
	// [done(keyAgentAPICanClose)]: state => state,
	// [done(keyAgentAPISessiondata)]: state => state
};

/*
	chatSessions: {
		{
			100: {
				mcount: {
					200: true						// Message Id already processed
				},
				session: {
					agent: "agent one",
					agentIds: [3, 4],				// Current agent list in this chat session
					amaxId: 0,
					channel: 0,
					chat: [							// Chat messsages received
						{
							aid: 0,					// Agent id, 0 is system message
							fromClient: false,
							id: 200,				// Message id
							read: false,
							sent: 1521611099,
							sentHuman: "13:44",
							text: "",				// Message content, JSON for system message
							umid: ""
						}
					]
					client: "",
					clientEmail: "",
					clientId: 0,
					clientStatus: "away",
					cmaxId: 0,						// Last read message id
					dead: false,
					errandId: 0,
					externalData: "",
					hasUnacked: true,
					invitedAgentIds: [],			// List of invited agent yet to respond
					ipaddress: "0.0.0.0",
					isDirty: true,
					isInvited: undefined,			// Indicates it is an invitation, required to response Join/Reject
					mcount: {},
					newMessage: false,				// Indicates new message arrived
					ownerId: 3,						// Agent Id
					sessionId: 100,
					source: "client",
					started: 1521611099,
					startedHuman: "2018/03/21 13:44",
					unsentMessagesArray: [			// Message ids yet to send
						{
							agent: "agent one",
							aid: 3,
							chatIndex: 7,
							fromClient: false,
							id: "a-3-1521684841893-1",
							preview: true,
							read: false,
							sent: 0,
							sentHuman: "",
							text: "test3"
						}
					],
					user: {},
					newMessageCount,
				},
				notification: {
					header: "",
					text: "",
				}
			}
		}
	}
	agentPresence: {
		5: {
			acceptChat: true,
			acceptInternalChat: true,
			areas: [
				{id: 1, name: "area1"}
			],
			chatName: "agent-3",
			id: 5,
			online: true,
			userName: "Agent 3"
		}
	}
*/
const socketInitState = {
	chatSessions: {},
	unsentMessageId: 0,
	agentPresence: {},
	acceptInternalChat: true,
	fbTypingStatus: {},
	onVideoCall: false,
	onVideoCallRequest: false,
	videoCallRejected: false,
	clientVidCalling: false,
	agentSharingScreen: false,
	agentScreenSharingOfferInProgress: false,
	clientSharingScreen: false,
	clientScreenSharingOfferInProgress: false,
	showCoBrowseFrame: false,
	clientSelectedDisplay: "",
	agentMouseControl: false,
	agentKeyboardControl: false,
	template: {}
	//template also used for Facebook quick replies
};

const socketReducers = {
	[done(keyAllChatAgent)]: chatAgentList(),
	[done(keyFavouriteChatAgent)]: favouriteList(),
	[done(keyChangeFavouriteChatAgent)]: updateFavourite(),
	// todo (include it if needed)
	// [CHAT_ACQUIRE_ERRAND]: handleAcquireErrand,
	// [CHAT_DO_SEND]: handleDoSend,
	// [CHAT_GET_MCOUNT]: getMcountReducer,
	// [CHAT_NEW_SESSION_RECEIVED]: handleNewSessionReceived,
	// [CHAT_ON_ACCEPT_CHAT]: handleOnAcceptChat,
	[CHAT_ON_ACTIVATE]: handleOnActivate,
	// [CHAT_ON_AGENT_PRESENCE]: handleOnAgentPresence,
	// [CHAT_ON_ASSOCIATE_ERRAND]: handleAssociateErrand,
	// [CHAT_ON_CLIENT_PATHS]: handleOnClientPaths,
	// [CHAT_ON_CONNECT_STATUS]: handleOnConnectStatus,
	// [CHAT_ON_DEAD_SESSION]: handleOnDeadSession,
	[CHAT_ON_FINISH_SESSION]: handleOnFinishSession,
	// [CHAT_ON_MESSAGE_ACKED]: handleOnMessageAcked,
	// [CHAT_ON_PREVIEW]: handleOnPreview,
	[CHAT_ON_QUEUE_SEND]: handleOnQueueSend,
	// [CHAT_ON_SESSION_INIT]: handleOnSessionInit,
	// [CHAT_ON_TAGS]: handleOnTags,
	// [CHAT_ON_UNSENT_MESSAGES]: handleOnUnsentMessages,
	// [CHAT_ON_UPDATE_TAGS]: handleOnUpdateTags,
	// [CHAT_SHOW_SYSTEM_ERROR_MESSAGE]: handleShowSystemErrorMessage,
	// [CHAT_UPDATE_CLIENT_STATUS]: handleUpdateClientStatus,
	[CHAT_UPDATE_MESSAGE]: handleUpdateMessage,
	[CHAT_UPDATE_NEW_MESSAGE_STATUS]: handleUpdateNewMessageStatus,
	[CHAT_RESET_NEWMESSAGE_COUNT]: handleResetNewMessageCount,
	[CHAT_AGENT_UNABLE_ANSWER_VIDEO]: handleSetAgentUnableToAnswerVideo,
	[CLIENT_REJECT_VIDEO_CALL]: handleClientRejectVideoCall,
	[CLIENT_VID_CALLING]: handleClientIsVidCalling,
	[UPDATE_CHAT_TYPING_STATUS]: (st, action) =>{
		let {type, currentChatId, status} = action;
		if(type === UPDATE_CHAT_TYPING_STATUS){
			return update(st, {fbTypingStatus: {[currentChatId]:{$set: status}}});
		}
		return st;
	},
	[SAVE_WHATSAPP_TEMPLATE_ID]: (state, action) =>{
		const { tid, errandId } = action.payload;
		return update(state, {
			template: {[errandId]: {$set: tid}}
		});
	},
	[CHAT_VIDEO_REQUEST_WIP] : handleVideoCallRequestWIP,
	[CHAT_AGENT_SCREEN_SHARING] : handleAgentSharingScreen,
	[CHAT_AGENT_SCREEN_SHARING_OFFER] : handleAgentSharingScreenOfferInProgress,
	[CHAT_CLIENT_SCREEN_SHARING] : handleClientSharingScreen,
	[CHAT_CLIENT_SCREEN_SHARING_OFFER] : handleClientSharingScreenOfferInProgress,
	[CHAT_CLIENT_COBROWSE_OFFER]: handleClientOfferCoBrowse,
	[CHAT_CLIENT_SELECTED_DISPLAY]: handleClientSelectedDisplay,
	[CHAT_TOGGLE_MOUSE_CONTROL] : handleAgentMouseAccess,
	[CHAT_TOGGLE_KEYBOARD_CONTROL] : handleAgentKeyboardAccess,
	[WS_EVENT]: handleInternalChat
};

const internalChatUIInitState = {
	showConversations: false,
	showFavourites: false,
	showAll: false
}

const internalChatUIReducers = {
	[CHAT_SHOW_CONVERSATIONS]: handleShowConversations,
	[CHAT_SHOW_FAVOURITES]: handleShowFavourites,
	[CHAT_SHOW_ALL]: handleShowAll,
	[done(keyAgentAPICanClose)]: setCanClose,
}

let chatReducer = combineReducers({
	// todo
	// [stateName(keyAgentAPIAgentlist)]: agentlist(),
	// [stateName(keyAgentAPIAnswerinvite)]: answerinvite(),
	// [stateName(keyAgentAPIForward)]: forward(),
	// [stateName(keyAgentAPIGetAreas)]: getareas(),
	// [stateName(keyAgentAPIGetAgents)]: getagents(),
	// [stateName(keyAgentAPICanClose)]: canclose(),
	// [stateName(keyAgentAPISessiondata)]: sessiondata(),
	[stateName(keyAgentAPIQueues)]: defAsyncReducer(
		keyAgentAPIQueues
		, asyncInitState
	),
	socket: createReducer(socketInitState, socketReducers),
	ui: createReducer(internalChatUIInitState, internalChatUIReducers),
	[stateName(keyGetClosedChatErrand)]: defAsyncReducer(
		keyGetClosedChatErrand
		, initWithOpr
	),
	[stateName(keyGetAgentAPIChatStatus)]: defAsyncReducer(
		keyGetAgentAPIChatStatus
		, asyncInitState
	)
});

chatReducer = reduceReducers(chatReducer, createReducer({}, globalReducers));

export default chatReducer;
