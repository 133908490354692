import { combineReducers } from 'redux'
import reduceReducers from 'reduce-reducers'
import update from 'immutability-helper'
import { customMerger, receiptGreetingObject } from '../../common/helpers'
import {
  keyAdminFilesAreaarchiveimages,
  keyAdminDeleteReceiptgreetings,
  keyAdminRGServiceaccounts,
  keyAdminReceiptgreetings,
  keyAdminSaveReceiptgreetings
} from '../constants/keys'
import {
  RECEIPT_GREETINGS_EDIT_FIELD,
  RECEIPT_GREETINGS_FINISH_EDIT,
  RECEIPT_GREETINGS_START_EDIT
} from '../constants/constants'
import {
  asyncInitState,
  asyncDoneActionType,
  createReducer,
  createReducerSubBranch,
  defAsyncReducer,
  normalizeRemoverUpdater
} from '../util'
import { adminAsyncMap, adminStateName } from '../selectors/receiptGreeting'
import { createCommonEditActionReducer } from './chatbot'

const done = key => asyncDoneActionType(adminAsyncMap[key])

const [
  _adminEditActionMap,
  _adminEditReducerMap
] = createCommonEditActionReducer(
  RECEIPT_GREETINGS_START_EDIT,
  RECEIPT_GREETINGS_EDIT_FIELD,
  RECEIPT_GREETINGS_FINISH_EDIT
)

export const adminEditActionMap = _adminEditActionMap

const merger = (a, b) => customMerger(a, b, receiptGreetingObject)

const _listReducers = {
  [done(keyAdminDeleteReceiptgreetings)]: (
    state,
    { payload: { data: { id: serverId }, param: { receipt, id } } }
  ) => {
    if (serverId !== id) {
      console.log('error as r/g id not match:', serverId, id)
    }
    const updater = normalizeRemoverUpdater(serverId, state[receipt])
    if (!updater) {
      return state
    }
    return update(state, { [receipt]: updater })
  },
  [done(keyAdminSaveReceiptgreetings)]: (
    state,
    { payload: { data: serverData, param: { receipt, id, data } } }
  ) => {
    const { id: serverId } = serverData
    if (typeof id === 'undefined') {
      // create
      return update(
        state, {
          [receipt]: {
            allIds: { $push: [serverId] },
            byId: { [serverId]: { $set: serverData } }
          }
        }
      )
    }
    if (serverId !== id) {
      console.log('id not match, not to give wrong state:', id)
      return state
    }
    // edit
    return update(
      state, {
        [receipt]: {
          byId: {
            [id]: {
              $set: merger(state[receipt].byId[id], serverData)
            }
          }
        }
      }
    )
  }
}

const _editReducers = { ..._adminEditReducerMap }

export const admin = combineReducers({
  [adminStateName(keyAdminFilesAreaarchiveimages)]: defAsyncReducer(
    keyAdminFilesAreaarchiveimages,
    asyncInitState
  ),
  [adminStateName(keyAdminDeleteReceiptgreetings)]: defAsyncReducer(
    keyAdminDeleteReceiptgreetings,
    asyncInitState
  ),
  [adminStateName(keyAdminRGServiceaccounts)]: defAsyncReducer(
    keyAdminRGServiceaccounts,
    asyncInitState
  ),
  [adminStateName(keyAdminReceiptgreetings)]: reduceReducers(
    defAsyncReducer(keyAdminReceiptgreetings, asyncInitState),
    createReducerSubBranch(
      (state, value) => update(state, { data: { $set: value } }),
      ({ data: state }) => state,
      createReducer(null, _listReducers)
    )
  ),
  [adminStateName(keyAdminSaveReceiptgreetings)]: defAsyncReducer(
    keyAdminSaveReceiptgreetings,
    asyncInitState
  ),
  edit: createReducer(null, _editReducers)
})
