import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';
import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import {
	keyExportList,
	keyExportCallList,
	keySimpleTagListByArea,
	keyCreateExport,
	keyUpdateExport,
	keyRemoveExport,
	keyActivateExport,
	keyGeneratedExport,
	keyRemoveGeneratedExport,
	keyGeneratedPreview,
	keyRemoveGeneratedCdrExport,
	keyGeneratedCdrExport
} from '../constants/keys';
import {
	SET_SELECTED_EXPORT,
	SET_EXPORT_INFORMATION,
	SET_ERRAND_CATEGORY,
	REMOVE_ERRAND_CATEGORY,
	CANCEL_CURRENT_EXPORT,
	REMOVE_SEARCH_TERM,
	EXPORT_PREVIEW_CLOSE,
	TOGGLE_EXPORT_POPUP,
	CALL_EXPORT_CDR_CLOSE,
	UPDATE_EXPORT_CALL_LIST
} from '../constants/constants';
import {
	defAsyncReducer,
	asyncInitState,
	asyncDoneActionType
} from '../util';
import { exports as exportActions } from '../actions/async/export';
import { MM_EXPORT_CALL } from '../../common/v5/constants';

const done = key => asyncDoneActionType(exportActions[key]);

const updateExportReducer = (st, act) => {
	const p = act.payload;
	if (act.type === SET_EXPORT_INFORMATION) {
		const field = p.field;
		const value = p.value;
		const isRemove = p.isRemove;
		if (st.ui.currentExport) {
			if (field === "name" || field === "description" || field === "privacyBool" || field === "emailExport"
				|| field === "emailAddress" || field === "emailHeader" || field === "emailBody" || field === "hour"
				|| field === "minute" || field === "absoluteTimeFrom" || field === "absoluteTimeTo"
				|| field === "fileType" || field === "include" || field === "privacy") {
				return update(st, { ui: { currentExport: { [field]: { $set: value } } } });
			}
			if (field === "displayKey" || field === "timeFrameType" || field === "channel" || field === "area"
				|| field === "searchInterval" || field === "timeZone" || field === "time" || field === "activate"
				|| field === "positiveTags" || field === "negativeTags") {
				return update(st, { ui: { currentExport: { [field]: { $set: value } } } });
			}
			if (field === "positiveWords" || field === "negativeWords" || field === "positiveFromAddress"
				|| field === "negativeFromAddress") {
				return update(st, { ui: { currentExport: { [field]: { $push: [value] } } } });
			} else if (field === "duration_Input") {
				return update(st, { ui: { currentExport: { duration_Filter: { $set: value } } } });
			} else {
				//Advance filter for Call history export
				if (field.indexOf("_Filter") > -1) {
					if (isRemove) {
						// if remove, "index" provided as value
						const index = value;
						const currentList = st.ui.currentExport[field];
						let newListArr = [];
						if (currentList) {
							newListArr = currentList.split(",");
						}
						if (index > -1) {
							newListArr.splice(index, 1);
						}
						const newFilterTxts = newListArr.join(",");
						return update(st, { ui: { currentExport: { [field]: { $set: newFilterTxts } } } });
					}
					const currentList = st.ui.currentExport[field];
					let newListArr = [];
					if (currentList) {
						newListArr = currentList.split(",");
					}
					newListArr.push(value);
					const newFilterTxts = newListArr.join(",");
					return update(st, { ui: { currentExport: { [field]: { $set: newFilterTxts } } } });
				} else {
					return update(st, { ui: { currentExport: { [field]: { $set: value } } } });
				}
			}
		}
	}
	if(act.type === SET_ERRAND_CATEGORY) {
		if(p.field === "positiveCategory" || p.field === "negativeCategory") {
			return update(st, {ui: {currentExport: {[p.field]: {$push: [p.value]}}}});
		}
	}
	if(act.type === REMOVE_ERRAND_CATEGORY) {
		if(p.field === "positiveCategory" ) {
			const index = st.ui.currentExport.positiveCategory.indexOf(p.value);
			return update(st, {ui: {currentExport: {[p.field]: {$splice: [[index, 1]]}}}});
		}
		if(p.field === "negativeCategory") {
			const index = st.ui.currentExport.negativeCategory.indexOf(p.value);
			return update(st, {ui: {currentExport: {[p.field]: {$splice: [[index, 1]]}}}});
		}
	}
	if(act.type === REMOVE_SEARCH_TERM) {
		if(p.field === "positiveWords" ) {
			const index = st.ui.currentExport.positiveWords.indexOf(p.value);
			return update(st, {ui: {currentExport: {[p.field]: {$splice: [[index, 1]]}}}});
		}
		if(p.field === "negativeWords") {
			const index = st.ui.currentExport.negativeWords.indexOf(p.value);
			return update(st, {ui: {currentExport: {[p.field]: {$splice: [[index, 1]]}}}});
		}
		if(p.field === "positiveFromAddress" ) {
			const index = st.ui.currentExport.positiveFromAddress.indexOf(p.value);
			return update(st, {ui: {currentExport: {[p.field]: {$splice: [[index, 1]]}}}});
		}
		if(p.field === "negativeFromAddress") {
			const index = st.ui.currentExport.negativeFromAddress.indexOf(p.value);
			return update(st, {ui: {currentExport: {[p.field]: {$splice: [[index, 1]]}}}});
		}
	}
	if(act.type === EXPORT_PREVIEW_CLOSE) {
		return update(st, {ui: {showGeneratedExport: {$set: false}, showExportPreview: {$set: false}}});
	}
	if(act.type === done(keyActivateExport)) {
		return update(st, {exportList: {data: {normalizeList: {[p.data.id]: {activate: {$set: p.data.active}}}}}});
	}
	if(act.type === done(keyRemoveExport)) {
		const index = st.exportList.data.tableList.indexOf(p.data.id);
		return update(st, {exportList: {data: {tableList: {$splice: [[index, 1]]}}}});
	}
	if(act.type === done(keyUpdateExport)) {
		const obj = update(p.param, {action: {$set: p.data.action}})
		return update(st, {exportList: {data: {normalizeList: {[p.data.id]: {$set: obj}}}}});
	}
	if(act.type === done(keyCreateExport)) {
		const obj = update(p.param, {id: {$set: p.data.id}, action: {$set: p.data.action}})
		return update(st, {exportList: {data: {
			normalizeList: {$merge: {[p.data.id]: obj}},
			tableList: {$push: [p.data.id]}
		}}});
	}
	if(act.type === done(keyRemoveGeneratedExport)) {
		const index = st.generatedList.data.list.indexOf(p.data.id);
		return update(st, {generatedList: {data: {list: {$splice: [[index, 1]]}}}});
	}
	if(act.type === done(keyGeneratedExport)) {
		return update(st, {ui: {showGeneratedExport: {$set: true}}});
	}
	if(act.type === done(keyGeneratedPreview)) {
		return update(st, {ui: {showExportPreview: {$set: true}}});
	}
	if(act.type === TOGGLE_EXPORT_POPUP) {
		return update(st, {ui: {showPopup: {$set: p}}})
	}
	if(act.type === UPDATE_EXPORT_CALL_LIST) {
		if(p.action === "add") {
			return update(st, {exportCallList: {data: {normalizeList: {[p.id]: {$set: p}}}}});
		}
		if(p.action === "remove") {
			return update(st, {exportCallList: {data: {normalizeList: {$unset: [p.id]}}}});
		}
		if(p.action === "activate") {
			return update(st, {exportCallList: {data: {normalizeList: {[p.id]: {activate: {$set: p.value}}}}}});
		}
	}
	if(act.type === done(keyGeneratedCdrExport)) {
		console.info("payload:",p);
		return update(st, {ui: {showGeneratedCdrExport: {$set: true}}});
	}
	if(act.type === done(keyRemoveGeneratedCdrExport)) {
		const index = st.generatedCdrList.data.list.indexOf(p.data.id);
		return update(st, {generatedCdrList: {data: {list: {$splice: [[index, 1]]}}}});
	}
	if(act.type === CALL_EXPORT_CDR_CLOSE) {
		return update(st, {ui: {showGeneratedCdrExport: {$set: false}}});
	}
	return st;
};

const initUI = {
	editSettings: false,
	showGeneratedExport: false,
	showGeneratedCdrExport: false,
	showExportPreview: false,
	selectedRowId: 0,
	currentExport: {
		name: "",
		displayKey: [],
		timeFrameType: 0,
		area: [],
	},
	showPopup: false
};

const fetchExportList = () => defAsyncReducer(keyExportList, asyncInitState);
const fetchExportCallList = () => defAsyncReducer(keyExportCallList, asyncInitState);
const fetchTagList = () => defAsyncReducer(keySimpleTagListByArea, asyncInitState);
const fetchGeneratedList = () => defAsyncReducer(keyGeneratedExport, asyncInitState);
const fetchGeneratedPreview = () => defAsyncReducer(keyGeneratedPreview, asyncInitState);
const fetchGeneratedCdrList = () => defAsyncReducer(keyGeneratedCdrExport, asyncInitState);

const setCurrentExport = (st, act) => {
	if(act.type === SET_SELECTED_EXPORT) {
		const {id, view} = act.payload;
		let item;
		if(view === MM_EXPORT_CALL) {
			if(st.exportCallList.data) {
				if(st.exportCallList.data.normalizeList && st.exportCallList.data.normalizeList[id]) {
					item = st.exportCallList.data.normalizeList[id];
				}
			}
		} else {
			if(st.exportList.data) {
				item = st.exportList.data.normalizeList[id];
			}
		}
		return update(st, {ui: {selectedRowId: {$set: id}, editSettings: {$set: true}, currentExport: {$set: item}, showGeneratedExport: {$set: false}}});
	}
	return st;
};
const CancelCurrentExport = (st, act) => {
	if(act.type === CANCEL_CURRENT_EXPORT) {
		return update(st, {ui: {selectedRowId: {$set: 0}, editSettings: {$set: false}, currentExport: {$set: null}}});
	}
	return st;
};

let exports = combineReducers({
	exportList: fetchExportList(),
	exportCallList: fetchExportCallList(),
	tagList: fetchTagList(),
	generatedList: fetchGeneratedList(),
	generatedCdrList: fetchGeneratedCdrList(),
	generatedPreview: fetchGeneratedPreview(),
	ui: (st = initUI, act) => st,
});
exports = reduceReducers(
	exports,
	setCurrentExport,
	CancelCurrentExport,
	updateExportReducer
);
export default exports;
