import { combineReducers } from 'redux'
import each from 'lodash/each'
import update from 'immutability-helper'
import { emptyObject } from '../../common/constants'
import { initEditValue } from '../../common/v5/smtpConstants'
import {
  AWSSES_EDIT_FIELD,
  AWSSES_FINISH_EDIT,
  AWSSES_START_EDIT
} from '../constants/constants'
import {
  keyAWSSESIdentities,
  keyAWSSESIdentity,
  keyCreateAWSSESIdentity,
  keyDeleteAWSSESIdentity
} from '../constants/keys'
import {
  asyncInitState,
  asyncDoneActionType,
  createReducer,
  defAsyncReducer,
  normalizeRemoverUpdater
} from '../util'
import {
  BRANCH_IDS_BY_REGION,
  BRANCH_IDENTITY_BY_REGION,
  adminAsyncMap,
  adminStateName
} from '../selectors/awsses'
import { createEditActionReducer } from './common'

const done = key => asyncDoneActionType(adminAsyncMap[key])

const initAWSSESState = { which: 'awsses', ...initEditValue }

const [
  _adminEditActionMap,
  _adminEditReducerMap
] = createEditActionReducer(
  AWSSES_START_EDIT,
  AWSSES_EDIT_FIELD,
  AWSSES_FINISH_EDIT,
  initAWSSESState
)

export const adminEditActionMap = _adminEditActionMap

const checkAndUpdateNorm = ({ allIds }, data) => {
  const { id } = data
  const updater = { byId: { [id]: { $set: data } } }
  let exist
  each(allIds, _id => {
    if (_id === id) {
      exist = true
      return false
    }
  })
  if (!exist) {
    updater.allIds = { $push: [id] }
  }
  return updater
}

const _listReducers = {
  [done(keyDeleteAWSSESIdentity)]: (
    state,
    { payload: { data: { id: serverId }, param: { region, id } } }
  ) => {
    if (serverId !== id) {
      console.log('error as identity not match:', serverId, id)
    }
    const regionState = state[region]
    if (!regionState) {
      console.log(`no ${region} data for delete`)
      return state
    }
    const updater = normalizeRemoverUpdater(serverId, regionState)
    if (!updater) {
      return state
    }
    return update(state, { [region]: updater })
  },
  [done(keyCreateAWSSESIdentity)]: (
    state,
    { payload: { data, param: { id, region } } }
  ) => {
    const regionState = state[region]
    if (!regionState) {
      console.log(`no ${region} data during create`)
      return state
    }
    return update(state, { [region]: checkAndUpdateNorm(regionState, data) })
  },
  [done(keyAWSSESIdentities)]: (
    state,
    { payload: { data, param: { region } } }
  ) => update(state, { [region]: { $set: data } }),
  [done(keyAWSSESIdentity)]: (
    state,
    { payload: { data, param: { region, id } } }
  ) => {
    const serverId = data.id
    if (serverId !== id) {
      console.log('error as identity not match:', serverId, id)
    }
    return update(state, { [region]: { byId: { [serverId]: { $set: data } } } })
  }
}

const updateStateByRegionAndId = (state, data, region, id) => {
  const regionState = state[region]
  if (regionState) {
    return update(state, { [region]: { [id]: { $set: data } } })
  }
  return update(state, { [region]: { $set: { [id]: data } } })
}

const updateListReducer = (
  state,
  { payload: { data, param: { region, id } } }
) => {
  const serverId = data.id
  if (typeof id !== 'undefined') {
    if (serverId !== id) {
      console.log('error as identity not match:', serverId, id)
    }
  }
  return updateStateByRegionAndId(state, data, region, serverId)
}

const _idByResionReducers = {
  [done(keyCreateAWSSESIdentity)]: updateListReducer,
  [done(keyAWSSESIdentity)]: updateListReducer
}

const _editReducers = { ..._adminEditReducerMap }

export const admin = combineReducers({
  [adminStateName(keyDeleteAWSSESIdentity)]: defAsyncReducer(
    keyDeleteAWSSESIdentity,
    asyncInitState
  ),
  [adminStateName(keyCreateAWSSESIdentity)]: defAsyncReducer(
    keyCreateAWSSESIdentity,
    asyncInitState
  ),
  [adminStateName(keyAWSSESIdentities)]: defAsyncReducer(
    keyAWSSESIdentities,
    asyncInitState
  ),
  [adminStateName(keyAWSSESIdentity)]: defAsyncReducer(
    keyAWSSESIdentity,
    asyncInitState
  ),
  [BRANCH_IDS_BY_REGION]: createReducer(emptyObject, _listReducers),
  [BRANCH_IDENTITY_BY_REGION]: createReducer(emptyObject, _idByResionReducers),
  edit: createReducer(initAWSSESState, _editReducers)
})
