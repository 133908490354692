import update from 'immutability-helper';
import { I } from '../../common/v5/config.js';
import {
	DISABLE_PRINT,
	ENABLE_PRINT_COL_CONVERSATION,
	ENABLE_PRINT_COL_MESSAGE,
	ENABLE_PRINT_ERRANDS
} from '../constants/constants';
import {
	PRINT_COL_CONVERSATION,
	PRINT_COL_MESSAGE,
	PRINT_ERRANDS
} from '../../common/v5/constants';
import { createReducer } from '../util';

const printInit = {
	show: false,
	header: '',
	type: PRINT_ERRANDS,
	data: null,
	time: 0
};

const multiPrintReducers = {
	[DISABLE_PRINT]: (state, action) => {
		return update(state, {$set: printInit});
	},
	[ENABLE_PRINT_ERRANDS]: (state, action) => {
		return update(state, {
			show: {$set: true},
			header: {$set: I('Print errand')},
			type: {$set: PRINT_ERRANDS},
			data: {$set: action.payload}
		});
	},
	[ENABLE_PRINT_COL_MESSAGE]: (state, action) => {
		const { thread, answer, id, time } = action.payload;
		return update(state, {
			show: {$set: true},
			header: {$set: I('Print collaboration')},
			type: {$set: PRINT_COL_MESSAGE},
			data: {$set: {thread, answer, id}},
			time: {$set: time}
		});
	},
	[ENABLE_PRINT_COL_CONVERSATION]: (state, action) => {
		const { thread, time } = action.payload;
		return update(state, {
			show: {$set: true},
			header: {$set: I('Print collaboration conversation')},
			type: {$set: PRINT_COL_CONVERSATION},
			data: {$set: thread},
			time: {$set: time}
		});
	}
};

const print = createReducer(printInit, multiPrintReducers);

export default print;
