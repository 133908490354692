import { combineReducers } from "redux";
// import { createSelector } from 'reselect';
import memoizeOne from 'memoize-one';
import update from 'immutability-helper';
import reduceReducers from 'reduce-reducers';

import * as keys from '../constants/keys';
import * as constant from '../../redux/constants/constants';
import {
	JUST_LOADED_STR,
	UNSELECT_STR,
	OPEN_STR,
	// ME_CREATE,
	// ME_CREATE_AS_NEW,
	ME_ST_BUSY,
	ME_ST_CREATED,
	ME_ST_IDLE,
	ME_ST_SAVE,
	RC_IM,
	RPLY_MANUAL_IM,
	// UNSELECT,
	// ICST_UNKNOWN,
	E_A_UNKNOWN,
	NEW_IM_ID	//lwb
} from '../../common/v5/constants'
import {
	// async,
	initWithOpr,
	mcamByID,
	asyncInitState,
	createReducer,
	defAsyncReducer,
	defMCAMReducer,
	asyncDoneActionType,
	// asyncRequestActionType,
	selectIMDataAsync,
	checkIfAllIMSelected,
	updateSelectAllIM,
	updateMsgReadState,
	selectAgentDataAsync,
	selectGroupDataAsync
} from '../util';
 import { imKeys } from '../actions/async/internalMessages';

// const req = key => asyncRequestActionType(imKeys[key]);

const done = key => asyncDoneActionType(imKeys[key]);
// const done = key => asyncDoneActionType('FetchMsgHistory');

const parseIMList = (contents, res, preOpr) => {
	if (contents.IMs != undefined) {
		const rawList = contents.IMs.slice();
		let orderedResult = [],
			norm = {},
			opr = {};
		res.errandRawSrcList = contents.IMs;
		$.each(rawList, (j, w) => {
			const v = w.mid;
			orderedResult.push(v);
			norm[v] = w;
			const o = preOpr[v];
			if (o === undefined) {
				opr[v] = {selected: false};
			} else {
				opr[v] = o;
			}
		});
		res.order = orderedResult;
		res.norm = norm;
		res.opr = opr;
	}
	return res;
};

function IMListProcessor(mcamID, data, preOpr) {
	return parseIMList(mcamByID(mcamID, data), {}, preOpr);
}

const memoizedIMListProcessor = memoizeOne(IMListProcessor);

const partialInternalMessageList = () => defAsyncReducer(keys.fetchInternalMessageList, initWithOpr,
	(state, action) => {
		const {data} = action.payload
		let {order, norm, opr} = memoizedIMListProcessor(
			'fetchInternalMessageList', data, state.data.opr
		)
		return update(state, {data: {$merge: {
			order, opr, norm
		}}});
	}
)

const getInternalMessage = () => defAsyncReducer(keys.keyGetInternalMessage, asyncInitState, (state, action) => {
	return update(state, {data: {$set: action.payload.data}});
});

const manualIMUIInit = {
	state: ME_ST_IDLE,
	createdId: NEW_IM_ID
};

const agentBookUIInit = {
	show: false,
	replyType: "",
	context: ""
	/* more agentBook UI states */
}

const groupBookUIInit = {
	show: false,
	replyType: "",
	context: ""
}

const manualInputsInitState = {
	area: 0,
	answer_state: E_A_UNKNOWN,
	answer_id: 0,
	reply_channel: RC_IM,
	update_area_id: 0,
	update_channel: 0,
	update_from: '', // string or integer
	update_subject: '',
	update_to: [],
	update_group: [],
	plain_answer: '',
	update_answer: '',
};

const clearIMInputsReducer = (state, action) => update(state, {$set: manualInputsInitState});

const manualInputsReducers = {
	[constant.CLEAR_IM_INPUTS]: clearIMInputsReducer,
};

const manualInputsReducer = createReducer(manualInputsInitState, manualInputsReducers);

const updateOpenIMState = (st = initOpenIM, act) => {
	if (act.type === constant.UPDATE_CURRENT_IM) {
		return update(st, {data: {$set: act.payload.data}});
	} else if(act.type === constant.SELECT_AGENT_IN_AGENT_LIST){
		const {id, select} = act.payload;
		return selectAgentDataAsync(st, id, select);
	} else if(act.type === constant.IM_SET_AGENT_LIST) {
		const {agents} = act.payload;
		let selected = {};
		$.each(agents, (i,v) => {
			 selected[v.Id] = {selected: false, name: v.Name};
		});
		return update(st, {agentCard: {agentList: {$set: selected}}});
	} else if(act.type === constant.IM_UPDATE_SELECTED_AGENT) {
		const {value, agents} = act.payload;
		let selected = {};
		$.each(agents, (i,v) => {
			let isFound = false;
			// let agentID = 0;
			$.each(value, (i1,v1) => {
				if(v.Name === v1) {
					isFound = true;
					// agentID = v.Id;
					return false;
				}
			});
			if (isFound) {
				selected[v.Id] = {selected: true, name: v.Name};
			} else {
				selected[v.Id] = {selected: false, name: v.Name};
			}
		});
		return update(st, {agentCard: {agentList: {$set: selected}}
		});
	} else if(act.type === constant.SELECT_GROUP_IN_GROUP_LIST) {
		const {id, select} = act.payload;
		return selectGroupDataAsync(st, id, select);
	} else if(act.type === constant.IM_SET_GROUP_LIST) {
		const {groups} = act.payload;
		let selected = {};
		$.each(groups, (i,v) => {
			// if(v.Name !== "guest") {
				 selected[v.Id] = {selected: false, name: v.Name};
			// }
		});
		return update(st, {groupCard: {groupList: {$set: selected}}});
	} else if(act.type === constant.IM_UPDATE_SELECTED_GROUP) {
		const {value, groups} = act.payload;
		let selected = {};
		$.each(groups, (i,v) => {
			// if(v.Name !== "guest") {
				let isFound = false;
				// let agentID = 0;
				$.each(value, (i1,v1) => {
					if(v.Name === v1) {
						isFound = true;
						// agentID = v.Id;
						return false;
					}
				});
				if (isFound) {
					selected[v.Id] = {selected: true, name: v.Name};
				} else {
					selected[v.Id] = {selected: false, name: v.Name};
				}
			// }
		});
		return update(st, {groupCard: {groupList: {$set: selected}}
		});

	}
	return st;
};

function selectMessageList(st, act) {
	if(act.type === constant.SELECT_MESSAGE_IN_MESSAGE_LIST) {
		const {id, select} = act.payload;
		return selectIMDataAsync(st, id, select);
	}
	return st;
}

const setIMReducer = (st, act) => {
	if(act.type === constant.SET_CURRENT_IM) {
		const { id } = act.payload;
		// if(id === 0) {
		// 	return update(st, {
		// 		currentMessage: {$merge: {id, type: UNSELECT_STR}}
		// 	});
		// }
		// return update(st, {
		// 	currentMessage: {$merge: {id, type: OPEN_STR}},
		// });
		return update(st, {
			currentMessage: {$merge: {id}},
		});

	}else if(act.type === constant.SHOW_AGENT_BOOK){
		const p = act.payload;
		return update(st, {ui: {agentBook: {
				show: {$set: p}}}});

	}else if(act.type === constant.SHOW_GROUP_BOOK){
		const p = act.payload;
		return update(st, {ui: {groupBook: {
				show: {$set: p}}}});

	}else if(act.type === constant.IM_SET_REPLY_ADDRESS_TYPE){
		const p = act.payload;
		const AgentOrGroup = p.AgentOrGroup;
		return update(st, {ui: {[AgentOrGroup]: { //agentBook/groupBook
			replyType: {$set: p.replyType},
			context: {$set: p.context}
		}}});

	}else if(act.type === constant.UPDATE_AGENT_BOOK){
		let p = act.payload;
		if(p.field === "list"){
			return update(st, {currentMessage: {agentBook: {$set: p.obj}}});
		}

	}else if(act.type === constant.UPDATE_GROUP_BOOK){
		let p = act.payload;
		if(p.field === "list"){
			return update(st, {currentMessage: {groupBook: {$set: p.obj}}});
		}
	}else if(act.type === constant.UPDATE_AGENT_CARD){
		let p = act.payload;
		if(p.field === "assign"){
			let agent = {}
			let c = p.obj.agent;
			agent = {id: c.id, name: c.name, avatar: c.avatar};
			return update(st, {currentMessage: {agentCard: {agent: {$merge: agent}}}});
		}

	}else if(act.type === constant.UPDATE_GROUP_CARD){
		let p = act.payload;
		if(p.field === "assign"){
			let group = {}
			let c = p.obj.group;
			group = {id: c.id, name: c.name};
			return update(st, {currentMessage: {groupCard: {group: {$merge: group}}}});
		}
	} else if(act.type === done(keys.keyFetchMsgHistory)) {
		return update(st, {currentMessage: {history: {$set: true}}});
	}
	return st;
};

const imInputsReducer = (st, act) => {
	if(act.type === constant.INPUT_IM_TEXT_CHANGES) {
		const {which, reply, ...p} = act.payload;
		let inpt;
		if(reply === RPLY_MANUAL_IM) {
			inpt = 'manualInputs';
		} else {
			inpt = 'inputs';
		}
		if(which === 'update_subject') {
			return update(st, {[inpt]: {[which]: {$set: p.value}}});
		} else if(which === 'update_answer') {
			return update(st, {[inpt]: {
				update_answer: {$set: p.value},
				plain_answer: {$set: p.plain}
			}});
		}

	} else if(act.type === constant.IM_RECIPIENTS_CHANGE) {
		const {which, value, reply, agents} = act.payload,
			updateRecipient = 'update_' + which;
		let inpt;
		if(reply === RPLY_MANUAL_IM) {
			inpt = 'manualInputs';
		} else {
			inpt = 'inputs';
		}
		let selected = {};
		$.each(agents, (i,v) => {
			let isFound = false;
			// let agentID = 0;
			$.each(value, (i1,v1) => {
				if(v.Name === v1.value) {
					isFound = true;
					// agentID = v.Id;
					return false;
				}
			});
			if (isFound) {
				selected[v.Id] = {selected: true, name: v.Name};
			} else {
				selected[v.Id] = {selected: false, name: v.Name};
			}
		});
		return update(st, {
			[inpt]: {[updateRecipient]: {$set: value}},
			currentMessage: {agentCard: {agentList: {$set: selected}}}
		});

	} else if(act.type === constant.IM_GROUPS_CHANGE) {
		const {which, value, reply, groups} = act.payload,
			updateRecipient = 'update_' + which;
		let inpt;
		if(reply === RPLY_MANUAL_IM) {
			inpt = 'manualInputs';
		} else {
			inpt = 'inputs';
		}
		let selected = {};
		$.each(groups, (i,v) => {
			// if(v.Name !== "guest") {
				let isFound = false;
				$.each(value, (i1,v1) => {
					if(v.Name === v1.value) {
						isFound = true;
						return false;
					}
				});
				if (isFound) {
					selected[v.Id] = {selected: true, name: v.Name};
				} else {
					selected[v.Id] = {selected: false, name: v.Name};
				}
			// }
		});
		return update(st, {
			[inpt]: {[updateRecipient]: {$set: value}},
			currentMessage: {groupCard: {groupList: {$set: selected}}}
		});

	} else if(act.type === constant.IM_APPEND_AGENT_REPLY_ADDRESS) {
		const ab = st.ui.agentBook;
		const cacl = st.currentMessage.agentCard.agentList;
		let name = "", inpt; //, agentId = 0

		// const ca = st.currentMessage.agentCard.agent;
		if(ab.context === "manual") {
			inpt = 'manualInputs';
			// name = ca.name;
			// agentId = ca.id;
			// if(name != ""){
			// 	let item = {id: agentId, value: name};
			// 	if(ab.replyType === 'to'){
			// 		return update(st, {[inpt]: {update_to: {$push: [item]}}});
			// 	}
			// }

			let selected = [];
			$.each(cacl, (i,v) => {
				if(v.selected) {
					let item = {id: i, value: v.name};
					selected.push(item);
				}
			});
			return update(st, {[inpt]: {update_to: {$set: selected}}});

		} else {	//"reply"
			inpt = 'inputs';
			let origin = "" ;
			let originId = 0 ;
			let subject ="";
			if (st.currentMessage.data) {
				origin = st.currentMessage.data.origin;
				originId = st.currentMessage.data.originId;
				if (st.currentMessage.data.subject.indexOf('Re:') == 0) {
					subject = st.currentMessage.data.subject;
				} else {
					subject = "Re: " + st.currentMessage.data.subject;
				}
			}
			name = origin;
			if(name != ""){
				let item = {id: originId, value: name};
				if(ab.replyType === 'to'){
					return update(st, {[inpt]: {update_to: {$set: [item]}, update_subject: {$set: subject}}});
				}
			}
			if(param && param.id && state.data.norm && state.data.norm[param.id]) {
				return update(state, {data: {norm: {[param.id]: {$set: data}}}});
			}
		}

	} else if(act.type === constant.IM_APPEND_GROUP_REPLY_ADDRESS) {
		const ab = st.ui.groupBook;
		// const ca = st.currentMessage.groupCard.group;
		const cacl = st.currentMessage.groupCard.groupList;
		// let name = "", groupId = 0, inpt;
		let inpt;
		if(ab.context === "manual") {
			inpt = 'manualInputs';
			// name = ca.name;
			// groupId = ca.id;
			// if(name != ""){
			// 	let item = {id: groupId, value: name};
			// 	if(ab.replyType === 'group'){
			// 		return update(st, {[inpt]: {update_group: {$push: [item]}}});
			// 	}
			// }
			let selected = [];
			$.each(cacl, (i,v) => {
				if(v.selected) {
					let item = {id: i, value: v.name};
					selected.push(item);
				}
			});
			return update(st, {[inpt]: {update_group: {$set: selected}}});

		//don't reply to group
		// } else {	//"reply"
		// 	inpt = 'inputs';
		// 	const cmid = st.currentMessage.id;
		// 	let origin = "" ;
		// 	let originId = 0 ;
		// 	let subject ="";
		// 	if (cmid > 0) {
		// 		origin = st.messageList.data.norm[cmid].origin;
		// 		originId = st.messageList.data.norm[cmid].originId;
		// 		subject = "Re: " + st.messageList.data.norm[cmid].subject;
		// 	}
		// 	name = origin;
		// 	if(name != ""){
		// 		let item = {id: originId, value: name};
		// 		if(ab.replyType === 'to'){
		// 			return update(st, {[inpt]: {update_to: {$set: [item]}, update_subject: {$set: subject}}});
		// 		}
		// 		// if(ab.replyType === 'group'){
		// 		// 	return update(st, {[inpt]: {update_group: {$set: [item]}, update_subject: {$set: subject}}});
		// 		// }
		// 	}
		// 	if(param && param.id && state.data.norm && state.data.norm[param.id]) {
		// 		return update(state, {data: {norm: {[param.id]: {$set: data}}}});
		// 	}
		}

	} else if(act.type === constant.IM_APPEND_TO_AGENT) {
		const {agentId, agentName} = act.payload;
		const inpt = 'manualInputs';
		let item = {id: agentId, value: agentName};
		return update(st, {[inpt]: {update_to: {$push: [item]}}});

	} else if(act.type === constant.IM_APPEND_TO_GROUP) {
		const {groupId, groupName} = act.payload;
		const inpt = 'manualInputs';
		let item = {id: groupId, value: groupName};
		return update(st, {[inpt]: {update_group: {$push: [item]}}});

	}

	return st;
};

let imUIInitials ={
	showIMReply: true,
	showMessageListSort: false,
	messageListSort: 0,
	isFetching: true,
	filter:{
		currentCtx: 'Internal messages',
		selectedFolder: 0,
	},
	sorting:{
		sort_attribute: 'date',
		sort_direction: 'desc',
		},
	// showSideBar: isMobile ? false : true,
	showIMPopAddMessage: false,  //lwb
	// reply: uiReplyInit,
	// manual: createReducer(manualIMUIInit,uiLocalReducers),
	manual: manualIMUIInit,
	agentBook: agentBookUIInit,
	groupBook: groupBookUIInit,
	isNewIM:{
		newIM: true,
		imID: 0,
	},
	isIMLoad: false,
	isNewThread: false
}

const initOpenIM = {
	id: 0,
	// type: JUST_LOADED_STR,
	agentCard:{
		// channel:{},
		agent:{
			id: 0,
			name: "",
			// date: "",
			avatar: ""
		},
		agentList:[]
	},
	groupCard:{
		// channel:{},
		group:{
			id: 0,
			name: "" //,
			// date: ""
		},
		groupList:[]
	},
	agentBook:[],
	groupBook:[],
	history: false,
};

const openIMReducer = (st = initOpenIM, act) => {
	return st;
};

const sendAnswer = () => defMCAMReducer(keys.keySendIMAnswer, asyncInitState, 'SendIM');

const saveDraft = () => defMCAMReducer(keys.keySaveIMDraft, asyncInitState,	'SaveIMDraft');

let imCounterInitials = {
	imMessage:{
		count: 0,
		colour: '',
	},
	drafts:{
		count: 0,
		colour: '',
	},
	sent:{
		count: 0,
		colour: '',
	},
	trash:{
		count: 0,
		colour: '',
	},
	newIM:{
		count: 0,
		colour: '',
	}
}

function selectAllMessages(st, act) {
	if(act.type === constant.SELECT_MESSAGE_IN_MESSAGE_LIST) {
		return checkIfAllIMSelected(st);
	} else if(act.type === constant.SELECT_ALL_MESSAGES_IN_MESSAGE_LIST) {
		return updateSelectAllIM(st, act.payload);
	}
	return st;
}

function updateMsgRead(st, act) {
	if(act.type === constant.UPDATE_MESSAGE_READ) {
		const mid = act.payload;
		return updateMsgReadState(st, mid);
	}
	return st;
}

// IM open reducer code - any reducer under this branch should be added
// top of this line as not break the init state and array of reducers.
const IMInputsInitState = {
	update_subject: '',
	update_to: [],
	update_group: [],
	plain_answer: '',
	update_answer: '',
};

const inputsReducers = {
	[constant.IM_CKEDITOR_REFORMAT_INPUT]: (state, action) => {
		const {plain, value} = action.payload;
		return update(state, {
			update_answer: {$set: value},
			plain_answer: {$set: plain}
		});
	}
};

const selectMsgReducer = (state, action) => {
	// if (action.type === SELECT_FORWARD_HISTORY) {
	// 	const { id, value } = action.payload;
	// 	return updateForwardAllReducer(
	// 		update(state, {data: {opr: {[id]: {selected: {$set: value}}}}})
	// 		, action
	// 	);
	// } else
	// if (action.type === PRE_SELECT_FORWARD_HISTORY) {
	// 	return loopAllSelectionOprSubReducer(state, action, false);
	// } else if (action.type === SELECT_FORWARD_ALL_HISTORIES) {
	// 	return loopAllSelectionOprSubReducer(state, action, action.payload.value);
	// }
	return state;
};

const fetchMsgHistory = () => defAsyncReducer(keys.keyFetchMsgHistory, initWithOpr,
	(state, action) => {
		const {data} = action.payload
		let {order, norm, opr} = memoizedIMListProcessor(
			'FetchMsgHistory', data, state.data.opr
		)
		return update(state, {data: {$merge: {
			order, opr, norm
		}}});
	}
);

const inputsReducer = createReducer(IMInputsInitState, inputsReducers);

let internalMessage = combineReducers({
	messageList: reduceReducers(partialInternalMessageList(), selectMessageList, selectAllMessages, updateMsgRead),
	currentMessage: reduceReducers(openIMReducer, updateOpenIMState, getInternalMessage()),
	inputs: inputsReducer,
	manualInputs: manualInputsReducer,
	// agentAreas: agentAreas(),
	// agents: agents(),
	// agentGroups:agentGroups(),
	sendAnswer: sendAnswer(),
	saveDraft: saveDraft(),
	counters: (state = imCounterInitials, action)=>{
		let p = action.payload;
		switch(action.type){
			case constant.IM_COUNTER:
				return update(state, {imMessage: {count: {$set: p.count}, colour: {$set: p.colour}}});
			case constant.IM_DRAFT_COUNTER:
				return update(state, {drafts: {count: {$set: p.count}, colour: {$set: p.colour}}});
			case constant.IM_SENT_COUNTER:
				return update(state, {sent: {count: {$set: p.count}, colour: {$set: p.colour}}});
			case constant.IM_TRASH_COUNTER:
				return update(state, {trash: {count: {$set: p.count}, colour: {$set: p.colour}}});
			case constant.NEW_IM_COUNTER:
				return update(state, {newIM: {count: {$set: p.count}, colour: {$set: p.colour}}});
			default:
				return state;
		}
	},
	fetchHistory: reduceReducers(fetchMsgHistory(), selectMsgReducer),

	ui: (state = imUIInitials, action)=>{
		let p = action.payload;
		switch(action.type){
			case constant.INTERNAL_MESSAGES_ISFETCHING:
				return update(state, {isFetching: {$set: p}});
			case constant.FILTER_MESSAGE_LIST_BY:
				return update(state, {filter:{ currentCtx: {$set: p}}});
			case constant.FILTER_FOLDER:
				return update(state, {filter:{ selectedFolder: {$set: p}}});
			case constant.TOGGLE_IM_POPUP:
				return update(state, {showIMPopAddMessage: {$set: p}});

			case constant.TOGGLE_SHOW_IM_REPLY:
				return update(state, {showIMReply: {$set: p}});

			case constant.SORT_PARAM:
				return update(state, {sorting:{ $set: p}});
				//sort_direction

			case constant.SHOW_AGENT_BOOK:
				return update(state, {agentBook: {show: {$set: p}}});

			case constant.SHOW_GROUP_BOOK:
				return update(state, {groupBook: {show: {$set: p}}});

			case constant.IM_BUSY: //IMBusyReducer;
				const {state: IMState, id} = action.payload;
				if(IMState === ME_ST_CREATED) {
					return update(state, {manual: {
							state: {$set: ME_ST_CREATED},
							createdId: {$set: id}
						}});
				} else if(IMState === ME_ST_IDLE) {
					return update(state, {manual: {
							state: {$set: ME_ST_IDLE},
							createdId: {$set: NEW_IM_ID}
						}});
				} else if(IMState === ME_ST_BUSY) {
					return update(state, {manual: {
							state: {$set: ME_ST_BUSY},
							createdId: {$set: id}
						}});
				} else if(IMState === ME_ST_SAVE) {
					return update(state, {manual: {
							state: {$set: ME_ST_SAVE},
							createdId: {$set: id}
					}});
				}
				return update(state, {manual: {state: {$set: IMState}}});

			case constant.SELECT_TOGGLE_SORT_MESSAGE_LIST:
				if(typeof p.value === 'boolean') {
					return update(state, {showMessageListSort: {$set: p.value}});
				}
				return update(state, {$merge: {showMessageListSort: false, messageListSort: p.index}});

			case constant.IS_NEW_IM:
				if (p.imID !== undefined) {
					return update(state, {isNewIM: {newIM: {$set: p.newIM}, imID: {$set: p.imID}}});
				}
				return update(state, {isNewIM: {newIM: {$set: p.newIM}}});

			case constant.IS_IM_LOAD:
				if (typeof p === 'boolean') {
				// if (p.isIMLoad !== undefined) {
					return update(state, {isIMLoad:  {$set: p}});
				}
				return state;

			case constant.IS_NEW_THREAD:
				if (typeof p === 'boolean') {
					return update(state, {isNewThread:  {$set: p}});
				}
				return state;

			default:
				return state;
		}
	},
});

internalMessage = reduceReducers(
	internalMessage,
	setIMReducer,
	reduceReducers(imInputsReducer)
);

export default internalMessage;
