import { combineReducers } from "redux";
import update from 'immutability-helper';
import {
	defAsyncReducer,
	asyncInitState,
	createReducer,
	asyncDoneActionType,
} from "../util";
import * as keys from '../constants/keys';
import * as constant from '../../redux/constants/constants';
import {
	UNSELECT_STR
} from '../../common/v5/constants';
import { mockErrand, mockTags, mockArea, mockKB } from './mockGlobalSearch';
import { workflow as workflowMap } from '../actions/async/workflow';
const done = key => asyncDoneActionType(workflowMap[key]);
let searchInitials ={
	headerText: "",
	replyText: "",
	bodyText: "",
	showResultBox: false,
	showReplyResultBox: false,
	searchOngoing: false,
	replySearchOngoing: false
}
let bodySearchUIInit = {
	searchBoxEmpty: false,
	showResult: false,
	// showResetFilters: false,
	showTopList: false,
	topResults: 10,
	showAllErrand: false,
	showAllCollabErrand: false,
	showAllKB: false,
	showAllArea: false,
	showAllTag: false,
	showAllContact: false,
	showAllEEContact: false,
	showAllCustomerNotes: false,
	showAllErrandNotes: false,
	isProcessing: false,
	errandOffset: 0,
	errandPage: 0,
	collabErrandOffset: 0,
	collabErrandPage: 0,
	areaOffset: 0,
	areaPage: 0,
	tagOffset: 0,
	tagPage: 0,
	kbOffset: 0,
	knowledgebasePage: 0,
	ccOffset: 0,
	eeOffset: 0,
	cnotesOffset: 0,
	enotesOffset: 0,
	contactCardPage: 0,
	eeContactPage: 0,
	customerNotesPage: 0,
	errandNotesPage: 0,
	currentPagination: "",
}

let initSearch = {
	id: 0,
	type: UNSELECT_STR,
	bodySearch: bodySearchUIInit,
	globalSearch: {
		isGlobal: false,
		isErrands: true,
		isCollaborations: false,
		isAreas: false,
		isTags: false,
		isContactCard: false,
		isKnowledgeBase: false,
		isEEContacts: false,
		isCustomerNotes: false,
		isErrandNotes: false,
		showAreaDD: false,
		showAdvanceOpts: false,
		showDateFrom: false,
		showDateTo: false,
		showAgentDD: false,
		showChannelDD: false,
		// showFolderDD: false,
		showTagsDD: false,
		errandTypesDD: false,
		sortDirectionDD: false,
		filterBy: 0, // 0:No filter, 1:My Errands, 2:All Errands, 3:Closed errands
		filterByLabel:{ // obsoleted
			my: false,
			all: false,
			close: false
		}
	},
	filters:{
		search:"",
		areas:"",
		agents:"",
		deleted:"", // obsoleted, substituted by errandTypes
		channels:"",
		tags:"",
		folders: 0, // obsoleted, substituted by errandTypes
		folder:"",
		date_type:"SearchArrivedDate",
		date_to: "",
		date_from: "",
		search_type:"",
		search_source:"",
		exactMatch:"no",
		active_flag:"no",
		autoRefresh: false,
		dateIntervalToggle: false,
		advancedOptions: "",
		errandTypes: 0,
		showInactive: false
	},
	defaultFilters:{ areas:"", agents: "", channels: "" },
	previewId: 0
}

let initResults = {
	opr: {},
	errandById: {},
	errands:	[],
	collabErrands:	[],
	areas:		[],
	tags:		[],
	knowledgeBases: [],
	contactCards: [],
	externalExpertContacts: [],
	customerNotes: [],
	errandNotes: [],
	totalErrand: 0,
	totalCollabErrands: 0,
	totalKBs: 0,
	totalAreas: 0,
	totalTags: 0,
	totalContacts: 0,
	totalExternalExpertContacts: 0,
	totalCustomerNotes: 0,
	totalErrandNotes: 0,
	totalMyErrand: 0,
	totalAllErrand: 0,
	totalClosedErrand: 0,
	totalCollabClosedErrand: 0,
	totalCollabMyErrand: 0,
	totalCollabAllErrand: 0,
	totalCollabClosedErrand: 0
}

const searchErrandReducers = (state, action) =>{
	let p = action.payload;
	if(p.actionFor === constant.SEARCH_TEXT_CLEAR) {
		return update(state, {
				bodySearch: {
					searchBoxEmpty: {$set: p.value},
					showAllErrand: {$set: false},
					showAllCollabErrand: {$set: false},
					showAllKB: {$set: false},
					showAllArea: {$set: false},
					showAllTag: {$set: false},
					showAllContact: {$set: false},
					showAllEEContact: {$set: false},
					showAllCustomerNotes: {$set: false},
					showAllErrandNotes: {$set: false},
					errandOffset: {$set: 0},
					currentPagination: {$set: ""}
				}
			}
		);
	}
	else if(p.actionFor === constant.LIST_SHOW_HIDE)
		return update(state, {bodySearch: {showTopList: {$set: p.value}}});
	else if(p.actionFor === constant.SELECT_TOP_LIST)
		return update(state, {bodySearch: {topResults: {$set: p.value}}});
	else{
		//
	}
	return state;
}

const globalSearchReducers = (state, action) =>{
	let p = action.payload;
	return update(state,{errands:{$merge: mockErrand}});
}
const handleGlobalSearch = (state, action) => {
	if (action.type != constant.WS_EVENT_GLOBAL_SEARCH) {
		return state;
	}
	let packet = action.packet;
	if(packet){
		if(action.paginateCtx === constant.SEARCH_ERRAND) {
			var opr = {}, errandById = {};
			if (packet.errand) {
				$.each(packet.errand, (i, v) => {
					const o = state.opr[v.id];
					if (o === undefined) {
						opr[v.id] = {selected: false};
					} else {
						opr[v.id] = o;
					}
					errandById[v.id] = v;
				});
			}
			return update(state, {
				opr: {$set: opr},
				errandById: {$set: errandById},
				errands: {$set: (packet.errand ? packet.errand : [])},
				totalErrand: {$set: (packet.errandCount ? packet.errandCount : 0)},
				totalMyErrand: {$set: (packet.myErrandCount ? packet.myErrandCount : 0)},
				totalAllErrand: {$set: (packet.allErrandCount ? packet.allErrandCount : 0)},
				totalClosedErrand: {$set: (packet.closedErrandCount ? packet.closedErrandCount : 0)},
		});
		}else if(action.paginateCtx === constant.SEARCH_CONTACTCARD){
			return update(state, {contactCards: {$set: (packet.contactCard ? packet.contactCard : [])},
								totalContacts: {$set: (packet.contactCount ? packet.contactCount : 0)}});
		}else if(action.paginateCtx === constant.SEARCH_KB){
			return update(state, {knowledgeBases: {$set: (packet.knowledgeBase ? packet.knowledgeBase : [])},
								totalKBs: {$set: (packet.kbCount ? packet.kbCount : 0)}});
		}else if(action.paginateCtx === constant.SEARCH_AREA){
			return update(state, {areas: {$set: (packet.area ? packet.area : [])},
								totalAreas: {$set: (packet.areaCount ? packet.areaCount : 0)}});
		}else if(action.paginateCtx === constant.SEARCH_TAGS){
			return update(state, {tags: {$set: (packet.tags ? packet.tags : [])},
								totalTags: {$set: (packet.tagCount ? packet.tagCount : 0)}});
		}else if(action.paginateCtx === constant.SEARCH_EECONTACT){
			return update(state, {externalExpertContacts: {$set: (packet.eeContact ? packet.eeContact : [])},
								totalExternalExpertContacts: {$set: (packet.customerNotesCount ? packet.customerNotesCount : 0)}});
		}else if(action.paginateCtx === constant.SEARCH_CUSTOMERNOTES){
			return update(state, {customerNotes: {$set: (packet.customerNotes ? packet.customerNotes : [])},
													totalCustomerNotes: {$set: (packet.customerNotesCount ? packet.customerNotesCount : 0)}});
		}else if(action.paginateCtx === constant.SEARCH_ERRANDNOTES){
			return update(state, {errandNotes: {$set: (packet.errandNotes ? packet.errandNotes : [])},
													totalErrandNotes: {$set: (packet.errandNotesCount ? packet.errandNotesCount : 0)}});
		}else{
			var opr = {}, errandById = {};
			if (packet.errand) {
				$.each(packet.errand, (i, v) => {
					const o = state.opr[v.id];
					if (o === undefined) {
						opr[v.id] = {selected: false};
					} else {
						opr[v.id] = o;
					}
					errandById[v.id] = v;
				});
			}

			if (packet.collabErrands) {
				$.each(packet.collabErrands, (i, v) => {
					const o = state.opr[v.id];
					if (o === undefined) {
						opr[v.id] = {selected: false};
					} else {
						opr[v.id] = o;
					}
					errandById[v.id] = v;
				});
			}
			return update(state, {
				opr: {$set: opr},
				errandById: {$set: errandById},
				errands: {$set: (packet.errand ? packet.errand : [])},
				collabErrands: {$set: (packet.collabErrands ? packet.collabErrands : [])},
				areas: {$set: (packet.area ? packet.area : [])},
				tags: {$set: (packet.tags ? packet.tags : [])},
				knowledgeBases: {$set: (packet.knowledgeBase ? packet.knowledgeBase : [])},
				contactCards: {$set: (packet.contactCard ? packet.contactCard : [])},
				externalExpertContacts: {$set: (packet.eeContact ? packet.eeContact : [])},
				customerNotes: {$set: (packet.customerNotes ? packet.customerNotes : [])},
				errandNotes: {$set: (packet.errandNotes ? packet.errandNotes : [])},
				totalErrand: {$set: (packet.errandCount ? packet.errandCount : 0)},
				totalMyErrand: {$set: (packet.myErrandCount ? packet.myErrandCount : 0)},
				totalAllErrand: {$set: (packet.allErrandCount ? packet.allErrandCount : 0)},
				totalClosedErrand: {$set: (packet.closedErrandCount ? packet.closedErrandCount : 0)},

				totalCollabErrands: {$set: (packet.collabErrandCount ? packet.collabErrandCount : 0)},
				totalCollabMyErrand: {$set: (packet.collabMyErrandCount ? packet.collabMyErrandCount : 0)},
				totalCollabAllErrand: {$set: (packet.collabAllErrandCount ? packet.collabAllErrandCount : 0)},
				totalCollabClosedErrand: {$set: (packet.collabClosedErrandCount ? packet.collabClosedErrandCount : 0)},

				totalKBs: {$set: (packet.kbCount ? packet.kbCount : 0)},
				totalAreas: {$set: (packet.areaCount ? packet.areaCount : 0)},
				totalTags: {$set: (packet.tagCount ? packet.tagCount : 0)},
				totalContacts: {$set: (packet.contactCount ? packet.contactCount : 0)},
				totalExternalExpertContacts: {$set: (packet.eeContactCount ? packet.eeContactCount : 0)},
				totalCustomerNotes: {$set: (packet.customerNotesCount ? packet.customerNotesCount : 0)},
				totalErrandNotes: {$set: (packet.errandNotesCount ? packet.errandNotesCount : 0)}
			});
		}
	}
	return state;
};

const setCurrentSearchOpt =(state, action)=>{
	switch(action.payload){
		case "GlobalSearch":
			return update(state, {globalSearch: {isGlobal: {$set: true}}, bodySearch: {currentPagination: {$set: ""}}});
		case "Errands":
			return update(state, {globalSearch: {isErrands: {$set: true}}, bodySearch: {currentPagination: {$set: ""}}});
		case "Collaborations":
			return update(state, {globalSearch: {isCollaborations: {$set: true}}, bodySearch: {currentPagination: {$set: ""}}});
		case "CustomerContacts":
			return update(state, {globalSearch: {isContactCard: {$set: true}}, bodySearch: {currentPagination: {$set: ""}}});
		case "KnowledgeBase":
			return update(state, {globalSearch: {isKnowledgeBase: {$set: true}}, bodySearch: {currentPagination: {$set: ""}}});
		case "Areas":
			return update(state, {globalSearch: {isAreas: {$set: true}}, bodySearch: {currentPagination: {$set: ""}}});
		case "Tags":
			return update(state, {globalSearch: {isTags: {$set: true}}, bodySearch: {currentPagination: {$set: ""}}});
		case "ExternalExpertContacts":
			return update(state, {globalSearch: {isEEContacts: {$set: true}}, bodySearch: {currentPagination: {$set: ""}}});
		case "CustomerNotes":
			return update(state, {globalSearch: {isCustomerNotes: {$set: true}}, bodySearch: {currentPagination: {$set: ""}}});
		case "ErrandNotes":
			return update(state, {globalSearch: {isErrandNotes: {$set: true}}, bodySearch: {currentPagination: {$set: ""}}});
	}
	return state;
}

const handleSearchFilter = (state, action) =>{
	let p = action.payload;
	return update(state, {globalSearch:{[p.option]:{$set: p.value}}});
}

const searchReducers = {
	[constant.SET_ERRAND_SEARCH_BOX]: (state, action) =>{
		return update(state, {searchText:{$set: action.payload}});
	},
	[constant.SHOW_ERRAND_SEARCH_CROSS_BOX]: searchErrandReducers,
	[constant.SET_CURRENT_SEARCH_SIDEBAR_OPTION]: setCurrentSearchOpt,
	[constant.UNSET_SEARCH_SIDEBAR_OPTION]: (state, action) =>{
		return update(state, {globalSearch:{
											isGlobal: {$set: action.payload},
											isErrands: {$set: action.payload},
											isCollaborations: {$set: action.payload},
											isContactCard: {$set: action.payload},
											isKnowledgeBase: {$set: action.payload},
											isAreas: {$set: action.payload},
											isTags: {$set: action.payload},
											isEEContacts: {$set: action.payload},
											isCustomerNotes: {$set: action.payload},
											isErrandNotes: {$set: action.payload}
										}
							}
					);
	},
	[constant.HANDLE_FILTER_SEARCH]: (state, action)=>{
		return update(state, {globalSearch: {filterBy: {$set: action.payload}}});
	},
	// obsoleted
	[constant.FILTER_SEARCH_ERRAND_MY]: (state, action)=>{
		return update(state, {globalSearch: {filterByLabel:{
																my: {$set: action.payload},
																all: {$set: false},
																close: {$set: false}
															}
														}
													}
												);
	},
	// obsoleted
	[constant.FILTER_SEARCH_ERRAND_ALL]: (state, action)=>{
		return update(state, {globalSearch: {filterByLabel: {
																all: {$set: action.payload},
																my: {$set: false},
																close: {$set: false}
															}
														}
													}
												);
	},
	// obsoleted
	[constant.FILTER_SEARCH_ERRAND_CLOSE]: (state, action)=>{
		return update(state, {globalSearch: {filterByLabel: {
																close: {$set: action.payload},
																all: {$set: false},
																my: {$set: false}
															}
														}
													}
												);
	},
	[constant.HANDLE_SEARCH_FILTER_OPTION]: handleSearchFilter,
	[constant.HANDLE_SEARCH_RESET_FILTER]: (state, action)=>{
		let p = action.payload;
		let {areas, agents, channels} = p;
		return update(state, {
			filters: {
				date_from: {$set: ""}
				,date_to: {$set: ""}
				,areas: {$set: areas}
				,agents: {$set: agents}
				,channels: {$set: channels}
				,tags: {$set: ""}
				,errandTypes: {$set: 0}
				,advancedOptions: {$set: ""}
				,showInactive: {$set: false}
			}
		})
	},
	[constant.HANDLE_SEARCH_FILTER_BY_AREA]: (state, action)=>{
		return update(state, {filters: {areas: {$set: action.payload}}})
	},
	[constant.HANDLE_SEARCH_FILTER_BY_AGENT]: (state, action)=>{
		return update(state, {filters: {agents: {$set: action.payload}}})
	},
	[constant.HANDLE_SEARCH_FILTER_BY_CHANNEL]: (state, action)=>{
		return update(state, {filters: {channels: {$set: action.payload}}})
	},
	[constant.HANDLE_SEARCH_FILTER_BY_TAG]: (state, action)=>{
		return update(state, {filters: {tags: {$set: action.payload}}})
	},
	[constant.HANDLE_TOGGLE_SEARCH_ADVANCED_OPT]: (state, action)=>{
		let inactive = false;
		let p = action.payload.split(',');
		if(p.length > 0){
			p.forEach(v =>{
				v = parseInt(v, 10);
				if(v === 2)/*ADVANCED OPTION(2) => SHOW INACTIVE*/
					inactive = true;
				})
		}
		return update(state, {filters: {advancedOptions: {$set: action.payload},showInactive: {$set: inactive}}})
	},
	[constant.HANDLE_TOGGLE_SEARCH_DATE_INTERVAL]: (state, action)=>{
		return update(state, {filters: {dateIntervalToggle: {$set: action.payload}}})
	},
	[constant.HANDLE_SEARCH_ERRAND_TYPE]: (state, action)=>{
		return update(state, {filters: {errandTypes: {$set: action.payload}}})
	},
	[constant.HANDLE_SEARCH_FROM_DATE]: (state, action)=>{
		return update(state, {filters: {date_from: {$set: action.payload}}})
	},
	[constant.HANDLE_SEARCH_TO_DATE]: (state, action)=>{
		return update(state, {filters: {date_to: {$set: action.payload}}})
	},
	[constant.HANDLE_SEARCH_SHOW_ALL_ITEM]: (state, action)=>{
		if(action.payload === constant.SEARCH_ERRAND)
			return update(state, {bodySearch: {showAllErrand: {$set: true}, currentPagination: {$set: action.payload}}})
		else if(action.payload === constant.SEARCH_COLLABORATION)
			return update(state, {bodySearch: {showAllCollabErrand: {$set: true}, currentPagination: {$set: action.payload}}})
		else if(action.payload === constant.SEARCH_AREA)
			return update(state, {bodySearch: {showAllArea: {$set: true}, currentPagination: {$set: action.payload}}})
		else if(action.payload === constant.SEARCH_TAGS)
			return update(state, {bodySearch: {showAllTag: {$set: true}, currentPagination: {$set: action.payload}}})
		else if(action.payload === constant.SEARCH_KB)
			return update(state, {bodySearch: {showAllKB: {$set: true}, currentPagination: {$set: action.payload}}})
		else if(action.payload === constant.SEARCH_CONTACTCARD)
			return update(state, {bodySearch: {showAllContact: {$set: true}, currentPagination: {$set: action.payload}}})
		else if(action.payload === constant.SEARCH_EECONTACT)
			return update(state, {bodySearch: {showAllEEContact: {$set: true}, currentPagination: {$set: action.payload}}})
		else if(action.payload === constant.SEARCH_CUSTOMERNOTES)
			return update(state, {bodySearch: {showAllCustomerNotes: {$set: true}, currentPagination: {$set: action.payload}}})
		else if(action.payload === constant.SEARCH_ERRANDNOTES)
			return update(state, {bodySearch: {showAllErrandNotes: {$set: true}, currentPagination: {$set: action.payload}}})
		else
			return state;
	},
	[constant.GLOBAL_SEARCH_INIT_BY_MCAM]: (state, action) =>{
		let p = action.payload;
		let {areas, agents, channels} = state.filters;
		return update(state, {filters: {areas: {$set: (areas !== "" ? areas : p.areas)}
										, agents: {$set: (agents !== "" ? agents : p.agents)}
										, channels:{$set: (channels !== "" ? channels : p.channels)}
										//, tags: {$set: p.tags} //@mujibur: tags, no need to be pre selected onloading, do we?
										}
							 , defaultFilters: {areas: {$set: p.areas}
											, agents: {$set: p.agents}
											, channels:{$set: p.channels}
											}
							}
						);
	},
	[constant.HANDLE_SEARCH_PROCESSING]: (state, action) =>{
		let p = action.payload;
		return update(state, {bodySearch: {isProcessing: {$set: p}}});
	},
	[constant.HANDLE_SEARCH_PAGINATION]: (state, action) =>{
		let p = action.payload;
		if(p.currentPaginateCtx === constant.SEARCH_ERRAND || p.currentPaginateCtx === constant.SEARCH_COLLABORATION)
			return update(state, {bodySearch:{errandOffset:{$set: p.offset}, currentPagination:{$set: p.currentPaginateCtx}, errandPage: {$set: p.selectedPage}}});
		else if(p.currentPaginateCtx === constant.SEARCH_AREA)
			return update(state, {bodySearch:{areaOffset:{$set: p.offset}, currentPagination:{$set: p.currentPaginateCtx}, areaPage: {$set: p.selectedPage}}});
		else if(p.currentPaginateCtx === constant.SEARCH_TAGS)
			return update(state, {bodySearch:{tagOffset:{$set: p.offset}, currentPagination:{$set: p.currentPaginateCtx}, tagPage: {$set: p.selectedPage}}});
		else if(p.currentPaginateCtx === constant.SEARCH_KB)
			return update(state, {bodySearch:{kbOffset:{$set: p.offset}, currentPagination:{$set: p.currentPaginateCtx}, knowledgebasePage: {$set: p.selectedPage}}});
		else if(p.currentPaginateCtx === constant.SEARCH_CONTACTCARD)
			return update(state, {bodySearch:{ccOffset:{$set: p.offset}, currentPagination:{$set: p.currentPaginateCtx}, contactCardPage: {$set: p.selectedPage}}});
		else if(p.currentPaginateCtx === constant.SEARCH_EECONTACT)
			return update(state, {bodySearch:{eeOffset:{$set: p.offset}, currentPagination:{$set: p.currentPaginateCtx}, eeContactPage: {$set: p.selectedPage}}});
		else if(p.currentPaginateCtx === constant.SEARCH_CUSTOMERNOTES)
			return update(state, {bodySearch:{cnotesOffset:{$set: p.offset}, currentPagination:{$set: p.currentPaginateCtx}, customerNotesPage: {$set: p.selectedPage}}});
		else if(p.currentPaginateCtx === constant.SEARCH_ERRANDNOTES)
			return update(state, {bodySearch:{enotesOffset:{$set: p.offset}, currentPagination:{$set: p.currentPaginateCtx}, errandNotesPage: {$set: p.selectedPage}}});
		else
			return state;
	},
	[constant.SET_SEARCH_PREVIEW_ID]: (state, action) => {
		let p = action.payload;
		return update(state, {previewId: {$set: p}});
	},
	[constant.HANDLE_SEARCH_RESET]: (state, action) =>{
		return update(state, { bodySearch: {
					errandOffset: {$set: 0},
					errandPage: {$set: 0}
				}
			}
		);
	},
	[done(keys.keyErrandWorkflowSettings)]: (state, action) => {
		let wfs = action.payload.data;
		return update(state, {bodySearch: {topResults: {$set: wfs.agentInboxSize}}});
	}
}

const searchReducer = createReducer(initSearch, searchReducers);

const resultRdcr = {
	[constant.WS_EVENT_GLOBAL_SEARCH]: handleGlobalSearch,
	[constant.HANDLE_SEARCH_RESET]:(state, action) =>{
		let p = action.payload;
		return update(state,{
			opr: {$set: {}}
			,errandById: {$set: {}}
			,errands: {$set: []}
			,collabErrands: {$set: []}
			,areas: {$set: []}
			,tags:{$set: []}
			,knowledgeBases: {$set: []}
			,contactCards: {$set: []}
			,eeContact: {$set: []}
			,customerNotes: {$set: []}
			,errandNotes: {$set: []}
			,totalErrand: {$set: 0}
			,totalKBs: {$set: 0}
			,totalAreas: {$set: 0}
			,totalTags: {$set: 0}
			,totalContacts: {$set: 0}
			,totalCustomerNotes: {$set: 0}
			,totalErrandNotes: {$set: 0}
			,totalMyErrand: {$set: 0}
			,totalAllErrand: {$set: 0}
			,totalClosedErrand: {$set: 0}
		});
	},
	[constant.SELECT_ERRAND_IN_SEARCH_RESULT]: (state, action) => {
		const {id, select} = action.payload;
		return updateSearchAllReducer(
			update(state, {opr: {[id]: {$set: {selected: select}}}})
			, action
		);
	},
	[constant.SELECT_ALL_ERRANDS_IN_SEARCH_RESULT]: (state, action) => {
		let opr = {};
		$.each(state.opr, (id, v) => {
			if (id != "all") {
				opr[id] = {selected: action.payload}
			}
		})
		return updateSearchAllReducer(
			update(state, {opr: {$set: opr}})
			, action
		);
	},
	[constant.SET_CURRENT_ERRAND]: (state, action) => {
		const { id } = action.payload;
		if (!state.opr[id]) {
			return state;
		}
		let opr = {};
		$.each(state.opr, (k, v) => {
			opr[k] = {selected: (k == id ? true : false)}
		})
		return update(state, {opr: {$set: opr}});
	}
}
const resultReducer = createReducer(initResults, resultRdcr);
let search = combineReducers({
	inputs: (state = searchInitials, action)=>{
		let p = action.payload;
		switch( action.type){
			case constant.GLOBAL_SEARCH_TEXT_CHANGE:
				if(p.actionFor === 'header'){
					let rsBox = (p.value.length > 1 ? true : false);
					return update(state, {headerText:{$set: p.value}, showResultBox: {$set: rsBox}});
				}else if(p.actionFor === "reply"){
					let rsBox = (p.value.length > 1 ? true : false);
					return update(state, {replyText:{$set: p.value}, showReplyResultBox: {$set: rsBox}});
				}else
					return update(state, {bodyText:{$set: p.value}});
			case constant.HEADER_SEARCH_RESULT_HIDE:
				return update(state, {showResultBox: {$set: p}});
			case constant.REPLY_SEARCH_RESULT_HIDE:
				return update(state, {showReplyResultBox: {$set: p}});
			case constant.HEADER_SEARCH_ONGOING:
				return update(state, {searchOngoing:{$set: p}});
			case constant.REPLY_SEARCH_ONGOING:
				return update(state, {replySearchOngoing:{$set: p}});
			case constant.GLOBAL_SEARCH_HEADER_DROP_DOWN:
				if(p.actionFor === 'header' && typeof p.value !== 'undefined' &&
					p.value != null && p.value.errandCount > 0){
					return update(state, {showResultBox: {$set: true}});
				}
		}
		return state;
	},
	ui: searchReducer,
	results: resultReducer
});

const checkIfAllSelected = opr => {
	let allSelected = true;
	$.each(opr, function(k, v) {
		if (k != "all" && !v.selected) {
			allSelected = false;
			return false;
		}
	});
	return allSelected;
}

const updateSearchAllReducer = (state, action) => {
	const { opr } = state;
	if (checkIfAllSelected(opr)) {
		state = update(state, {opr: {all: {$set: true}}});
	} else if (opr.all) {
		state = update(state, {opr: {all: {$set: false}}});
	}
	return state;
}

export default search;
