import { combineReducers } from 'redux'
import reduceReducers from 'reduce-reducers'
import update from 'immutability-helper'
import { commonMerger } from '../../common/helpers'
import {
  keyAdminDeleteSmtp,
  keyAdminSaveSmtp,
  keyAdminSmtp
} from '../constants/keys'
import {
  SMTP_EDIT_FIELD,
  SMTP_FINISH_EDIT,
  SMTP_START_EDIT
} from '../constants/constants'
import {
  asyncInitState,
  asyncDoneActionType,
  createReducer,
  createReducerSubBranch,
  defAsyncReducer,
  normalizeRemoverUpdater
} from '../util'
import { adminAsyncMap, adminStateName } from '../selectors/smtp'
import { createCommonEditActionReducer } from './chatbot'

const done = key => asyncDoneActionType(adminAsyncMap[key])

const [
  _adminEditActionMap,
  _adminEditReducerMap
] = createCommonEditActionReducer(
  SMTP_START_EDIT,
  SMTP_EDIT_FIELD,
  SMTP_FINISH_EDIT
)

export const adminEditActionMap = _adminEditActionMap

const _listReducers = {
  [done(keyAdminDeleteSmtp)]: (
    state,
    { payload: { data: { id: serverId }, param: { id } } }
  ) => {
    if (serverId !== id) {
      console.log('error as SMTP id not match:', serverId, id)
    }
    const updater = normalizeRemoverUpdater(serverId, state)
    if (!updater) {
      return state
    }
    return update(state, updater)
  },
  [done(keyAdminSaveSmtp)]: (
    state,
    { payload: { data: serverData, param: { id, data } } }
  ) => {
    const { id: serverId } = serverData
    if (typeof id === 'undefined') {
      // create
      return update(
        state, {
          allIds: { $push: [serverId] },
          byId: { [serverId]: { $set: serverData } }
        }
      )
    }
    if (serverId !== id) {
      console.log('id not match, not to give wrong state:', id)
      return state
    }
    // edit
    return update(
      state, {
        byId: {
          [id]: {
            $set: commonMerger(state.byId[id], serverData)
          }
        }
      }
    )
  }
}

const _editReducers = { ..._adminEditReducerMap }

export const admin = combineReducers({
  [adminStateName(keyAdminDeleteSmtp)]: defAsyncReducer(
    keyAdminDeleteSmtp,
    asyncInitState
  ),
  [adminStateName(keyAdminSmtp)]: reduceReducers(
    defAsyncReducer(keyAdminSmtp, asyncInitState),
    createReducerSubBranch(
      (state, value) => update(state, { data: { $set: value } }),
      ({ data: state }) => state,
      createReducer(null, _listReducers)
    )
  ),
  [adminStateName(keyAdminSaveSmtp)]: defAsyncReducer(
    keyAdminSaveSmtp,
    asyncInitState
  ),
  edit: createReducer(null, _editReducers)
})
