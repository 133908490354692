import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';
import { LOCATION_CHANGE } from 'connected-react-router';
import update from 'immutability-helper';
import {
	ADMIN
	, BACKSEARCH
	, EXPORTS
	, EXPORTS_CALL, MESSAGES
	, SEARCH
	, SEARCH_ANSWERS
	, STATISTICS
	, WORKFLOW
	, LIVE_REPORTS
} from '../../common/path';
import { hasPrefix } from '../../common/helpers';
//import { I } from '../../common/v5/config';
import {
	keyAgentCurrentStatus,
	keyAgentGetSelectedStatus,
	keyAgentLastSeen,
	keyAgentPing,
	keyChangeHeaderTicker,
	keyChangePickupNext,
	keyChangeAcceptChat,
	keyChatSessionsCount,
	keyErrandWorkflowSettings,
	keyGetAgentChatSourceConfig,
	keyUpdateAgentChatSourceConfig,
	keyAgentFavourite,
	keyAgentStatusList
} from '../constants/keys';
import {
	ALERT_CONFIRM_CLOSED,
	ALERT_CONFIRM_CUSTOM_BUTTON_CLICK,
	ALERT_CONFIRM_ENABLE_CUSTOM,
	ALERT_CONFIRM_AUTO_LOGOUT,
	ALERT_CONFIRM_TOGGLE,
	HEADER_ALERT_EDIT_DATA,
	PREPARE_POST_LINK,
	SHOW_HIDE_POST_PREVIEW,
	TOGGLE_HEADER_ARROW,
	TOGGLE_CHAT_WINDOW,
	TOGGLE_POP_ALERT,
	ADD_TOAST_ALERT,
	UPDATE_TOAST_LIST,
	TOGGLE_POP_WAIT,
	ERRAND_ENABLE_CONFIRM,
	ERRAND_CONFIRM_CANCEL,
	ERRAND_CONFIRM_YES,
	TOGGLE_MENU_DROPDOWN,
	ENABLE_PICK_UP_NEXT,
	TOGGLE_HOTKEYS_HELPER,
	STORE_HOTKEYS_ACTIVE,
	SETUP_HOTKEY_LIST,
	SET_HOTKEY_ACTIVE,
	ACTIVE_HOTKEY_DIALOG,
	WS_EVENT,
	SHOW_CHAT_SOURCE_CONFIG,
	TOGGLE_CHAT_SOURCE,
	UPDATE_CHAT_CONFIG_STATUS,
	ALERT_CONFIRM_TO_LOGIN
} from '../constants/constants';
import {
	AS_AVAILABLE
	, CHAT_SOURCE_ENABLED
	, CHAT_SOURCE_OFFLINE
	, CHAT_SOURCE_ONLINE
	, MM_ADMINISTRATION
	, MM_EXPORT
	, MM_EXPORT_CALL
	, MM_LIVE_REPORTS
	, MM_MESSAGE
	, MM_SEARCH
	, MM_SEARCHANSWERS
	, MM_STATISTICS
	, MM_WORKFLOW
	, POPBOX_ALERT
	, POPBOX_AUTO_LOGOUT
	, POPBOX_CONFIRM
	, POPBOX_CUSTOM
	, POPBOX_WAIT
} from '../../common/v5/constants';
import {
	createReducer,
	createReducerSubBranch,
	defAsyncReducer,
	asyncInitState,
	asyncDoneActionType
} from '../util';
import { headerAlertReducerMap } from '../actions/hmf';
import { headerMenuFooter as headerActions } from '../actions/async/hmf';
import { workflow } from '../actions/async/workflow';

function done(key) {
	return asyncDoneActionType(headerActions[key]);
}

const changeHeaderTicker = () => defAsyncReducer(keyChangeHeaderTicker,
	update(asyncInitState, {$merge: {param: features.tickerSelected}}));

const changeAcceptChat = () => defAsyncReducer(keyChangeAcceptChat,
	update(asyncInitState, {$merge: {accept: features.acceptChat}}),
	(state, action) => {
		const {data} = action.payload;
		if(data != null) {
			return update(state, {accept: {$set: data.acceptchat}});
		}
		return state;
	}
);

const chatSessionsCount = () => defAsyncReducer(keyChatSessionsCount,
	asyncInitState);

const currentStatus = () => defAsyncReducer(keyAgentCurrentStatus,
	update(asyncInitState, {data: {$set: {status: AS_AVAILABLE}}}));

const setChatSourceConfig = (state, action) => {
	let newState = $.extend({}, state);
	if (action.payload.data && action.payload.data.disabled) {
		$.each(action.payload.data.disabled, (index, serviceType) => {
			if (newState.data) {
				newState = update(newState, {data: {$merge: {[serviceType]: false}}});
			} else {
				newState = update(newState, {data: {$set: {[serviceType]: false}}});
			}
		})
	}
	return newState;
}

const loadChatSourceConfig = () => defAsyncReducer(keyGetAgentChatSourceConfig, asyncInitState, setChatSourceConfig);
const savedChatSourceConfig = () => defAsyncReducer(keyUpdateAgentChatSourceConfig, asyncInitState, setChatSourceConfig);

const agentFavouriteReducer = () => defAsyncReducer(keyAgentFavourite, asyncInitState, (state, action) => {
	let newState = $.extend({}, state);
	if (action.payload.data) {
		newState = update(newState, {
			data: {$set: action.payload.data},
			[action.payload.data.name]: {$set: action.payload.data.list}
		});
	}
	return newState;
});

const agentStatusListReducer = () => defAsyncReducer(keyAgentStatusList, asyncInitState, (state, action) => {
	let newState = $.extend({}, state);
	if (action.payload.data) {
		newState = update(newState, {data: {$set: action.payload.data}});
		var byId = { ["status"]: {
			className: "available",
			id: "available",
			label: AS_AVAILABLE
		}}
		var byName = {}
		$.each(action.payload.data, (i, v) => {
			byId[v.id] = v;
			byName[v.label] = v;
		})
		newState = update(newState, {
			byId: {$set: byId},
			byName: {$set: byName}
		});
	}
	return newState;
});

const selectedStatus = () => defAsyncReducer(keyAgentGetSelectedStatus,
	asyncInitState);

const agentPing = () => defAsyncReducer(keyAgentPing, asyncInitState);

const agentLastSeen = () => defAsyncReducer(keyAgentLastSeen, asyncInitState);

const pathnamePrefixToMainMenu = [
	{prefix: ADMIN, menu: MM_ADMINISTRATION}
	, {prefix: BACKSEARCH, menu: MM_SEARCH}
	, {prefix: EXPORTS, menu: MM_EXPORT}
	, {prefix: EXPORTS_CALL, menu: MM_EXPORT_CALL}
	, {prefix: MESSAGES, menu: MM_MESSAGE}
	, {prefix: SEARCH_ANSWERS, menu: MM_SEARCHANSWERS}
	, {prefix: SEARCH, menu: MM_SEARCH}
	, {prefix: STATISTICS, menu: MM_STATISTICS}
	, {prefix: WORKFLOW, menu: MM_WORKFLOW}
	, {prefix: LIVE_REPORTS, menu: MM_LIVE_REPORTS}
];

const getMainMenuFromPath = pathname => {
	let mainMenu = MM_WORKFLOW;
	$.each(pathnamePrefixToMainMenu, (i, { prefix, menu }) => {
		if (hasPrefix(pathname, prefix)) {
			mainMenu = menu;
			return false;
		}
	});
	return mainMenu;
};

const mainMenuInitialState = {
	activeMenuToggle: false
	, activeMainMenu: getMainMenuFromPath(window.location.pathname)
};

export const menu = combineReducers({
	mainMenu: (state = mainMenuInitialState, { type, payload }) => {
		switch (type) {
			case TOGGLE_MENU_DROPDOWN:
				return update(state, {activeMenuToggle: {$set: !payload}});
			case LOCATION_CHANGE:
				const { pathname } = payload.location;
				return update(state, {activeMainMenu: {$set: getMainMenuFromPath(pathname)}});
			default:
				return state;
		}
	}
});

const alertConfirmDefaultState = {
	button: null
	, buttons: null
	, show: false
	, type: POPBOX_ALERT
	, text: ''
	, opr: ''
	, data: null
	, cancel: false
	, withCopy: false
	, copyAbleTxt: ''
};

const uiHeaderInitState = {
	openHeaderArrow: null,
	alert: alertConfirmDefaultState,
	toastList: [],
	pickUpNext: false,
	hotkeyId: "",
	hotkeyActive: false,
	hotkeysParam: [], //current key set that displayed in helper
	hotkeysHelper: false,
	hotkeys: [], //all available keys on the current page (example "0","1","2"...,"S")
	hotkeyDialog: false,
	showChatSourceConfig: false,
	postPreviewIsOpened: false,
	chatSourceStatus: CHAT_SOURCE_ENABLED,
	totalChannel: 0,
	totalChannelActive: 0
};

const uiFooterInitState = {
	activateChatWindow: false
};

const alertConfirmEnableSubReducer = (state, action, type) => {
	const { opr, text, buttons, data } = action.payload;
	return update(state, {alert: {$set: {
			show: true
			, type
			, text
			, opr
			, data
			, buttons
		}}});
};

function selectedStatusSyncToCurrentStatusReducer(state, action) {
	if (action.type !== done(keyAgentGetSelectedStatus)) {
		return state;
	}
	const { status } = action.payload.data;
	if(state.data == null) {
		return state;
	}
	return update(state, {data: {$merge: {status}}});
}

function showAlertSubReducer(state, action, type) {
	const { text, custom } = action.payload;
	let withCopy = false, copyAbleTxt = "";
	if(custom && custom.withCopy) {
		withCopy = true;
		copyAbleTxt = custom.copyAbleTxt;
	}
	return update(state, {alert: {$merge: {
			show: true
			, text
			, type
			, withCopy
			, copyAbleTxt
		}}});
}

function updateBoolSubReducer(state, action, field) {
	return update(state, {[field]: {$set: action.payload}});
}

function createBoolUpdateReducer(field) {
	return (state, action) => updateBoolSubReducer(state, action, field);
}

// all bool only state can use this reducer where the action.payload carry only
// the bool state to be updated.
const boolStateReducers = {
	[ENABLE_PICK_UP_NEXT]: createBoolUpdateReducer("pickUpNext")
	, [SHOW_HIDE_POST_PREVIEW]: createBoolUpdateReducer("postPreviewIsOpened")
	, [TOGGLE_HOTKEYS_HELPER]: createBoolUpdateReducer("hotkeysHelper")
	, [STORE_HOTKEYS_ACTIVE]: createBoolUpdateReducer("hotkeysParam")
	, [SETUP_HOTKEY_LIST]: createBoolUpdateReducer("hotkeys")
	, [SET_HOTKEY_ACTIVE]: createBoolUpdateReducer("hotkeyActive")
	, [ACTIVE_HOTKEY_DIALOG]: createBoolUpdateReducer("hotkeyDialog")
	, [SHOW_CHAT_SOURCE_CONFIG]: createBoolUpdateReducer("showChatSourceConfig")
};

let header = combineReducers({
	postSource: (state = null, action) => {
		const { payload, type } = action;
		if (type !== PREPARE_POST_LINK) {
			return state;
		} else if (!payload) {
			return null;
		}
		const { data, format } = payload;
		if (!format) {
			return null;
		}
		return payload;
	},
	tckSelected: changeHeaderTicker(),
	acceptChat: changeAcceptChat(),
	chatSessionsCount: chatSessionsCount(),
	currentStatus: reduceReducers(
		currentStatus()
		, selectedStatusSyncToCurrentStatusReducer
	),
	chatSource: reduceReducers(
		loadChatSourceConfig()
		, savedChatSourceConfig()
		, (state, action) => {
			if (action.type == TOGGLE_CHAT_SOURCE) {
				if(state.data){
					return update(state, {data: {$merge: {[action.payload.serviceType]: action.payload.enable}}})
				}else{
					return update(state, {data: {$set: {[action.payload.serviceType]: action.payload.enable}}})
				}
			}
			return state;
		}
	),
	agentFavourite: agentFavouriteReducer(),
	agentStatus: agentStatusListReducer(),
	uiData: reduceReducers(
		(st = uiHeaderInitState, act) => {
			switch(act.type) {
			case ALERT_CONFIRM_CLOSED:
				return update(st, {alert: {$set: alertConfirmDefaultState}});
			case ALERT_CONFIRM_CUSTOM_BUTTON_CLICK: {
				const {button, cancel} = act.payload;
				return update(st, {alert: {
					button: {$set: button},
					show: {$set: false},
					cancel: {$set: cancel}
				}});
			}
			case ALERT_CONFIRM_AUTO_LOGOUT:
				return alertConfirmEnableSubReducer(st, act, POPBOX_AUTO_LOGOUT);
			case ALERT_CONFIRM_TO_LOGIN:
				return showAlertSubReducer(st, act, POPBOX_ALERT);
			case ALERT_CONFIRM_ENABLE_CUSTOM:
				return alertConfirmEnableSubReducer(st, act, POPBOX_CUSTOM);
			case ALERT_CONFIRM_TOGGLE:
				return update(st, {alert: {show: {$set: act.payload}}});
			case TOGGLE_HEADER_ARROW:
				return update(st, {$merge: {openHeaderArrow: act.payload}});
			case TOGGLE_POP_ALERT:
				if(typeof act.payload === 'undefined') {
					return update(st, {alert: {show: {$set: !st.alert.show}}});
				} else {
					return showAlertSubReducer(st, act, POPBOX_ALERT);
				}
			case UPDATE_TOAST_LIST:
				return update(st, {toastList: {$set: act.payload}});
			case ADD_TOAST_ALERT:
				const obj = act.payload
				obj.id = st.toastList.length
				return update(st, {toastList : {$push: [obj]}});
			case TOGGLE_POP_WAIT:
				if(typeof act.payload === 'undefined') {
					return update(st, {alert: {show: {$set: false}}});
				} else {
					return showAlertSubReducer(st, act, POPBOX_WAIT);
				}
			case ERRAND_ENABLE_CONFIRM:
				return alertConfirmEnableSubReducer(st, act, POPBOX_CONFIRM);
			case ERRAND_CONFIRM_CANCEL:
				return update(st, {alert: {
					show: {$set: false},
					cancel: {$set: true}
				}});
			case ERRAND_CONFIRM_YES:
				return update(st, {alert: {show: {$set: false}}});
			case asyncDoneActionType(workflow[keyErrandWorkflowSettings]):
				if (act.payload.data) {
					const pickUpNext = act.payload.data["pickUpNext"];
					return update(st, {pickUpNext: {$set: pickUpNext}})
				} else {
					return st;
				}
			case UPDATE_CHAT_CONFIG_STATUS: {
				const p = act.payload;
				let status = CHAT_SOURCE_ENABLED;
				if(p.totalActiveChannel > 0){
					if(p.totalChannel === p.totalActiveChannel){
						status = CHAT_SOURCE_ONLINE;
					}
				}else{
					status = CHAT_SOURCE_OFFLINE;
				}
				return update(st, {
					chatSourceStatus: {$set: status},
					totalChannel: {$set: p.totalChannel},
					totalChannelActive: {$set: p.totalActiveChannel},
					showChatSourceConfig: {$set: false}
				});
			}
			case WS_EVENT:
				switch (act.packet.type) {
				case "WS_CONNECTED":
					return update(st, {wsConnected: {$set: true}});
				case "WS_DISCONNECTED":
					return update(st, {wsConnected: {$set: false}});
				default:
					return st;
				}
			default:
				return st;
			}
		}
		, createReducer(uiHeaderInitState, boolStateReducers)
		, createReducerSubBranch(
			(state, value) => update(state, { alert: { $set: value } })
			, ({ alert }) => alert
			, createReducer(alertConfirmDefaultState, headerAlertReducerMap)
		)
	)
});

export const footer = combineReducers({
	agentPing: agentPing(),
	agentLastSeen: agentLastSeen(),
	uiData: (st = uiFooterInitState, act) => {
		if(act.type == TOGGLE_CHAT_WINDOW) {
			return update(st, {activateChatWindow: {$set: !st.activateChatWindow}})
		}
		return st;
	}
});

header = reduceReducers(header);

export { header };
