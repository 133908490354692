import update from 'immutability-helper';
import {
    asyncDoneActionType
} from '../util';
import { admin as AdminsMap } from '../selectors/admin';
import {
    keyGetOrganisations,
    keyGetOrganisationById
} from '../constants/keys';

const done = key => asyncDoneActionType(AdminsMap[key]);

const initOrganisations = {
    activeTab: 0,
    orgList: {
        list: []
    },
    org: {
        data: {
            feature: {},
            org: {},
            user: {}
        },
    }
};

const organisationReducer = (st = initOrganisations, act) => {
    if (act.type === done(keyGetOrganisations)) {
        return update(st, {
            orgList: { list: { $set: act.payload.data } }
        });
    }
    if (act.type === done(keyGetOrganisationById)) {
        return update(st, {
            org: { data: { $set: act.payload.data } }
        });
    }
    return st;
}

export { organisationReducer };
