import update from 'immutability-helper';
import {
	keyGetAgentListInChat,
} from '../constants/keys';
import {
	asyncDoneActionType,
} from '../util';

import {
	evtCHAT_AGENT_PRESENCE,
	CHAT_SET_CANDIDATES,
} from '../constants/constants';

import { errand as errandActions } from '../actions/async/errand';

const done = key => asyncDoneActionType(errandActions[key]);

export const agentStore = (state = {}, action) => {
	if (action.type == "WS_EVENT") {
		if (action.packet.event == evtCHAT_AGENT_PRESENCE) {
			let ae = action.packet.args[0];
			let merge = {[ae.id]: {...ae}};
			return update(state, { $merge: merge });
		}
		return state;
	}

	if (action.type == CHAT_SET_CANDIDATES) {
		let merge = {};
		$.each(action.response.list, (i, area) => {
			$.each(area.agents, (j, agent) => {
				merge[agent.id] = agent;
			});
		});
		return update(state, { $merge: merge });
	}
	if (action.type == done(keyGetAgentListInChat)) {
		let merge = {};
		$.each(action.payload.data.list, (i, agent) => {
			merge[agent.id] = agent;
		});
		return update(state, { $merge: merge });
	}
	return state;
}
