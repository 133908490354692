import { combineReducers } from "redux";
import shortid from 'shortid';
import each from 'lodash/each';
import update from 'immutability-helper';
import reduceReducers from 'reduce-reducers';
import { emptyObject } from '../../common/constants';
import {
	breakpointKeys,
	fillupMissingBreakpointKeys
} from '../../common/theme';
import { I, L } from '../../common/v5/config';
import { isChartGrid } from '../../common/v5/helpers';
import {
	PC_NEW_CHART_LAYOUT
	, PC_PIN_NEW_POSITION
} from '../../common/v5/statisticConstants';
import {
	keyAdminLeaderboard,
	keyAdminOneReports,
	keyAdminPinReports,
	keyAdminReportDrilldown,
	keyAdminReports,
	keyAdminReportsExport,
	keyGetReportsOverviewLayout,
	keyGroups,
	keySaveReportsOverviewLayout,
	keyTimeTimespan,
	keyTimeformat,
	keyTimeTimezones,
	keyCreateScheduleReport,
	keyShowScheduledReports,
	keyShareReportDone,
	keyGetSavedReport,
	keyAdminCustomReports,
	keyGetReportKeys,
	keyGetReportGroups,
	keyReportPreview,
	keyCustomTimeSpan,
	keyTimespanFormats,
	keyCollaboratorList
} from '../constants/keys';
import {
	actionReducerCreator
	, asyncDoneActionType
	, asyncFailActionType
	, asyncInitState
	, asyncRequestActionType
	, createReducer
	, defAsyncReducer
	, getStateName
	, reduxCreators
	, NEVER_EXPIRED_CACHE
} from "../util";
import {
	CHANGE_OVERVIEW_GRID,
	CLEAR_CHART_DATA,
	GET_CHART_IMAGE,
	LOAD_STATISTICS_VIEW,
	REMOVE_OVERVIEW_CHART_CONTENT,
	SHOW_DRILLDOWN_DATA_TABLE,
	STATISTICS_READY,
	STORE_CLEAR_DRILLDOWN_PARAMETERS,
	UPDATE_CHART_LAYOUT,
	UPDATE_CHART_PARAMETER,
	UPDATE_CHART_PARAMETERS,
	UPDATE_OVERVIEW_CHART_MAP,
	UPDATE_OVERVIEW_GRID,
	UPDATE_REPORT_PARAM_ID,
	UPDATE_REPORT_COMPARE_PARAM_ID,
	SET_SCHEDULE_TOGGLE,
	UPDATE_SCHEDULE_REPORT,
	RESET_SCHEDULE_REPORT,
	SET_SHARE_TOGGLE,
	UPDATE_SHARE_PARAM,
	OPEN_SCHEDULE_REPORT,
	RESET_SHARE_REPORT,
	SET_SHARE_REPORT,
	OPEN_CREATE_REPORT,
	TOGGLE_CREATE_REPORT,
	UPDATE_CREATE_REPORT,
	RESET_CREATE_REPORT,
	TOGGLE_REPORT_PREVIEW,
	OPEN_CONFIG_TIME,
	TOGGLE_CONFIG_TIME,
	RESET_CONFIG_TIME,
	UPDATE_CONFIG_TIME,
	REORDER_CONFIG_TIME,
	REMOVE_SELECTION_CONFIG_TIME,
	REORDER_CREATE_REPORT_KEY,
	REMOVE_SELECTED_REPORT_KEY,
	REORDER_CREATE_REPORT_GROUP,
	REMOVE_SELECTED_REPORT_GROUP,
	CLEAR_SELECTION_CONFIG_TIME
} from '../constants/constants';
import {
	AT_ALL_ACTIVE,
	CL_OVERVIEW,
	CL_REPORT,
	CS_EXIST,
	CS_PIN,
	CS_REPORT,
	CS_SPECIAL,
	CT_GENERAL_TOTAL,
	CT_LEADERBOARD,
	CT_MULTI_TOTAL,
	DT_RELATIVE,
	EXECUTIVE_REPORT,
	LT_CLOSED,
	LT_HANDLING_TIME,
	OC_ACTIVE_AGENTS,
	OC_LEADERBOARD,
	OC_LIMITS,
	OC_ORGANISATION,
	OL_LEFT_PANE,
	OL_MID_PANE,
	OL_RIGHT_PANE,
	RDT_EVERY_DAY,
	ET_EXCEL,
	// RDT_SAME_WEEK,
	RL_LEFT_PANE,
	RL_RIGHT_PANE,
	RL_BOTTOM_PANE,
	RL_COMPARE_PANE,
	CT_GENERAL_BAR,
	CT_GENERAL_TABLE,
	SR_ORG_OVERVIEW,
	SR_ACTIVE_AGENT,
	SR_AVG_HANDLE_TIME,
	SR_CAP_UTIL_CHAT,
	SR_CHAT_SESSIONS,
	SR_CLOSED,
	SR_DEL_ERRANDS,
	SR_INCOMING,
	SR_LEADERBOARD,
	SR_RESP_TIME,
	SR_SATISFY_METER,
	STATISTICS_TITLE,
	VIEW_REPORT,
	SR_FACEBOOK_RATING,
	SR_NUMBER_OF_CONTACTS,
	SR_CLOSED_AND_ANSWERED,
	SR_ANSWERED_AND_AVG_HANDLING_TIME_BY_AGENT_GROUP,
	SR_ANSWERED_AND_AVG_HANDLING_TIME_BY_ORGANISATION,
	SR_CLOSED_VOICE_ERRAND_AND_AVG_HANDLING_TIME_BY_AGENT_GROUP,
	SR_CLOSED_VOICE_ERRAND_AND_AVG_HANDLING_TIME_BY_ORGANISATION,
	CT_CONTACTSTOPLIST,
	SR_COLLABORATION
} from '../../common/v5/constants';

const [
	_statisticChartsActionMap
	, statisticChartsReducerMap
] = actionReducerCreator([
	[UPDATE_OVERVIEW_CHART_MAP, chartMap => ({chartMap: {[CL_OVERVIEW]: chartMap}})]
	, [UPDATE_OVERVIEW_GRID, grid => ({grid: {[CL_OVERVIEW]: grid}})]
	, [REMOVE_OVERVIEW_CHART_CONTENT, shortid => ({byShortid: {[CL_OVERVIEW]: {$unset: [shortid]}}})]
]);

export const statisticChartsActionMap = _statisticChartsActionMap;

export const statisticsMap = reduxCreators([
	[keyAdminLeaderboard, 'leaderboard'],
	[keyAdminOneReports, 'onereport'],
	[keyAdminPinReports, 'pinreports'],
	[keyAdminReportDrilldown, 'reportDrilldown'],
	[keyAdminReports, 'reports'],
	[keyAdminReportsExport, 'reportsExport'],
	[keyGetReportsOverviewLayout, 'overviewLayout'],
	[keyGroups, 'groups'],
	[keySaveReportsOverviewLayout, 'saveOverviewLayout'],
	[keyTimeTimespan, 'timespan'],
	[keyTimeformat, 'timeformat'],
	[keyTimeTimezones, 'timezones'],
	[keyCreateScheduleReport, 'createScheduleReport'],
	[keyShowScheduledReports, 'showScheduledReports'],
	[keyShareReportDone, 'shareReportDone'],
	[keyGetSavedReport, 'getSavedReport'],
	[keyAdminCustomReports, 'customReports'],
	[keyGetReportKeys, 'reportKeys'],
	[keyGetReportGroups, 'reportGroups'],
	[keyReportPreview, 'reportPreview'],
	[keyCustomTimeSpan, 'customTimeSpan'],
	[keyTimespanFormats, 'timespanFormats'],
	[keyCollaboratorList, 'collaborators']
]);

export const stateName = key => getStateName(statisticsMap[key]);

export function chartIdentifier(layout, position, idx, index) {
	if (isChartGrid(layout)) {
		if (typeof idx !== "string") {
			throw "grid layout must carry idx to work";
		}
		return {layout, idx};
	}
	return layout + ":" + position + ":" + index;
}

const done = key => asyncDoneActionType(statisticsMap[key]);

const fail = key => asyncFailActionType(statisticsMap[key]);

const request = key => asyncRequestActionType(statisticsMap[key]);

const leaderboardSubReducer = (state, action, boardType) => {
	const { data, param } = action.payload;
	if (param.leaderBoardType === boardType) {
		return update(state, {data: {$set: data}});
	}
	return state;
};

const collaborators = () => defAsyncReducer(keyCollaboratorList, asyncInitState);

const closedLeaderboard = () => defAsyncReducer(keyAdminLeaderboard,
	asyncInitState,
	(state, action) => leaderboardSubReducer(state, action, LT_CLOSED));

const handlingTimeLeaderboard = () => defAsyncReducer(keyAdminLeaderboard,
	asyncInitState,
	(state, action) => leaderboardSubReducer(state, action, LT_HANDLING_TIME));

function addI18nName(data) {
	return update(data, {$merge: {i18nName: L(data.Name)}});
}

const reportDrilldown = () => defAsyncReducer(
	keyAdminReportDrilldown
	, asyncInitState
);

const reports = () => defAsyncReducer(
	keyAdminReports,
	asyncInitState,
	(state, action) => {
		// normalized it by name
		const { data } = action.payload;
		let systemReportsByName = {}, byId = {}, newData = [];
		$.each(data, (i, v) => {
			v = addI18nName(v);
			newData.push(v);
			if (v.SystemReport) {
				// only system report's name useful. Custom report name is not
				// use-able and should not be used to determine anything because
				// customers are allowed to use any name even same as system
				// report's name.
				systemReportsByName[v.Name] = v;
			}
			byId[v.Id] = v;
		});
		newData.sort((a, b) => {
			if (a.i18nName < b.i18nName) {
				return -1;
			}
			if (a.i18nName > b.i18nName) {
				return 1;
			}
			return 0;
		});
		return update(state, {$merge: {
			systemReportsByName
			, byId
			, data: newData
		}});
	}
);

const pinReports = () => defAsyncReducer(
	keyAdminPinReports,
	asyncInitState,
	(state, action) => {
		// normalized it by id
		const { data } = action.payload;
		let byId = {}, newData = {pinnedReports: []};
		$.each(data.pinnedReports, (i, v) => {
			v = addI18nName(v);
			newData.pinnedReports.push(v);
			byId[v.Id] = v;
		});
		return update(state, {$merge: {byId, data: newData}});
	}
);

const groups = () => defAsyncReducer(keyGroups, asyncInitState);

const timespan = () => defAsyncReducer(keyTimeTimespan, asyncInitState);

const timeformat = () => defAsyncReducer(keyTimeformat, asyncInitState);

const timezones = () => defAsyncReducer(keyTimeTimezones, asyncInitState);

const customReports = () => defAsyncReducer(keyAdminCustomReports, asyncInitState);

const reportKeys = () => defAsyncReducer(keyGetReportKeys, asyncInitState);

const reportGroups = () => defAsyncReducer(keyGetReportGroups, asyncInitState);

const reportPreview = () => defAsyncReducer(keyReportPreview, asyncInitState);

const timespanFormats = () => defAsyncReducer(keyTimespanFormats, asyncInitState);

const reportsControlByCflagAnswered = {
	[SR_CLOSED_AND_ANSWERED]: true
	, [SR_ANSWERED_AND_AVG_HANDLING_TIME_BY_AGENT_GROUP]: true
	, [SR_ANSWERED_AND_AVG_HANDLING_TIME_BY_ORGANISATION]: true
	, [SR_CLOSED_VOICE_ERRAND_AND_AVG_HANDLING_TIME_BY_AGENT_GROUP]: true
	, [SR_CLOSED_VOICE_ERRAND_AND_AVG_HANDLING_TIME_BY_ORGANISATION]: true
};

const chatOnlySystemReport = {
	[SR_CAP_UTIL_CHAT]: true
	, [SR_CHAT_SESSIONS]: true
};

const createChartValidatorByName = (cflag, chatEnabled) => name => {
	if (chatOnlySystemReport[name]) {
		return chatEnabled;
	} else if (reportsControlByCflagAnswered[name]) {
		return true;
	}
	return true;
};

const generateOverviewLeftPanelCharts = cflag => {
	const defaultList = [
		{
			id: SR_ACTIVE_AGENT
			, type: CS_REPORT
			, chart: CT_MULTI_TOTAL
			, param: {
				agentType: AT_ALL_ACTIVE
				// , dateType: DT_RELATIVE
				// , relativeDateType: RDT_SAME_DAY
			}
		}
		, {
			id: {
				layout: CL_OVERVIEW
				, position: OL_RIGHT_PANE
				, index: 0
			}
			, type: CS_EXIST
			// OC_ORGANISATION will display total open errands if
			// requested as CT_GENERAL_TOTAL chart.
			, chart: CT_GENERAL_TOTAL
		}
		, {
			id: SR_CLOSED
			, type: CS_REPORT
		}
		, {
			id: SR_AVG_HANDLE_TIME
			, type: CS_REPORT
		}
		, {
			id: SR_CHAT_SESSIONS
			, type: CS_REPORT
			, chart: CT_GENERAL_TOTAL
		}
		, {
			id: SR_SATISFY_METER
			, type: CS_REPORT
		}
		, {
			id: SR_FACEBOOK_RATING
			, type: CS_REPORT
		}
		, {
			id: SR_COLLABORATION
			, type: CS_REPORT
			, chart: CT_GENERAL_TOTAL
		}
	];
	defaultList.push(
		{
			id: SR_CLOSED_AND_ANSWERED
			, type: CS_REPORT
		}
		, {
			id: SR_ANSWERED_AND_AVG_HANDLING_TIME_BY_AGENT_GROUP
			, type: CS_REPORT
		}
		, {
			id: SR_ANSWERED_AND_AVG_HANDLING_TIME_BY_ORGANISATION
			, type: CS_REPORT
		}
		, {
			id: SR_CLOSED_VOICE_ERRAND_AND_AVG_HANDLING_TIME_BY_AGENT_GROUP
			, type: CS_REPORT
		}
		, {
			id: SR_CLOSED_VOICE_ERRAND_AND_AVG_HANDLING_TIME_BY_ORGANISATION
			, type: CS_REPORT
		}
	);
	return defaultList;
};

const generateOverviewMiddlePanelCharts = cflag => {
	const defaultList = [
		{
			id: SR_LEADERBOARD
			, type: CS_REPORT
			, chart: CT_LEADERBOARD
			, param: {
				agentType: AT_ALL_ACTIVE
				// , dateType: DT_RELATIVE
				// , relativeDateType: RDT_SAME_DAY
			}
		}
		// {
		// 	id: OC_LEADERBOARD
		// 	, type: CS_SPECIAL
		// 	, param: {relativeDateType: RDT_SAME_DAY}
		// }
	];
	if (true) {
		defaultList.push({
			id: SR_NUMBER_OF_CONTACTS
			, type: CS_REPORT
			, chart: CT_CONTACTSTOPLIST
		});
	}
	return defaultList;
};

function createChartAppender(maxWidth, w, h, startX, startY, layout, charts) {
	let currentX = startX
		, currentY = startY
		;
	if (typeof layout === "undefined") {
		layout = [];
	}
	if (typeof charts === "undefined") {
		charts = [];
	}
	const obj = {layout, charts};
	obj.pushLayoutBase = function() {
		this.layout.push({ x: currentX, y: currentY, w, h });
		currentX += w;
		if ((currentX - startX) >= maxWidth) {
			currentX = startX;
			currentY += h;
		}
	};
	obj.pushLayout = function(count) {
		for (let i=0; i<count; i++) {
			this.pushLayoutBase();
		}
	};
	obj.push = function(...arg) {
		this.charts.push(...arg);
		this.pushLayout(arg.length);
	};
	obj.createAppender = function(
		nextMaxWidth
		, nextW
		, nextH
		, nextStartX
		, nextStartY
	) {
		if (typeof nextMaxWidth === "undefined") {
			nextMaxWidth = maxWidth;
		}
		if (typeof nextW === "undefined") {
			nextW = w;
		}
		if (typeof nextH === "undefined") {
			nextH = h;
		}
		if (typeof nextStartX === "undefined") {
			nextStartX = currentX;
		}
		if (typeof nextStartY === "undefined") {
			nextStartY = currentY;
		}
		return createChartAppender(
			nextMaxWidth
			, nextW
			, nextH
			, nextStartX
			, nextStartY
			, this.layout
			, this.charts
		);
	};
	return obj;
}

const createUpdateShortid = (_charts, createValidator) => {
	let chartMap = {};
	let rawChartMap = {};
	let obj = {};
	let indexByShortid;
	let validator;
	const charts = [];
	const shortidByIndex = index => {
		let id = obj[index]
		if (!id) {
			let chart = _charts[index];
			if (chart.type === CS_EXIST) {
				chart = update(
					chart
					, { id: { idx: { $set: shortidByIndex(chart.id.index) } } }
				);
			}
			id = shortid.generate();
			chartMap[id] = chart;
			obj[index] = id;
		}
		return id;
	};
	const updateLayout = breakpointLayout => {
		if (indexByShortid) {
			return updateLayout.updateLayoutShortid(breakpointLayout);
		}
		indexByShortid = {};
		const layout = [];
		const unsetChart = [];
		const unsetIndex = [];
		const _chartMap = {};
		each(updateLayout.updateLayoutBase(breakpointLayout), (v, index) => {
			const { i } = v;
			const chart = chartMap[i];
			if (validator(chart, i)) {
				indexByShortid[i] = layout.length;
				rawChartMap[i] = _charts[index];
				layout.push(v);
			} else {
				unsetChart.push(i);
				unsetIndex.push(index);
			}
		});
		obj = update(obj, {$unset: unsetIndex});
		each(update(chartMap, {$unset: unsetChart}), (v, k) => {
			if (v.type === CS_EXIST) {
				const { idx, index } = v.id;
				const _index = indexByShortid[idx];
				if (index !== _index) {
					const updater = { id: { index: { $set: _index } } };
					v = update(v, updater);
					rawChartMap = update(rawChartMap, { [k]: updater });
				}
			}
			_chartMap[k] = v;
		});
		chartMap = _chartMap;
		each(layout, ({ i }) => {
			charts.push(rawChartMap[i]);
		});
		return layout;
	};
	updateLayout.chartMap = () => chartMap;
	updateLayout.charts = () => charts;
	updateLayout.updateLayoutShortid = breakpointLayout => {
		const layout = [];
		each(breakpointLayout, (v, index) => {
			const shortid = obj[index];
			if (shortid) {
				layout.push(update(v, { i: { $set: shortid } }));
			}
		});
		return layout;
	};
	updateLayout.updateLayoutBase = breakpointLayout => {
		const layout = [];
		each(breakpointLayout, (v, index) => {
			layout.push(update(v, { i: { $set: shortidByIndex(index) } }));
		});
		validator = createValidator(chartMap);
		return layout;
	};
	return updateLayout;
};

const generateGridShortid = (charts, _layouts, createValidator) => {
	const layouts = {};
	const updateShortid = createUpdateShortid(charts, createValidator);
	each(_layouts, (v, k) => {
		layouts[k] = updateShortid(v);
	});
	return [layouts, updateShortid.charts(), updateShortid.chartMap()];
};

const initLayoutData = {
	[CL_OVERVIEW]: [
		generateOverviewLeftPanelCharts(cflag)
		, generateOverviewMiddlePanelCharts(cflag)
		, [{
			id: SR_ORG_OVERVIEW
			, type: CS_REPORT
			// , param: {
			// 	dateType: DT_RELATIVE
			// 	, relativeDateType: RDT_SAME_DAY
			// }
		}] // [{id: OC_ORGANISATION, type: CS_SPECIAL}]
	]
	, [CL_REPORT]: [
		[{
			id: {
				layout: CL_REPORT
				, position: RL_RIGHT_PANE
				, index: 0
			}
			, type: CS_EXIST
			, chart: CT_GENERAL_BAR
		}]
		, [{id: SR_RESP_TIME, type: CS_REPORT}]
		, [{
			id: {
				layout: CL_REPORT
				, position: RL_RIGHT_PANE
				, index: 0
			}
			, type: CS_EXIST
			, chart: CT_GENERAL_TABLE
		}]
		, [{id: SR_RESP_TIME, type: CS_REPORT}]
		, [{
			id: {
				layout: CL_REPORT
				, position: RL_COMPARE_PANE
				, index: 0
			}
			, type: CS_EXIST
			, chart: CT_GENERAL_BAR
		}]
	]
};

const generateResponsiveLayouts = grid => {
	const _layouts = {};
	each(breakpointKeys, v => {
		_layouts[v] = grid;
	});
	return _layouts;
};

function overviewGridAndChartMap() {
	let layout = createChartAppender(4, 2, 8, 0, 0);
	const [left, middle, right] = initLayoutData[CL_OVERVIEW];
	layout.pushLayout(left.length);
	layout = layout.createAppender(4, 4, 5, 4, 0);
	layout.pushLayout(middle.length);
	layout = layout.createAppender(4, 4, 5, 8, 0);
	layout.pushLayout(right.length);
	const _charts = [];
	const offsets = {
		[OL_LEFT_PANE]: 0
		, [OL_MID_PANE]: left.length
		, [OL_RIGHT_PANE]: left.length + middle.length
	};
	each(left.concat(middle).concat(right), v => {
		if (v.type === CS_EXIST) {
			const { index, position } = v.id;
			const _index = offsets[position] + index;
			if (index !== _index) {
				v = update(v, { id: { index: { $set: _index } } });
			}
		}
		_charts.push(v);
	});
	const [
		layouts
		, charts
		, chartMap
	] = generateGridShortid(
		_charts,
		generateResponsiveLayouts(layout.layout),
		() => () => true
	);
	return [chartMap, { charts, layout: layouts.lg, layouts }];
}

const [overviewChartMap, overviewGrid] = overviewGridAndChartMap();

const reportGridLayout = [
	{x: 0, y: 0, w: 10, h: 14}
	, {x: 10, y: 0, w: 2, h: 14}
	, {x: 0, y: 14, w: 12, h: 12}
];

function reportGridAndChartMap() {
	const _charts = [];
	const [left, right, bottom] = initLayoutData[CL_REPORT];
	const offsets = {
		[RL_LEFT_PANE]: 0
		, [RL_RIGHT_PANE]: left.length
		, [RL_BOTTOM_PANE]: left.length + right.length
	};
	each(left.concat(right).concat(bottom), v => {
		if (v.type === CS_EXIST) {
			const { index, position } = v.id;
			const _index = offsets[position] + index;
			if (index !== _index) {
				v = update(v, { id: { index: { $set: _index } } });
			}
		}
		_charts.push(v);
	});
	const [
		layouts
		, charts
		, chartMap
	] = generateGridShortid(
		_charts,
		generateResponsiveLayouts(reportGridLayout),
		() => () => true
	);
	return [chartMap, { charts, layout: layouts.lg, layouts }];
}

const [reportChartMap, reportGrid] = reportGridAndChartMap(cflag);

export const initGridData = {
	[CL_OVERVIEW]: overviewGrid
	, [CL_REPORT]: reportGrid
};

const initByShortid = (initGridData => {
	const result = {}
	each(initGridData, (_, k) => {
		result[k] = {};
	});
	return result;
})(initGridData);

const normalizeGrid = chartMap => {
	const result = {};
	each(chartMap, ({ id, chart, type }, idx) => {
		if (type === CS_REPORT && !result[id]) {
			result[id] = { idx, chart };
		}
	});
	return result;
};

const normalizedOnePaneExecutiveSystemReport = (charts, position, result) => {
	each(charts, ({ id, chart, type }, index) => {
		if (process.env.NODE_ENV !== 'production') {
			console.log(result[id]);
		}
		if (type === CS_REPORT && !result[id]) {
			result[id] = { chart, index, position };
		}
	});
};

const normalizeLayout = layout => [
	OL_LEFT_PANE
	, OL_MID_PANE
	, OL_RIGHT_PANE
].reduce((result, value) => {
	normalizedOnePaneExecutiveSystemReport(
		layout[value]
		, value
		, result
	);
	return result;
}, {});

export const normalizeOverviewLayout = (useNewLayout, data) => {
	let normalizer;
	if (useNewLayout) {
		normalizer = normalizeGrid;
	} else {
		normalizer = normalizeLayout;
	}
	return normalizer(data);
};

const initChartMap = {
	[CL_OVERVIEW]: overviewChartMap
	, [CL_REPORT]: reportChartMap
};

export const normalizedExecutiveSystemReportLayout = ((
	useNewLayout
	, gridData
	, oldLayoutData
) => {
	let data;
	if (useNewLayout) {
		data = gridData;
	} else {
		data = oldLayoutData;
	}
	return normalizeOverviewLayout(useNewLayout, data);
})(PC_NEW_CHART_LAYOUT, initChartMap[CL_OVERVIEW], initLayoutData[CL_OVERVIEW]);

const initCharts = {
	// id is generated by position:index where position is index value of layout
	// while index is the index of the layout[position].
	byId: {}
	, byShortid: initByShortid
	, chartMap: initChartMap
	, grid: initGridData
	// layout is array of array object where outer array is the panels position
	// on the page meanwhile inner array is the index within this position. The
	// content consist of mandatory 'id' and 'type' and optional 'param' or
	// 'chart'. Details:
	//    id: Identifier for the 'type' which its js-type can differ depend on
	//        'type' property.
	//  type: Report type which determine the which endpoint to trigger.
	//        CS_SPECIAL: Special endpoint. 'id' is predefined string.
	//         CS_REPORT: Report endpoint which base on database-id of the
	//                    report. 'id' is string which is the name of the
	//                    report if the report is a system report. 'id' can be
	//                    the database-id report as well which in this case must
	//                    be a number type.
	//          CS_EXIST: Endpoint follow one of the existing chart's endpoint.
	//                    The 'id' is object consist layout, position and index
	//                    of that existing chart.
	//                    Current 'chart' / 'param' (c/p) will not be
	//                    overwritten by targetted chart's c/p. When multiple
	//                    CS_EXIST appear, first encounter defined c/p will be
	//                    used. Current's c/p always highest priority.
	//                    NOTE: sharing chart with different 'param' won't work
	//                    because different parameter mean different chart data
	//                    but shared chart mean common data. Different 'chart'
	//                    will work because the 'chart' is pretty-much front-end
	//                    feature only at the moment (future may not be the
	//                    case when chart type can be changed dynamically).
	//                    Redux selector is used to obtain suitable data for
	//                    different 'chart' type.
	//                    Different 'layout' mean different page. In the case
	//                    where chart exist in different page / layout (p/l),
	//                    only the information that needed to fetch the chart
	//                    data is copied, the final resolved layout, position or
	//                    index are not used. Instead, the last resolved layout
	//                    that has the same layout current one will be used.
	//                    This allows only same p/l's charts to share chart
	//                    data. Different p/l's charts won't be allowed to
	//                    shared fetched chart data but only share the chart
	//                    information for fetching the chart data.
	//            CS_PIN: Pinned-report endpoint. This endpoint later will be
	//                    resolved to report endpoint. Its 'id' is the
	//                    database-id of the pinned-report.
	// param: Initial parameter that is used to trigger endpoint. If left out,
	//        default parameter will be used. Ignored for CS_EXIST as this
	//        value follows the target overview's param.
	// chart: TODO: determine the result of selector and chart type. The idea
	//        this is needed is that some chart data be used reuse but display
	//        as chart diagram.
	, layout: initLayoutData
	, normalizedOverviewLayout: normalizedExecutiveSystemReportLayout
};

const firstRequestUpdater = (endpointParam, param) => {
	const updater = {loading: true, endpointParam};
	if (param) {
		updater.param = param;
	}
	return updater;
};

const existedRequestUpdater = (endpointParam, param) => {
	const updater = {loading: {$set: true}, endpointParam: {$set: endpointParam}};
	if (param) {
		updater.param = {$set: param};
	}
	return updater;
};

const gridGet = ({
	layout
	, idx
}) => state => state.byShortid[layout][idx];

const gridUpdater = (
	{ layout, idx }
	, updater
) => ({byShortid: {[layout]: {[idx]: updater}}});

const byIdGet = chartId => state => state.byId[chartId];

const byIdUpdater = (chartId, updater) => ({byId: {[chartId]: updater}});

const createChartUpdater = (getter, updaterCreator) => chartId => {
	const o = {
		get: getter(chartId)
		, createUpdater: updater => updaterCreator(chartId, updater)
		, set: (state, updater) => update(state, o.createUpdater(updater))
		, updateData: (state, data) => o.set(state, {data: {$set: data}, loading: {$set: false}})
		, updateLoading: state => o.set(state, {loading: {$set: false}})
		, updateFirstRequest: (state, endpointParam, param) => o.set(state, {$set: firstRequestUpdater(endpointParam, param)})
		, updateExistedRequest: (state, endpointParam, param) => o.set(state, existedRequestUpdater(endpointParam, param))
		, updateReqest: (state, endpointParam, param) => {
			let updater;
			if (o.get(state)) {
				updater = o.updateExistedRequest
			} else {
				updater = o.updateFirstRequest
			}
			return updater(state, endpointParam, param);
		}
	};
	return o;
};

const gridChartUpdater = createChartUpdater(gridGet, gridUpdater);

const chartUpdater = createChartUpdater(byIdGet, byIdUpdater);

export const getChartUpdater = chartId => {
	if (typeof chartId === "object") {
		return gridChartUpdater(chartId);
	}
	return chartUpdater(chartId);
};

function simpleChartLayoutReducer(state, action) {
	const { data, param } = action.payload
		;
	if(!param) {
		return state;
	}
	const { oclayout } = param;
	if (!oclayout) {
		return state;
	}
	return getChartUpdater(oclayout).updateData(state, data);
}

function isPinReportExist(existingLayout, pinnedReportID) {
	let exist;
	$.each(existingLayout, (i, {id, type}) => {
		if (type === CS_PIN && id == pinnedReportID) {
			exist = true;
			return false;
		}
	});
	return !!exist;
}

export function getPinReportParam(v) {
	const filters = v.Filters;
	return {
		reportId: v.reportId
		, reportType: v.reportType
		, dateType: filters.dateType
		, relativeDateType: filters.relativeDateType
		, timeStampFormat: filters.timeStampFormat
		, timeFormat: filters.timeFormat
		, startTime: filters.startTime
		, endTime: filters.endTime
		, areas: filters.areas
		, channels: filters.channels
		, agentType: filters.agentType
		, groups: filters.groups
		, users: filters.users
		, tags: filters.tags
		, timeZoneId: filters.timeZoneId
		, completeResult: filters.showCompleteResult
	};
}

// TODO: never test before
const getPinReportUpdater = (
	chartId
	, updater
) => getChartUpdater(chartId).createUpdater(updater);

// TODO: never test before
const pinReportUpdater = (currentChartSize, raw) => getPinReportUpdater(
	chartIdentifier(
		CL_OVERVIEW
		, OL_LEFT_PANE
		, currentChartSize
		, currentChartSize
	)
	, {raw, param: getPinReportParam(raw)}
);

// TODO: never test before and new grid layout need to refactor this.
function appendPinReportsReducer(state, action) {
	// TODO: temporary pin-report only can appear in overview widget
	// as there is no storage for user overview layout. So, below here
	// will try to fill up the left panel charts with pinned-reports
	// until hit the limit.
	const limit = OC_LIMITS[OL_LEFT_PANE]
		, leftCharts = state.layout[CL_OVERVIEW][OL_LEFT_PANE]
		;
	if (leftCharts.length && leftCharts.length >= limit) {
		return state;
	}
	const { data } = action.payload
		, order = []
		;
	let newState = state
		, chartSize = leftCharts.length
		;
	each(data.pinnedReports, (v, i) => {
		if (isPinReportExist(leftCharts, v.Id)) {
			return;
		}
		newState = update(newState, pinReportUpdater(chartSize, v));
		order.push({id: v.Id, type: CS_PIN});
		chartSize++;
		if (chartSize >= limit) {
			return false;
		}
	});
	return update(
		newState
		, {layout: {[CL_OVERVIEW]: {[OL_LEFT_PANE]: {$push: order}}}}
	);
}

const loadingFalse = {loading: false}
	, activeAgentsParam = {id: OC_ACTIVE_AGENTS, type: CS_SPECIAL}
	, leaderboardParam = {id: OC_LEADERBOARD, type: CS_SPECIAL}
	, organisationParam = {id: OC_ORGANISATION, type: CS_SPECIAL}
	;
function chartFailReducer(state, action) {
	const { param } = action.payload
		, { oclayout } = param
		;
	if (!oclayout) {
		return state;
	}
	return getChartUpdater(oclayout).updateLoading(state);
}

function chartRequestSubReducerBase(state, action, endpointParam, paramGetter) {
	const { param: doneparam } = action.payload
		;
	if(!doneparam) {
		return state;
	}

	const { oclayout } = doneparam;
	if (!oclayout) {
		return state;
	}
	if (typeof endpointParam === "undefined") {
		endpointParam = doneparam.endpointParam;
	}
	let param;
	if (typeof paramGetter === "function") {
		param = paramGetter(state, action);
	} else {
		param = doneparam.param;
	}
	return getChartUpdater(oclayout).updateReqest(state, endpointParam, param);
}

function leaderboardParamGetter(state, action) {
	const { param: doneparam } = action.payload
		, { relativeDateType } = doneparam
		;
	return {relativeDateType};
}

const clearChartDataReducer = (state, { payload: { id } }) => {
	if (typeof id === "undefined") {
		return state;
	}
	return getChartUpdater(id).set(state, {$set: null});
};

const _chartsReducers = {
	[request(keyAdminOneReports)]: (state, action) => chartRequestSubReducerBase(state, action)
	, [fail(keyAdminOneReports)]: chartFailReducer
	, [done(keyAdminOneReports)]: simpleChartLayoutReducer
	, [done(keyAdminPinReports)]: state => state // appendPinReportsReducer
	, [request(keyAdminLeaderboard)]: (state, action) => chartRequestSubReducerBase(state, action, leaderboardParam, leaderboardParamGetter)
	, [fail(keyAdminLeaderboard)]: chartFailReducer
	, [done(keyAdminLeaderboard)]: (state, action) => {
		const { data, param } = action.payload
			, { leaderBoardType, oclayout } = param
			;
		if (!oclayout) {
			return state;
		}
		// TODO: if we wanna suport old code leader board on grid then this need
		// refactor
		const exist = state.byId[oclayout];
		let newData = {[leaderBoardType]: data};
		if (exist.data) {
			newData = update(exist.data, {$merge: newData});
		}
		return update(state, {byId: {[oclayout]: {
			data: {$set: newData}
			, loading: {$set: false}
		}}});
	}
	, [GET_CHART_IMAGE]: (state, { payload }) => {
		const { id, value } = payload;
		return getChartUpdater(id).set(state, {imageRequested: {$set: value}});
	}
	, [UPDATE_CHART_LAYOUT]: (state, action) => {
		return update(state, {layout: {[CL_REPORT]: {$set: action.payload}}});
	}
	, [UPDATE_CHART_PARAMETER]: (state, action) => {
		const { id, field, value } = action.payload;
		return getChartUpdater(id).set(state, {param: {[field]: {$set: value}}});
	}
	, [UPDATE_CHART_PARAMETERS]: (state, action) => {
		const { id, param } = action.payload;
		return getChartUpdater(id).set(state, {param: {$merge: param}});
	}
	, [UPDATE_REPORT_PARAM_ID]: clearChartDataReducer
	, [UPDATE_REPORT_COMPARE_PARAM_ID]: clearChartDataReducer
	, [CLEAR_CHART_DATA]: clearChartDataReducer
	, ...statisticChartsReducerMap
};

const createChartValidator = (
	systemReportValidator
	, pinReportsById
	, findChartLayoutId
) => (chartLayoutId, idx) => {
	if (chartLayoutId.type === CS_EXIST) {
		chartLayoutId = findChartLayoutId(chartLayoutId, idx);
	}
	if (!chartLayoutId) {
		return;
	}
	const { id, type } = chartLayoutId;
	if (type === CS_SPECIAL) {
		throw "no longer support special chart source"
	} else if (type === CS_REPORT) {
		if (typeof id === "number") {
			return true;
		} else if (typeof id !== "string") {
			throw "unsupport report chart source id " + typeof id;
		}
		return systemReportValidator(id);
	} else if (type === CS_PIN) {
		const { systemReportName } = pinReportsById(id);
		if (typeof systemReportName === "undefined") {
			return true;
		}
		return systemReportValidator(systemReportName);
	}
	throw `unsupport report chart source ${type} ${id}`;
};

// intelligently fill up missing piece of breakpoint key.
const addMissingBreakpoints = layouts => {
	let last;
	each(breakpointKeys, v => {
		const layout = layouts[v];
		if (typeof layout !== "undefined") {
			last = layout;
			return false;
		}
	});
	return fillupMissingBreakpointKeys(last, layouts)
};

const updateOverviewGridReducer = (state, action) => {
	if (action.type !== CHANGE_OVERVIEW_GRID) {
		return state;
	}
	const { payload: {
			cflag
			, chatEnabled
			, createChartLayoutIdFinder
			, grid: { charts: _charts, layouts: _layouts }
		} } = action
		, [layouts, charts, chartMap] = generateGridShortid(
			_charts
			, _layouts
			, chartMap => createChartValidator(
				createChartValidatorByName(cflag, chatEnabled)
				, id => {
					const { byId } = state[stateName(keyAdminPinReports)];
					if (!byId || !byId[id]) {
						return emptyObject;
					}
					return byId[id];
				}
				, createChartLayoutIdFinder(chartMap)
			)
		)
		;
	return update(state, { charts: {
		grid: { [CL_OVERVIEW]: { $set: {
			charts
			, layouts: addMissingBreakpoints(layouts)
		} } }
		, chartMap: { [CL_OVERVIEW]: { $set: chartMap } }
	} });
};

const initLayouts = {
	[CL_OVERVIEW]: emptyObject
};

const overviewLayoutReducer = (
	state
	, { payload: { data: { content } } }
) => update(state, { [CL_OVERVIEW]: { $set: content } });

const _layoutsReducers = {
	[done(keyGetReportsOverviewLayout)]: overviewLayoutReducer
	, [done(keySaveReportsOverviewLayout)]: overviewLayoutReducer
};

const statsCurrentView = {
	active: EXECUTIVE_REPORT,
	activeId: 0,
	ready: false,
	reportParamId: null,
	schedule: {
		toggle: false,
		activeId: 0
	},
	createReport: {
		toggle: false,
		activeId: 0
	},
	share: {
		toggle: false
	},
	drilldown: {
		show: false,
		parameters: null
	},
	configTime: {
		toggle: false,
		activeId: 0
	}
};

const statsViewReducer = (st = statsCurrentView, act) => {
	const p = act.payload;
	if(act.type === LOAD_STATISTICS_VIEW) {
		let data = [];
		if(typeof p.data !== "undefined"){
			data = p.data;
		}
		return update(st, {
			active: {$set: p.view},
			activeId: {$set: data.id ? data.id : 0},
			schedule: {
				activeId: {$set: 0}
			}
		});
	} else if (act.type === SHOW_DRILLDOWN_DATA_TABLE) {
		return update(st, {drilldown: {show: {$set: p}}});
	} else if (act.type === STATISTICS_READY) {
		return update(st, {ready: {$set: true}});
	} else if (act.type === STORE_CLEAR_DRILLDOWN_PARAMETERS) {
		return update(st, {drilldown: {parameters: {$set: p}}});
	} else if (act.type === UPDATE_REPORT_PARAM_ID) {
		return update(st, {reportParamId: {$set: p}});
	} else if (act.type === UPDATE_REPORT_COMPARE_PARAM_ID) {
		return update(st, {reportCompareParamId: {$set: p}});
	} else if (act.type === SET_SCHEDULE_TOGGLE) {
		return update(st, {schedule: {toggle: {$set: p}}});
	} else if (act.type === SET_SHARE_TOGGLE) {
		return update(st, {share: {toggle: {$set: p}}});
	} else if (act.type === OPEN_SCHEDULE_REPORT) {
		return update(st, {
			schedule: {
				activeId: {$set: p}
			}
		});
	} else if (act.type === OPEN_CREATE_REPORT) {
		return update(st, {
			createReport: {
				activeId: {$set: (p ? p : 0)},
				toggle: {$set: true}
			}
		});
	} else if (act.type === TOGGLE_CREATE_REPORT) {
		return update(st, {createReport: {toggle: {$set: p}}});
	} else if (act.type === TOGGLE_CONFIG_TIME) {
		return update(st, {configTime: {toggle: {$set: p}}});
	} else if (act.type === OPEN_CONFIG_TIME) {
		return update(st, {
			configTime: {
				activeId: {$set: (p ? p : 0)},
				toggle: {$set: true}
			}
		});
	}
	return st;
};

const initConfigtime = {
	createNew: {
		name:'',
		timespan: '',
		data: [],
		selectedTimespan: '',
		selectedTimespanArr: []
	}
}

const configTimeReducers = {
	[done(keyCustomTimeSpan)]: (state, action) => {
		const { data } = action.payload;
		if(data) {
			let ids = [];
			if(data.data){
				$.each(data.data, (i, v) => {
					ids.push(v.id);
				});
			}
			return update(state, {
				createNew: {
					name: {$set: data.displayName ? data.displayName : ""},
					timespan: {$set: data.timespan},
					data: {$set: data.data},
					selectedTimespan: {$set: ids.join(",")},
					selectedTimespanArr: {$set: ids}
				}
			});
		}
		return state;
	},
	[RESET_CONFIG_TIME]: (state, action) => {
		return initConfigtime;
	},
	[UPDATE_CONFIG_TIME]: (state, action) => {
		const { field, value } = action.payload;
		if(field === "selectedTimespan"){
			let curValues = [];
			if(state.createNew.selectedTimespan != ""){
				curValues = state.createNew.selectedTimespan.toString().split(",").map(v => parseInt(v, 10));
			}
			curValues.push(value);
			return update(state, {
				createNew: {
					selectedTimespan: {$set: curValues.join(",")},
					selectedTimespanArr: {$set: curValues}
				}
			});
		}
		return update(state, {createNew: {[field]: {$set: value}}});
	},
	[REORDER_CONFIG_TIME]: (state, action) => {
		let { pos, id } = action.payload;
		let ids = parseInt(id, 10);
		let list = state.createNew.selectedTimespanArr;
		let index = state.createNew.selectedTimespanArr.findIndex(x => x === ids);
		let newObj = update(list, {
			$splice: [[index, 1]]
		})
		newObj = update(newObj, {
			$splice: [[pos, 0, ids]]
		});
		return update(state, {
			createNew: {
				selectedTimespanArr: {$set: newObj},
				selectedTimespan: {$set: newObj.join(",")}
			}
		});
	},
	[REMOVE_SELECTION_CONFIG_TIME]: (state, action) => {
		let index = action.payload;
		let list = state.createNew.selectedTimespanArr;
		let newObj = update(list, {
			$splice: [[index, 1]]
		});
		return update(state, {
			createNew: {
				selectedTimespanArr: {$set: newObj},
				selectedTimespan: {$set: newObj.join(",")}
			}
		});
	},
	[CLEAR_SELECTION_CONFIG_TIME]: (state, action) => {
		return update(state, {
			createNew: {
				selectedTimespanArr: {$set: []},
				selectedTimespan: {$set: ''}
			}
		});
	}
}

const getIdStrFromList = (list) => {
	let ids = [], s = "";
	$.each(list, (i, v) => {
		ids.push(v.id);
	});
	s = ids.join(",");
	return s;
}

const initCreateReport = {
	createNew: {
		name: ''
		, availability: 0
		, keys: ""
		, groups: ""
		, status: ''
		, buttonBusy: false
	}
	, preview: false
}

const createReportReducers = {
	[done(keyAdminOneReports)]: (state, action) => {
		const { data } = action.payload;
		if(data && typeof data.availability !== "undefined") {
			return update(state, {
				createNew: {
					name: {$set: (data.name ? data.name : '')},
					availability: {$set: (data.availability ? data.availability : 0)},
					keys: {$set: getIdStrFromList(data.keys)},
					groups: {$set: getIdStrFromList(data.groups)}
				}
			});
		}
		return state;
	},
	[UPDATE_CREATE_REPORT]: (state, action) => {
		const { field, value } = action.payload;
		if(field === "keys"){
			//reset groups back to empty because some of the groups selected
			//previously may no longer valid
			return update(state,{
				createNew: {
					keys: {$set: value},
					groups: {$set: ""}
				}
			});
		}
		return update(state, {createNew: {[field]: {$set: value}}});
	},
	[RESET_CREATE_REPORT]: (state, action) => {
		return initCreateReport;
	},
	[done(keyReportPreview)]: (state, action) => {
		return update(state, {
			preview: {$set: true}
		});
	},
	[TOGGLE_REPORT_PREVIEW]: (state, action) => {
		const toggle = action.payload;
		return update(state, {
			preview: {$set: toggle}
		})
	},
	[REORDER_CREATE_REPORT_KEY]: (state, action) => {
		let { pos, id } = action.payload;
		let ids = parseInt(id, 10);
		let list = state.createNew.keys;
		let arrList = [];
		if(list) {
			arrList = list.split(",").map(v => parseInt(v, 10));
		}
		let index = arrList.findIndex(x => x === ids);
		let newObj = update(arrList, {
			$splice: [[index, 1]]
		})
		newObj = update(newObj, {
			$splice: [[pos, 0, ids]]
		});
		return update(state, {
			createNew: {
				keys: {$set: newObj.join(",")}

			}
		});
	},
	[REORDER_CREATE_REPORT_GROUP]: (state, action) => {
		let { pos, id } = action.payload;
		let ids = parseInt(id, 10);
		let list = state.createNew.groups, arrList = [];
		if(list) {
			arrList = list.split(",").map(v => parseInt(v, 10));
		}
		let index = arrList.findIndex(x => x === ids);
		let newObj = update(arrList, {
			$splice: [[index, 1]]
		})
		newObj = update(newObj, {
			$splice: [[pos, 0, ids]]
		});
		return update(state, {
			createNew: {
				groups: {$set: newObj.join(",")}
			}
		});
	},
	[REMOVE_SELECTED_REPORT_KEY]: (state, action) => {
		let index = action.payload;
		let list = state.createNew.keys;
		let arrList = [];
		if(list) {
			arrList = list.split(",").map(v => parseInt(v, 10));
		}
		let newObj = update(arrList, {
			$splice: [[index, 1]]
		});
		return update(state, {
			//reset groups back to empty because some of the groups selected
			//previously may no longer valid
			createNew: {
				keys: {$set: newObj.join(",")},
				groups: {$set: ""}
			}
		});
	},
	[REMOVE_SELECTED_REPORT_GROUP]: (state, action) => {
		let index = action.payload;
		let list = state.createNew.groups, arrList = [];
		if(list) {
			arrList = list.split(",").map(v => parseInt(v, 10));
		}
		let newObj = update(arrList, {
			$splice: [[index, 1]]
		});
		return update(state, {
			createNew: {groups: {$set: newObj.join(",")}}
		});
	}
}

const initScheduleReport = {
	createNew: {
		reportName: ''
		, reportId: 0
		, id: 0
		, address: ''
		, outputType: 0
		, relativeDateType: 1
		, time: 1
		, hour: 0
		, minute: 0
		, dateType: 2
		, status: ''
		, buttonBusy: false
		, timeZoneId: 0
		, timeStampFormat: 0
		, agentType:0
		, users: ''
		, areas:''
		, channels: ''
		, groups: ''
		, tags: ''
		, showCompleteResult: false
	},
	list: {}
};

const initShareReport = {
	selectedAgent: ''
	, emailAddress: ''
	, shareReportId: 0
	, imageLeftChart: ''
	, status: ''
	, busy: false
};

const scheduleReducers = {
	[LOAD_STATISTICS_VIEW]: (state, action) => {
		const { view } = action.payload;
		if(view == VIEW_REPORT){
			return initScheduleReport;
		}
		return state;
	},
	[UPDATE_SCHEDULE_REPORT]: (state, action) => {
		const { field, value } = action.payload;
		return update(state, {createNew: {[field]: {$set: value}}});
	},
	[RESET_SCHEDULE_REPORT]: (state, action) => {
		return initScheduleReport;
	},
	[done(keyShowScheduledReports)]: (state, action) => {
		const { scheduledReports, count } = action.payload.data;
		if(count > 0){
			return update(state, {list: {$set: scheduledReports}});
		}else{
			return update(state, {list: {$set: []}});
		}
		return state;
	},
};

const updateScheduleReportParamReducer = (state = {}, action) => {
	if(action.type === OPEN_SCHEDULE_REPORT) {
		let view = state.view
		, charts = state.charts
		;
		// Edit schedule report
		if (view.schedule.activeId > 0) {
			let id = action.payload
			, list = state.scheduleReport.list
			;
			for (let i = 0; i < list.length; i++){
				let report = list[i];
				if (report.Id === id) {
					let mailAddr = "";
					if(report.Addresses && report.Addresses.length > 0){
						let mails = [];
						$.each(report.Addresses, (i,v) => {
							mails.push(v.emailAddress);
						});
						mailAddr = mails.join(",");
					}
					return update(state, {
						scheduleReport: {
							createNew: {
								id: {$set: report.Id},
								reportName: {$set: report.Name},
								reportId: {$set: report.ReportId},
								outputType: {$set: report.Output},
								address: {$set: mailAddr},
								time: {$set: report.Time},
								hour: {$set: report.Hour},
								minute: {$set: report.Minute},
								relativeDateType: {$set: report.Filters.relativeDateType},
								dateType: {$set: report.Filters.dateType},
								timeZoneId: {$set: report.Filters.timeZoneId},
								timeStampFormat: {$set: report.Filters.timeStampFormat},
								channels: {$set: report.Filters.channels},
								areas: {$set: report.Filters.areas},
								agentType: {$set: report.Filters.agentType},
								users: {$set: report.Filters.users},
								groups: {$set: report.Filters.groups},
								tags: {$set: report.Filters.tags},
								showCompleteResult: {$set: report.Filters.completeResult}
							}
						}
					});
					break;
				}
			}
		} else if (view.reportParamId) { // Create schedule report
			const chart = charts.byId[view.reportParamId.id];
			return update(state, {
				scheduleReport: {
					createNew: {
						id: {$set: 0},
						reportName: {$set: view.reportParamId.chart.id},
						reportId: {$set: chart.endpointParam.id},
						outputType: {$set: ET_EXCEL},
						address: {$set: ""},
						time: {$set: RDT_EVERY_DAY},
						hour: {$set: 0},
						minute: {$set: 0},
						relativeDateType: {$set: chart.param.relativeDateType},
						dateType: {$set: chart.param.dateType},
						timeZoneId: {$set: chart.param.timeZoneId},
						timeStampFormat: {$set: chart.param.timeStampFormat},
						channels: {$set: chart.param.channels},
						areas: {$set: chart.param.areas},
						agentType: {$set: chart.param.agentType},
						users: {$set: chart.param.users},
						groups: {$set: chart.param.groups},
						tags: {$set: chart.param.tags},
						showCompleteResult: {$set: chart.param.completeResult}
					}
				}
			});
		}
	}
	return state;
};

const shareReducers = {
	[UPDATE_SHARE_PARAM]: (state, action) => {
		const { field, value } = action.payload;
		const { selectedAgent } = state;
		if (selectedAgent !== "" && field === 'selectedAgent') {
			const strValue = value.toString();
			if (strValue.indexOf(',') < 0) {
				const strSelectedAgent = selectedAgent.toString();
				if (strSelectedAgent.indexOf(',') < 0) {
					if (strSelectedAgent === strValue) {
						return update(state, { [field]: { $set: selectedAgent } });
					} else {
						var res = selectedAgent + ',' + value;
						return update(state, { [field]: { $set: res } });
					}
				} else {
					var arrSelectedAgent = selectedAgent.split(',');
					var arrValue = [strValue];
					if (arrSelectedAgent.includes(strValue)) {
						return update(state, { [field]: { $set: selectedAgent } });
					} else {
						var resArray = arrSelectedAgent.concat(arrValue);
						var resStr = resArray.join()
						return update(state, { [field]: { $set: resStr } });
					}
				}
			} else {
				return update(state, { [field]: { $set: value } });
			}
		}
		if (field === 'removeSelectedAgent') {
			return update(state, { selectedAgent: { $set: value } });
		};
		return update(state, { [field]: { $set: value } });
	},
	[RESET_SHARE_REPORT]: (state, action) => {
		return initShareReport;
	}
};

let statistics = combineReducers({
	view: statsViewReducer,
	charts: createReducer(initCharts, _chartsReducers),
	layouts: createReducer(initLayouts, _layoutsReducers),
	scheduleReport: createReducer(initScheduleReport, scheduleReducers),
	createReport: createReducer(initCreateReport, createReportReducers),
	configTime: createReducer(initConfigtime, configTimeReducers),
	shareReport: createReducer(initShareReport, shareReducers),
	[stateName(keyAdminLeaderboard)]: closedLeaderboard(),
	handlingTimeLeaderboard: handlingTimeLeaderboard(),
	[stateName(keyAdminReportDrilldown)]: reportDrilldown(),
	[stateName(keyAdminReports)]: reports(),
	[stateName(keyAdminPinReports)]: pinReports(),
	[stateName(keyGetReportsOverviewLayout)]: reduceReducers(
		defAsyncReducer(keyGetReportsOverviewLayout, asyncInitState),
		(state, action) => {
			if (action !== done(keySaveReportsOverviewLayout)) {
				return state;
			}
			const { payload } = action;
			return update(state, {data: {$set: payload}});
		}
	),
	[stateName(keyGroups)]: groups(),
	[stateName(keySaveReportsOverviewLayout)]: defAsyncReducer(
		keySaveReportsOverviewLayout,
		asyncInitState
	),
	[stateName(keyTimeTimespan)]: timespan(),
	[stateName(keyTimeformat)]: timeformat(),
	[stateName(keyTimeTimezones)]: timezones(),
	[stateName(keyGetReportKeys)]: reportKeys(),
	[stateName(keyGetReportGroups)]: reportGroups(),
	[stateName(keyAdminCustomReports)]: customReports(),
	[stateName(keyReportPreview)]: reportPreview(),
	[stateName(keyTimespanFormats)]: timespanFormats(),
	[stateName(keyCollaboratorList)] : collaborators(),
});

statistics = reduceReducers(
	statistics,
	updateScheduleReportParamReducer,
	updateOverviewGridReducer
);

export default statistics;
