import { combineReducers } from 'redux';
import {
     CKEDITOR_CLEAR_INSERTION_TEXT
     , CKEDITOR_INSERT_TEXT
} from '../constants/constants';
import { createReducer } from '../util';

const _insertionTextReducers = {
     [CKEDITOR_CLEAR_INSERTION_TEXT]: () => ""
     , [CKEDITOR_INSERT_TEXT]: (state, { payload }) => payload
};

let ckeditor = combineReducers({
     insertionText: createReducer("", _insertionTextReducers)
});

export default ckeditor;