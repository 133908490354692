// debugger related
import { registerSelectors } from 'reselect-tools';
import * as chatSelectors from './selectors/chat';
import * as errandSelectors from './selectors/errand';
import * as headerSelectors from './selectors/hmf';

if (window.__RESELECT_TOOLS__) {
	registerSelectors(chatSelectors);
	registerSelectors(errandSelectors);
	registerSelectors(headerSelectors);
}
