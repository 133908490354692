import map from 'lodash/map'
import update from 'immutability-helper';
import reduceReducers from 'reduce-reducers';
import { emptyArray } from '../../common/constants'
import {
	CHAT_ADD_FILE_ARCHIVE,
	CHAT_ADD_KB_FILE,
	CHAT_ADD_UNSENT_MESSAGE,
	CHAT_ERROR,
	CHAT_MESSAGE_SENT,
	CHAT_REMOVE_FILE_ARCHIVE,
	CHAT_REMOVE_KB_FILE,
	CHAT_SET_CURRENT_MESSAGE,
	DATA_SYNC,
	ECHAT_ANSWER_INVITE,
	WS_EVENT,
	evtCHAT_REGISTER,
	evtCHAT_FINISH_SESSION,
	evtCHAT_FORWARDED,
	evtCHAT_ERRAND_ADD,
	evtCHAT_NEW_MESSAGE,
	evtDELETE_MESSAGE,
	evtCHAT_ANSWER_INVITE,
	evtCHAT_TAGS,
	evtCHAT_PREVIEW,
	evtCHAT_PARTICIPANTS,
	evtCHAT_UPDATE_IPADDR,
	evtCHAT_UNBLOCK_IP,
	evtCHAT_MESSAGE_ACKED,
	evtCHAT_MESSAGES_SEEN,
	evtCHAT_DEAD_CHAT_SESSION,
	evtCHAT_MAYBE_DEAD_CHAT_SESSION,
	evtCHAT_CHAT_SESSION_IS_NOT_DEAD,
	evtCHAT_CHAT_SESSION_VISITOR_PATH,
	SET_CURRENT_ERRAND,
	UPLOAD_CHAT_ATTACHMENT,
	CHAT_SENT_ONE_ATTACHMENT,
	CHAT_UPDATE_TAGS,
	CHAT_SET_CANDIDATES,
	CHAT_SET_SEEN,
	CHAT_GETTING_CANDIDATES,
	CHAT_DONE_GETTING_CANDIDATES,
	CHAT_IP_BLOCKED,
	CHAT_ADD_INTERNAL_COMMENT,
	CHAT_DELETE_INTERNAL_COMMENT,
	CHAT_UPDATE_INTERNAL_COMMENT,
	FETCHED_CHAT_AGENT_CANDIDATES,
	FETCHED_CHAT_AREA_CANDIDATES,
	FETCHING_CHAT_AGENT_CANDIDATES,
	FETCHING_CHAT_AREA_CANDIDATES,
	FETCH_CHAT_AGENT_CANDIDATES_FAILED,
	FETCH_CHAT_AREA_CANDIDATES_FAILED,
	FORWARD_CHAT_TO_AGENT_STARTED,
	FORWARD_CHAT_TO_AGENT_SUCCEEDED,
	FORWARD_CHAT_TO_AREA_STARTED,
	FORWARD_CHAT_TO_AREA_FAILED,
	FORWARD_CHAT_TO_AREA_SUCCEEDED,
	SELECT_ERRAND_IN_ERRAND_LIST,
	SELECT_ALL_ERRANDS_IN_ERRAND_LIST,
	evtCHAT_HAS_NEW_EMOTICON,
	evtCHAT_WA_SESSION_LOCKED,
	CHAT_VIDEO_AGENT_SHOW,
	CHAT_VIDEO_CLIENT_READY,
	CHAT_VIDEO_CALL_INPROGRESS,
	CHAT_VIDEO_CALL_FULLSCREEN,
	CHAT_VIDEO_CALL_CLIENT_MUTED,
	CHAT_VIDEO_CALL_CLIENT_WEBCAM,
	CHAT_VIDEO_UPDATE_TIMER,
	CLIENT_REJECT_VIDEO_CALL,
	CHAT_AGENT_RECORD_VID,
	CHAT_CLIENT_COBROWSE_OFFER,
	CHAT_VIDEO_BG_EXTRACTION_STATUS,
	CHAT_SET_SUMMARY
} from '../constants/constants';
import {
	CHAT_crOwner,
	CHAT_crGuest,
} from '../../common/v5/constants';
import {
	CHAT_LIST,
	CURRENT_CHAT,
	createSetupCondtionReducer
} from './wire';
import { createReducer } from '../util';

const initChatInputUI = ce => {
	if (!ce.ui) {
		ce.ui = {
			currentMessage: "",
			selected: false,
			chatSummaryInput: ""
		};
	}
	return ce;
}

const initMessageById = ce => {
	ce.messageById = {};
	ce.messages.map((msg, i) => {
		ce.messageById[msg.id] = msg;
	})
	return ce;
}

const initUnsentMessage = ce => {
	if (!ce.unsent) {
		ce.unsent = [];
	}
	return ce;
}

const initUnhandledEmoticon = ce =>{
	if (!ce.hasNewEmoticon) {
		ce.hasNewEmoticon = "";
	}
	return ce;
}

const initUnhandledReceipt = ce =>{
	if (!ce.hasNewReceipt) {
		ce.hasNewReceipt = "";
	}
	return ce;
}

const initArchiveFiles = ce => {
	if (!ce.files) {
		ce.files = [];
	}
	return ce;
}

const initAddAgent = ce => {
	if (!ce.addAgent) {
		ce.addAgent = {};
	}
	if (!ce.agentPresence) {
		ce.agentPresence = {};
	}
	return ce;
}

const initAckedMessages = ce => {
	ce.messages = $.map(ce.messages, m => {
		if (m.id <= ce.CMaxId) {
			return update(m, { read: { $set: true }});
		}
		return m;
	});
	return ce;
}

const hasExpired = ce => {
	if (!ce.dead || ce.messages.length == 0) {
		return false;
	}

	let lastMsg = ce.messages[ce.messages.length-1];
	if (lastMsg.aid != 0 || lastMsg.fromClient) {
		return false;
	}

	return lastMsg.text && lastMsg.text == '{"event":"CHAT_EXPIRED"}';
}

const countUnseen = messages => {
	let i
	, c = 0
	;

	// count the number of messages that the agent has not read.
	for (i=0; i<messages.length; i++) {
		let msg = messages[i];
		if(!msg.seen && (msg.fromClient || msg.aid != initialChatData.agentID)){
			c++;
		}
	}

	return c;
}

const checkHasAgentMsg = messages => {
	let i
	, haveAgentMsg = false
	;
	// count the number of messages from agent
	for (i=0; i<messages.length; i++) {
		let msg = messages[i];
		if(!msg.fromClient && msg.aid > 0){
			haveAgentMsg = true;
			break;
		}
	}
	return haveAgentMsg;
}

const initChatErrand = ce => {
	ce = initChatInputUI(ce);
	ce = initUnsentMessage(ce);
	ce = initUnhandledEmoticon(ce);
	ce = initUnhandledReceipt(ce);
	ce = initArchiveFiles(ce);
	ce = initAddAgent(ce);
	ce = initAckedMessages(ce);
	ce.expired = hasExpired(ce);
	ce.unReadMsgs = countUnseen(ce.messages);
	ce.videoCallClientReady = checkHasAgentMsg(ce.messages);
	return initMessageById(ce);
}

const addChatErrand = (state, ce) => {
	let o, n; // old and new
	o = [...state];
	n = [];
	let added = false;
	let cenew = {...ce};
	initChatErrand(cenew);

	// add the new one, sort descending by errand id (newest at top).
	o.map((cex, i) => {
		if (added) {
			n.push(cex);
			return;
		}
		if (cex.errand.id > cenew.errand.id) {
			n.push(cex);
		} else if (cex.errand.id == cenew.errand.id) {
			if(cex.visitorPaths){
				Object.assign(cenew, {visitorPaths: [cex.visitorPaths[0]]})
			}
			n.push(cenew);
			added = true;
		} else if (cex.errand.id < cenew.errand.id) {
			n.push(cenew);
			added = true;
			n.push(cex);
		}
	});
	if (!added) {
		n.push(cenew);
	}
	return n;
}

const removeChatErrand = (state, sessionId) => {
	let o= [...state]
	, n = []
	;
	for (var cex of o) {
		if (cex.sessionId == sessionId) {
			continue;
		}
		n.push(cex);
	}
	return n;
}

const updateChatEmoticons = (state, args) =>{
	let sessionIDX = 0
	, msgIDX = 0
	, i = -1
	, emots = []
	, emot = {}
	, emotChanges = ""
	;
	if(typeof args === 'undefined' && args === null){
		return state;
	}
	let sessionId = args.sessionId
		, messageId = args.mId
		, emoticon = args.emoticon
		, receipt = args.watermark
		, timestamp = args.timestamp
		;
	if(sessionId === 0) return state;
	if(messageId === 0) return state;
	for (let cex of state) {
		i++;
		if (cex.sessionId !== sessionId) {
			continue;
		}
		sessionIDX = i;
		emots = [];
		let j = 0;
		for (let msg of cex.messages) {
			if(msg.id === messageId){
				emot = msg
				emot.emoticon = emoticon;
				emot.receipt = receipt;
				msgIDX = j
				//emotChanges decides to re-render for each emoticon/receipts reaches into system
				emotChanges = `${messageId}-${timestamp}`
				emots.push(emot);
			}else{
				emot = msg
				if(msg.receipt !== 'read'){
					emot.emoticon = emoticon;
					emot.receipt = receipt;
				}
				emots.push(emot);
			}
			j++;
		};
	}
	return update(state, {[sessionIDX]: {
		messages: {$set:emots}
		, messageById: {[messageId]:{$set:emot}}
		, hasNewEmoticon: {$set: emotChanges}
	}});
};

const addWaSessionLocked = (state, cm) => {
	let canSendMessageToWA = true
	, i = -1
	, idxnew = 0
	;
	for (var cex of state) {
		i++;
		if (cex.sessionId != cm.sessionId) {
			continue;
		}
		idxnew = i;
		canSendMessageToWA = false;
		break;
	}
	return update(state,  {
		[idxnew]: {
			canSendMessageToWA: {$set: canSendMessageToWA},
		}
	});
}
const deleteMessageByUmid = (state, umid) => {
	for (const sessionIndex in state) {
		const session = state[sessionIndex];
		const messageIndex = session.messages.findIndex(msg => msg.umid === umid.addr.mId);
		console.log("messageIndex", messageIndex)

		if (messageIndex !== -1) {
			const messageId = session.messages[messageIndex].id;
			const newMessages = update(session.messages, { $splice: [[messageIndex, 1]] });
			const newMessageById = update(session.messageById, { $unset: [messageId.toString()] });

			state = update(state, {
				[sessionIndex]: {
					messages: { $set: newMessages },
					messageById: { $set: newMessageById },
					unReadMsgs: { $set: countUnseen(newMessages) }
				}
			});
			return state;
		}
	}
	return state;
}
const addNewMessage = (state, cm) => {
	let idxnew = 0
	, toAdd = []
	, toAddById = {}
	, n
	, i = -1
	, clearPreview
	, hasNewMessageFromSelf
	, hasNewMessageFromClient = false
	, newUnseen = 0
	, canSendMessageToWA = true
	;

	for (var cex of state) {
		i++;
		if (cex.sessionId != cm.sessionId) {
			continue;
		}
		idxnew = i;
		for (var msg of cm.messages) {
			if (cex.messageById[msg.id]) {
				continue;
			}
			toAdd.push(msg);
			toAddById[msg.id] = msg;
			if (msg.fromClient) {
				canSendMessageToWA = msg.canSendWaMessage;
				clearPreview = true;
				if (!msg.seen) {
					newUnseen++;
				}
				hasNewMessageFromClient = true;
			} else if (msg.aid == initialChatData.agentID) {
				hasNewMessageFromSelf = true;
				hasNewMessageFromClient = false;
			} else {
				// system message
				if (!msg.seen) {
					newUnseen++;
				}
			}
		};
		break;
	}
	if (toAdd.length == 0) {
		return state;
	}

	let preview = state[idxnew].preview;
	if (clearPreview) {
		preview = '';
	}
	return update(state,  {
		[idxnew]: {
			messages: { $push: toAdd },
			messageById: { $merge : toAddById },
			preview: { $set: preview },
			hasNewMessageFromSelf: { $set: hasNewMessageFromSelf },
			hasNewMessageFromClient: { $set: hasNewMessageFromClient },
			unReadMsgs: { $set: countUnseen(state[idxnew].messages) + newUnseen },
			canSendMessageToWA: {$set: canSendMessageToWA},
		}
	});
}

const markAsDead = (ce, expired) => {
	return update(ce, {
		dead: { $set: true },
		expired: { $set: expired },
	});
}

const setDead = (ces, sid, expired) => {
	return updateChat(ces, sid, ce => markAsDead(ce, expired));
}

const mergeState = (state, newChatErrands) => {
	let byId = {};
	if (state) {
		$.each(state, (i, ce) => {
			byId[ce.sessionId] = ce;
		});
	}
	newChatErrands = newChatErrands.map(ce => {
		let ece = byId[ce.sessionId];
		if (!ece) {
			return ce;
		}

		// synchronize front end's message seen state with the new ones
		// from backend.
		if (ece.messages) {
			let i;
			for (i=0; i<ce.messages.length; i++) {
				if (i >= ece.messages.length) {
					break;
				}
				if (ce.messages[i].id != ece.messages[i].id) {
					// this should never happen
					break;
				}
				ce.messages[i].seen = ece.messages[i].seen;
			}
		}

		return update(ce, {
			unsent: { $set: ece.unsent },
			ui: { $set: ece.ui },
			files: { $set: ece.files },
		});
	});
	return newChatErrands.map(initChatErrand);
}
//wsEventReducer represents the the events which emitted from the chatd
const wsEventReducer = (state, action) => {
	let packet = action.packet
	, args = packet.args
	, o
	;
	switch (packet.event) {
	case evtCHAT_REGISTER:
		if (args[0].chatErrands) {
			return mergeState(state, args[0].chatErrands);
		}
		return state;
	case evtCHAT_ERRAND_ADD:
		return addChatErrand(state, args[0]);
	case evtCHAT_FINISH_SESSION:
		o = args[0];
		if (o.isOwner || o.guestLeaving || o.isStale) {
			return removeChatErrand(state, o.sessionId);
		}
		return setDead(state, o.sessionId, o.expired);
	case evtCHAT_HAS_NEW_EMOTICON: //used for social media emoticon and read receipts
		return updateChatEmoticons(state, args[0]);
	case evtCHAT_WA_SESSION_LOCKED:
		return addWaSessionLocked(state, args[0]);
	case evtCHAT_NEW_MESSAGE:
		return addNewMessage(state, args[0]);
	case evtDELETE_MESSAGE:
		return deleteMessageByUmid(state, args[0]);
	case evtCHAT_ANSWER_INVITE:
		// This event is triggered by agent accepting chat in 4.5 UI.
		if (args[0].isStale) {
			return removeChatErrand(state, args[0].sessionId);
		}
		return updateJoin(state, args[0].sessionId);
	case evtCHAT_TAGS:
		return chatUpdateTags(state, args[0].sessionId, args[0].Tags);
	case evtCHAT_PREVIEW:
		return chatUpdatePreview(state, args[0].sessionId, args[0].previewType, args[0].preview);
	case evtCHAT_PARTICIPANTS:
		return chatUpdateParticipants(state, args[0].sessionId, args[0].AgentIds, args[0].InvitedAgentIds);
	case evtCHAT_UPDATE_IPADDR:
		return chatUpdateClientIPAddress(state, args[0].sessionId, args[0].addr);
	case evtCHAT_UNBLOCK_IP:
		return chatSetIPBlocked(state, args[0].sessionId, false);
	case evtCHAT_MESSAGE_ACKED:
		return chatMessageAcked(state, args[0].sessionId, args[0].ids);
	case evtCHAT_MESSAGES_SEEN:
		return chatMessageSeen(state, args[0].sessionId, args[0].ids);
	case evtCHAT_DEAD_CHAT_SESSION:
		o = args[0];
		if( o.hasUnhandledMsg ){
			setDead(state, o.sessionId, o.expired);
			return removeChatErrand(state, o.sessionId);
		}
		return chatUpdateClientOnlineStatus(state, o.sessionId, false);
	case evtCHAT_MAYBE_DEAD_CHAT_SESSION:
		return chatUpdateClientOnlineStatus(state, args[0].sessionId, false);
	case evtCHAT_CHAT_SESSION_IS_NOT_DEAD:
		return chatUpdateClientOnlineStatus(state, args[0].sessionId, true);
	case evtCHAT_CHAT_SESSION_VISITOR_PATH:
		return chatUpdateVisitorPaths(state, args[0].sessionId,
			args[0].errandId,
			args[0].paths);
	}

	// console.log("DEBUG TODO HANDLE WS_EVENT", packet.event);
	return state;
}

// TODO: here is another place where normalized redux store data can help. Then
// no need duplicate reducer at this part.
const wsEventReducerCurrentChat = (chat, action) => {
	if (!action.packet.args || action.packet.args.length > 0 && chat.sessionId != action.packet.args[0].sessionId) {
		return chat;
	}
	switch (action.packet.event) {
		case evtCHAT_FORWARDED:
			return updateChatOwner(chat, action.packet.args[0].toId, action.packet.args[0].to);
		case evtCHAT_FINISH_SESSION:
			return updateChatClosed(chat, action.packet.args[0]);
		case evtCHAT_TAGS:
			return updateTags(chat, action.packet.args[0].Tags);
		case evtCHAT_PREVIEW:
			return updatePreview(chat, action.packet.args[0].previewType, action.packet.args[0].preview);
		case evtCHAT_PARTICIPANTS:
			return updateParticipants(chat, action.packet.args[0].AgentIds, action.packet.args[0].InvitedAgentIds);
		case evtCHAT_UPDATE_IPADDR:
			return updateClientIPAddress(chat, action.packet.args[0].addr);
		case evtCHAT_UNBLOCK_IP:
			return updateIPBlocked(chat, false);
		case evtCHAT_DEAD_CHAT_SESSION:
			return updateClientOnlineStatus(chat, false);
		case evtCHAT_MAYBE_DEAD_CHAT_SESSION:
			return updateClientOnlineStatus(chat, false);
		case evtCHAT_CHAT_SESSION_VISITOR_PATH:
			return updateClientVisitorPaths(chat,
				action.packet.args[0].sessionId,
				action.packet.args[0].errandId,
				action.packet.args[0].paths);
		}
	return chat;
}

const updateClientVisitorPaths = (ce, sid, eid, paths) => {
	if(ce.sessionId == sid){
		return update(ce, {visitorPaths: {$set: paths}});
	}
	return ce
}
const chatUpdateClientOnlineStatus = (ces, sid, onlineStatus) => updateChat(ces, sid, ce => updateClientOnlineStatus(ce, onlineStatus));
const updateClientOnlineStatus = (ce, onlineStatus) => update(ce, {ClientOnline: {$set: onlineStatus}});

const chatUpdateVisitorPaths = (ces, sid, eid, paths) => updateChat(ces, sid, ce => updateVisitorPaths(ce, paths));
const updateVisitorPaths = (ce, paths) => update(ce, { visitorPaths: { $set: paths }});

const chatAddUnsentMessage = (ces, action) => updateChat(ces, action.sessionId, ce => addUnsentMessage(ce, action));
const addUnsentMessage = (ce, action) => update(ce, {
	unsent: { $push: [{
		// These should match Message.ToMap in chatd,
		// as befit an "unsent message". Once it is
		// acked we fill in the rest in MESSAGESENT.
		aid: initialChatData.agentID,
		umid: action.umid,
		text: action.text
	}]},
	hasNewMessageFromSelf: { $set: true },
	hasNewMessageFromClient: { $set: false }
});

const chatMessageSent = (ces, action) => updateChat(ces, action.sessionId, ce => updateMessageSent(ce, action));
const updateMessageSent = (ce, action) => {
	let wasUnsent
	, newunsent = ce.unsent.filter(um => {
		if (um.umid == action.payload.umid) {
			wasUnsent = {...um};
			return false;
		}
		return true;
	})
	;

	if (!wasUnsent) {
		return ce;
	}

	// MESSAGESENT
	wasUnsent.id = action.payload.id;
	wasUnsent.sent = action.payload.sent;
	wasUnsent.sentHuman = action.payload.sentHuman;

	return update(ce, {
		unsent : { $set: newunsent },
		messages: { $push: [wasUnsent] },
		messageById: { $merge: { [wasUnsent.id]: wasUnsent }},
	});
}

const chatSetCurrentMessage = (state, action) => state.map(ce => {
	let cis = action.chatInputState[ce.sessionId];
	if(typeof cis === 'undefined' ||
		(cis && cis.inputValue == ce.ui.currentMessage)){
		return ce
	}
	return update(ce, {ui: { $set: { currentMessage: cis.inputValue }}});
});

const chatError = (ces, action) => {
	if (action.event == evtCHAT_NEW_MESSAGE) {
		// could not send message
		return updateChat(ces, action.sessionId, ce => updateCouldNotSend(ce, action));
	}
	// console.log("TODO handle CHAT_ERROR ",action);
	return ces;
}
const updateCouldNotSend = (ce, action) => {
	let newunsent = ce.unsent.map(um => {
		if (um.umid != action.umid) {
			return um;
		}
		return {...um, error: action.error};
	});
	return update(ce, { unsent: { $set: newunsent }});
}

const updateChatOwner = (chat, agentId, agentName) => {
	let role = chat.Role;
	if (agentId == initialChatData.agentID) {
		role = CHAT_crOwner;
	}
	return update(chat, {
		Role: { $set: role },
		errand: {
			data: {
				agentId: { $set: agentId },
				agent: { $set: agentName },
			}
		},
	});
}

const updateChatClosed = (st, o) => {
	if (o.isOwner || o.guestLeaving || o.isStale) {
		return markAsDead(st, o.expired);
	}

	let notice, dead;
	if (o.expired) {
		notice = I("This chat has expired.");
	} else if (o.closedBy) {
		notice = I("{NAME} has closed this chat")
			.replace("{NAME}", o.closedBy);
	} else if (o.needTag) {
		notice = I("Please tag this chat to close it");
	} else {
		notice = I("This chat has ended.");
	}

	return update(st, {
		dead: { $set: !o.needTag },
		expired: { $set: o.expired },
		notification: { $set: notice },
	});
}

const updateChat = (ces, sid, f) => ces.map(ce => {
	if (ce.sessionId != sid) {
		return ce;
	}
	return f(ce);
});

const updateArea = (ces, action) => updateChat(ces, action.sessionId, ce => updateChatArea(ce, action.areaId, action.areaName));
const updateChatArea = (chat, areaId, areaName) => update(chat, {
	errand: {
		data: {
			area: { $set: areaId },
			areaName: { $set: areaName },
		},
	},
});

const updateJoin = (ces, sid) => updateChat(ces, sid, ce => updateChatJoined(ce));
const updateChatJoined = chat => update(chat, { Role: { $set: CHAT_crGuest } });

const addChatFileArchive = (ces, action) => updateChat(ces, action.chat.sessionId, ce => addFileArchive(ce, action));
const addFileArchive= (ce, action) => {
	let fa = [...ce.files];
	$.each(action.files, (i,v) => {
		if (v.id != action.id) {
			return;
		}
		fa.push({...v, kind: 'archive'});
	});
	return update(ce, { files: { $set: fa } });
}

const removeChatFileArchive = (ces, action) => updateChat(ces, action.chat.sessionId, ce => removeFileArchive(ce, action));
const removeFileArchive = (ce, action) => {
	let fa = [];
	$.each(ce.files, (i,v) => {
		if (v.id == action.id) {
			return;
		}
		fa.push(v);
	});
	return update(ce, { files: { $set: fa } });
}

const isInAttachmentList = (list, item) => {
	for(let i=0; i < list.length; i++){
		if(list[i].id == item.id){
			return true;
		}
	}
	return false;
}
const addChatKnowledgeBaseFile = (ces, action) => updateChat(ces, action.chat.sessionId, ce => addKnowledgeBasefile(ce, action));
const addKnowledgeBasefile= (ce, action) => {
	let {attachments: libraryList} = action;
	let attachment_list = [...ce.files];
	let newList= [];
	if(libraryList){
		$.each(libraryList, (i,v) => {
			if(isInAttachmentList(attachment_list, v) == false) {
				newList.push({...v, kind: 'knowledgebase'});
			}
		});
		if(newList.length > 0) {
			return update(ce, {files: {$push: newList}});
		}
	}
	return ce;
}

const removeChatKnowledgeBaseFile = (ces, action) => updateChat(ces, action.chat.sessionId, ce => removeKnowledgeBasefile(ce, action));
const removeKnowledgeBasefile = (ce, action) => {
	let fa = [];
	$.each(ce.files, (i, v) => {
		if (v.id != action.id) {
			fa.push(v);
		}
	});
    return update(ce, { files: { $set: fa } });
}

const addChatFileUpload = (ces, action) => updateChat(ces, action.chat.sessionId, ce => addFileUpload(ce, action));
const addFileUpload = (ce, action) => {
	let fa = [...ce.files]
	, p = action.payload
	;
	fa.push({
		id: p.id,
		idc: p.idc,
		download: p.download,
		name: p.value,
		estSize: p.estSize,
		kind: 'upload',
		src: p.download,
	});
	return update(ce, { files: { $set: fa } });
}

const chatRemoveOneAttachment = (ces, action) => updateChat(ces, action.chat.sessionId, ce => removeOneAttachment(ce, action));
const removeOneAttachment = (ce, action) => {
	let fa = [];
	$.each(ce.files, (i, v) => {
		if (v.id != action.id) {
			fa.push(v);
		}
	});
	return update(ce, { files: { $set: fa }});
}

const chatUpdateTags = (ces, sid, tags) => updateChat(ces, sid, ce => updateTags(ce, tags));
const updateTags = (ce, tags) => update(ce, { tags: { $set: tags }});

const chatUpdatePreview = (ces, sid, previewType, preview) => updateChat(ces, sid, ce => updatePreview(ce, previewType, preview));
const updatePreview = (ce, previewType, preview) => update(ce, {
	previewType: { $set: previewType },
	preview: { $set: preview },
});

const chatUpdateParticipants = (ces, sid, agentIds, invitedAgentIds) => updateChat(ces, sid, ce => updateParticipants(ce, agentIds, invitedAgentIds));
const updateParticipants = (ce, agentIds, invitedAgentIds) => update(ce, {
	// set undead just in case agent was removed earlier
	dead: { $set: false },
	AgentIds: { $set: agentIds },
	InvitedAgentIds: { $set: invitedAgentIds },
});

const chatUpdateClientIPAddress = (ces, sid, addr) => updateChat(ces, sid, ce => updateClientIPAddress(ce, addr));
const updateClientIPAddress = (ce, addr) => update(ce, { ClientIP: { $set: addr }});

// agent's other browser has "seen" these messages
const chatMessageSeen = (ces, sid, ids) => updateChat(ces, sid, ce => updateAcked(ce, ids, 'seen'));
// client has received these messages
const chatMessageAcked = (ces, sid, ids) => updateChat(ces, sid, ce => updateAcked(ce, ids, 'read'));
const updateAcked = (ce, ids, field) => {
	let i
	, j
	, changed = false
	, needUpdate = {}
	;

	for (i=0;i<ids.length;i++) {
		needUpdate[ids[i]] = true;
	}

	let changedMessage = {};
	let messageById = ce.messageById;
	for(i=ce.messages.length-1;i>=0;i--) {
		let mid = ce.messages[i].id;
		if(typeof mid === 'undefined'){
			continue;
		}
		if (!needUpdate[mid]) {
			continue;
		}
		changedMessage[mid] = {[field]: true};
		messageById = update(messageById, {
			$merge: {
				[mid]: update(messageById[mid], { [field]: { $set: true } }),
			}
		});
		if (!changed) {
			changed = true;
		}
	}
	if (changed) {
		ce = update(ce, {
			messages: {
				$set: $.map(ce.messages, (v) => {
					if (!changedMessage[v.id]) {
						return v;
					}
					return update(v, { [field]: { $set: true }});
				}),
			},
			messageById: { $set: messageById },
		});
		ce.unReadMsgs = countUnseen(ce.messages);
	}

	return ce;
}

const chatUpdateAddAgent = (ces, sid, response) => updateChat(ces, sid, ce => updateAddAgent(ce, response));
const updateAddAgent = (ce, response) => update(ce, {
	addAgent: { $set: {
		list: response.list,
		error: response.error,
	}},
});

const chatSetIPBlocked = (ces, sid, blocked) => updateChat(ces, sid, ce => updateIPBlocked(ce, blocked));
const updateIPBlocked = (ce, blocked) => update(ce, { IPBlocked: { $set: blocked }});

//update chat summary
const updateSummary = (ce, summary) => update(ce, {
	summary: {$set: summary}
});

//update video call state in chat errand list? but why we set it separately as the CurrentChatErrand?
const chatSetVideoCall = (ces, sid, status) => updateChat(ces, sid, ce => updateVideoCall(ce, status));
const updateVideoCall = (ce, status, fromAgent) => update(ce, {
	videoCall: { $set: status },
	videoCallFromAgent: {$set: fromAgent}
});
const chatSetVideoCallInProgress = (ces, sid, status) => updateChat(ces, sid, ce => updateVidCallInProgress(ce, status));
const updateVidCallInProgress = (ce, status) => update(ce, {
	videoCallInProgress: {$set: status}
});
const chatSetVideoCallIsRecording = (ces, sid, status) => updateChat(ces, sid, ce => updateVidCallIsRecording(ce, status));
const updateVidCallIsRecording = (ce, status) => update(ce, {
	videoIsRecording: {$set: status}
});
const chatSetVideoCallClientReady = (ces, sid, status) => updateChat(ces, sid, ce => updateVidCallClientReady(ce, status));
const updateVidCallClientReady = (ce, status) => update(ce, {
	videoCallClientReady: {$set: status}
});
const chatSetCobrowsing = (ces, sid, status) => updateChat(ces, sid, ce => updateCoBrowseStatus(ce, status));
const updateCoBrowseStatus = (ce, status) => update(ce, {
	isOnCoBrowsing: {$set: status}
});

// A message is "seen" when we finally display it to
// the agent. If the new message belongs to a chat that
// is not the currently selected one then its "seen"
// state should not be changed. Note that this is
// different from what we have in 4.5. In 4.5 the
// message is considered "seen" regardless of whether
// the agent has opened the corresponding chat tab.
const chatSetSeen = (ces, sid, ids) => updateChat(ces, sid, ce => updateMessageSeen(ce, ids)); // Mak - this seems reduntant with chatMessageSeen?
const updateMessageSeen = (ce, ids) => updateAcked(ce, ids, 'seen');

// Update of chat session response to video call request
const handleShowVideoChat = (ce, status, fromAgent) => update(ce, {
	videoCall: { $set: status },
	videoCallFromAgent: {$set: fromAgent}
});

// Flag that determine whether video call is in session
const handleVideoInProgress = (ce, status) => update (ce, {
	videoCallInProgress: { $set: status },
});

// Flag that determine whether co browsing in chat is in session
const handleCoBrowsingStatusUpdate = (ce, status) => update (ce, {
	isOnCoBrowsing: { $set: status },
});

// Flag that determine whether video call is still recording
const handleVideoIsRecording = (ce, status) => update (ce, {
	videoIsRecording: { $set: status },
});

// Flag that determine whether client is ready for video call
const handleVideoClientReady = (ce, status) => update (ce, {
	videoCallClientReady: { $set: status },
});

// Flag that determine whether client is muted/unmute their mic during video call
const handleClientToggleAudio = (ce, status) => update (ce, {
	videoCallClientMuted: { $set: status }
});
const handleClientToggleWebCam = (ce, status) => update (ce, {
	videoCallClientOffWebCam: { $set: status }
});

// Last video call chat session's timer
const handleUpdateVidCallTimer = (ce, timer) => update (ce, {
	videoCallLastTimer: { $set: timer }
});

// Full screen video chat toggle
const handleVideoFullScreen = (ce, toggle) => update (ce, {
	videoCallFullScreen: { $set: toggle }
});

// Video bg extraction status
const handleVideoExtractionStatus = (ce, status) => update (ce, {
	videoExtractionDone: { $set: status }
});

const updateGettingCandidates = (ce, status) => update(ce, { gettingCandidates: { $set: status }});

const selectChatErrand = (state, action) => {
	const {id, select} = action.payload;
	let newState = [...state]
	$.each(newState, (i, chat) => {
		if (chat.errand && chat.errand.id == id) {
			newState = update(newState, {[i]: {ui: {$merge: {selected: select}}}});
			return false;
		}
	});
	return newState;
}

const selectAllChatErrand = (state, action) => {
	const select = action.payload;
	let newState = [...state]
	$.each(newState, (i, chat) => {
		if (chat.errand && chat.ui.selected != select) {
			newState = update(newState, {[i]: {ui: {$merge: {selected: select}}}});
		}
	});
	return newState;
}

const openChatErrand = (state, action) => {
	const { id } = action.payload;
	if (id <= 0) {
		return state;
	}
	let newState = [...state]
	$.each(newState, (i, chat) => {
		if (chat.errand && chat.errand.id == id) {
			newState = update(newState, {[i]: {ui: {$merge: {selected: true}}}});
		} else {
			newState = update(newState, {[i]: {ui: {$merge: {selected: false}}}});
		}
	});
	return newState;
}

const chatListAddInternalComment = (state, action) => {
	let newState = [...state]
	if(action.payload.noteId == 0) {
		$.each(newState, (i, chat) => {
			if(chat.sessionId == action.payload.sid){
				let newNotes = [];
				if(chat.errand.notes){
					$.each(chat.errand.notes, (j, note) => {
						newNotes.push(note);
					});
				}
				newNotes.push(action.payload.newNote);
				newState = update(newState, {[i]: {errand:
					{notes: {$set: newNotes}}}});
			}
		});
	}
	return newState
}

const chatListUpdateInternalComment = (state, action) => {
	const newNote = action.payload.newNote;
	let newState = [...state]
	let newText = newNote.note;
	let newAttachments = newNote.attachments;
	$.each(newState, (i, chat) => {
		if(chat.sessionId == action.payload.sid){
			if(chat.errand.notes){
				$.each(chat.errand.notes, (j, note) => {
					if(note.id == newNote.id){
						newState = update(newState, {[i]: {errand: {notes: {[j]: {
							note: {$set:newText},
							attachments: {$set:newAttachments}
						}}}}});
					}
				});
			}
		}
	});
	return newState
}

const chatListDeleteInternalComment = (state, action) => {
	const ap = action.payload;
	let newState = [...state]
	if(ap.note != 0) {
		$.each(newState, (i, chat) => {
			if(chat.errand.data.id == ap.errand){
				let newNotes = [];
				if(chat.errand.notes){
					$.each(chat.errand.notes, (j, note) => {
						if(note.id != ap.note){
							newNotes.push(note);
						}
					});
				}
				newState = update(newState, {[i]: {errand:
					{notes: {$set: newNotes}}}});
			}
		});
	}
	return newState
}

const chatAddInternalComment = (chat, action) => {
	const ap = action.payload;
	let cNotes = chat.errand.notes;
	if(ap.noteId == 0){
		let newNotes = [];
		if(typeof cNotes !== "undefined"){
			for(let i=0; i < cNotes.length; i++){
				newNotes.push(cNotes[i]);
			}
		}
		newNotes.push(ap.newNote);
		return update(chat, {errand: {notes:{$set: newNotes}}});
	}
	return chat;
}

const updateInternalComment = (chat, action) => {
	const ap = action.payload;
	const newNote = ap.newNote;
	let cNotes = chat.errand.notes;
	for(let j=0; j < cNotes.length; j++){
		if(cNotes[j].id == newNote.id){
			chat = update(chat, {errand: {notes:{[j]: {$set: newNote}}}});
			break;
		}
	}
	return chat;
}

const chatDeleteInternalComment = (chat, action) => {
	const ap = action.payload;
	let cNotes = chat.errand.notes;
	if(ap.note != 0){
		let newNotes = [];
		for(let i=0; i < cNotes.length; i++){
			if(cNotes[i].id != ap.note){
				newNotes.push(cNotes[i]);
			}
		}
		return update(chat, {errand: {notes:{$set: newNotes}}});
	}
	return chat;
}

// V5 chat events
let errandListChat = (state = emptyArray, action) => {
	if (action.type == WS_EVENT) {
		return wsEventReducer(state, action);
	} else if (action.type == CHAT_ADD_UNSENT_MESSAGE) {
		return chatAddUnsentMessage(state, action);
	} else if (action.type == CHAT_SET_CURRENT_MESSAGE) {
		return chatSetCurrentMessage(state, action);
	} else if (action.type == CHAT_MESSAGE_SENT) {
		return chatMessageSent(state, action);
	} else if (action.type == CHAT_ERROR) {
		return chatError(state, action);
	} else if (action.type == FORWARD_CHAT_TO_AREA_SUCCEEDED) {
		if (action.response.isStale) {
			return removeChatErrand(state, action.response.sessionId);
		}
		return updateArea(state, action);
	} else if (action.type == ECHAT_ANSWER_INVITE) {
		if (action.chat.isStale) {
			return removeChatErrand(state, action.chat.sessionId);
		}
		return updateJoin(state, action.chat.sessionId);
	} else if (action.type == CHAT_ADD_FILE_ARCHIVE) {
		return addChatFileArchive(state, action);
	} else if (action.type == CHAT_REMOVE_FILE_ARCHIVE) {
		return removeChatFileArchive(state, action);
	} else if (action.type == CHAT_ADD_KB_FILE) {
		return addChatKnowledgeBaseFile(state, action);
	} else if (action.type == CHAT_REMOVE_KB_FILE) {
		return removeChatKnowledgeBaseFile(state, action);
	} else if (action.type == UPLOAD_CHAT_ATTACHMENT) {
		return addChatFileUpload(state, action);
	} else if (action.type == CHAT_SENT_ONE_ATTACHMENT) {
		return chatRemoveOneAttachment(state, action);
	} else if (action.type == CHAT_UPDATE_TAGS) {
		return chatUpdateTags(state, action.chat.sessionId, action.Tags);
	} else if (action.type == CHAT_SET_CANDIDATES) {
		return chatUpdateAddAgent(state, action.chat.sessionId, action.response);
	} else if (action.type == CHAT_IP_BLOCKED) {
		return chatSetIPBlocked(state, action.chat.sessionId, true);
	} else if (action.type == SELECT_ERRAND_IN_ERRAND_LIST) {
		return selectChatErrand(state, action);
	} else if (action.type == SELECT_ALL_ERRANDS_IN_ERRAND_LIST) {
		return selectAllChatErrand(state, action);
	} else if (action.type == SET_CURRENT_ERRAND) {
		return openChatErrand(state, action);
	} else if (action.type == CHAT_SET_SEEN) {
		return chatSetSeen(state, action.chat.sessionId, action.ids);
	} else if (action.type == CHAT_UPDATE_INTERNAL_COMMENT) {
		return chatListUpdateInternalComment(state, action);
	} else if (action.type == CHAT_ADD_INTERNAL_COMMENT) {
		return chatListAddInternalComment(state, action);
	} else if (action.type == CHAT_DELETE_INTERNAL_COMMENT) {
		return chatListDeleteInternalComment(state, action);
	} else if (action.type == CHAT_VIDEO_AGENT_SHOW) {
		return chatSetVideoCall(state, action.payload.sessionId, action.payload.show, action.payload.fromAgent);
	} else if (action.type == CHAT_VIDEO_CALL_INPROGRESS) {
		return chatSetVideoCallInProgress(state, action.payload.sessionId, action.payload.status);
	} else if (action.type == CLIENT_REJECT_VIDEO_CALL) {
		if(action.payload) {
			return chatSetVideoCallInProgress(state, action.payload.sessionId, false);
		}
	} else if (action.type == CHAT_AGENT_RECORD_VID) {
		return chatSetVideoCallIsRecording(state, action.payload.sessionId, action.payload.status);
	} else if (action.type == CHAT_VIDEO_CLIENT_READY) {
		return chatSetVideoCallClientReady(state, action.payload.sessionId, action.payload.status);
	} else if(action.type == CHAT_CLIENT_COBROWSE_OFFER) {
		return chatSetCobrowsing(state, action.payload.sessionId, action.payload.status);
	}
	return state;
};

const chatListDataSyncReducer = createSetupCondtionReducer(
	(chats, action, condition, updater) => map(chats, chat => {
		if (condition(chat, action)) {
			return updater(chat)
		}
		return chat
	}),
	CHAT_LIST
)

const _errandListChatReducers = {
	[DATA_SYNC]: chatListDataSyncReducer
}

errandListChat = reduceReducers(
	errandListChat,
	createReducer(emptyArray, _errandListChatReducers)
)

let currentChatReducer = (chat = null, action) => {
	if (action.type == SET_CURRENT_ERRAND) {
		if (action.payload.chat) {
			return action.payload.chat;
		}
		return null;
	}

	if (chat == null) {
		return chat;
	}

	if (action.type == "WS_EVENT") {
		return wsEventReducerCurrentChat(chat, action);
	}

	if(action.type == CHAT_VIDEO_AGENT_SHOW) {
		return handleShowVideoChat(chat, action.payload.show, action.payload.fromAgent);
	}else if (action.type == CHAT_VIDEO_CALL_INPROGRESS) {
		return handleVideoInProgress(chat, action.payload.status);
	}else if (action.type == CHAT_VIDEO_CALL_FULLSCREEN) {
		return handleVideoFullScreen(chat, action.payload)
	} else if (action.type == CHAT_VIDEO_CALL_CLIENT_MUTED) {
		return handleClientToggleAudio(chat, action.payload)
	} else if (action.type == CHAT_VIDEO_CALL_CLIENT_WEBCAM) {
		return handleClientToggleWebCam(chat, action.payload)
	} else if (action.type == CHAT_VIDEO_UPDATE_TIMER) {
		return handleUpdateVidCallTimer(chat, action.payload)
	} else if (action.type == CLIENT_REJECT_VIDEO_CALL) {
		if(action.payload) {
			return handleVideoInProgress(chat, false);
		}
	} else if (action.type == CHAT_AGENT_RECORD_VID) {
		if(action.payload) {
			return handleVideoIsRecording(chat, action.payload.status);
		}
	} else if (action.type == CHAT_VIDEO_CLIENT_READY) {
		return handleVideoClientReady(chat, action.payload.status);
	} else if(action.type == CHAT_CLIENT_COBROWSE_OFFER) {
		return handleCoBrowsingStatusUpdate(chat, action.payload.status);
	} else if(action.type == CHAT_VIDEO_BG_EXTRACTION_STATUS) {
		return handleVideoExtractionStatus(chat, action.payload);
	}

	if (action.type == CHAT_UPDATE_INTERNAL_COMMENT) {
		return updateInternalComment(chat, action)
	} else if (action.type == CHAT_ADD_INTERNAL_COMMENT) {
		return chatAddInternalComment(chat, action)
	} else if (action.type == CHAT_DELETE_INTERNAL_COMMENT) {
		return chatDeleteInternalComment(chat, action)
	}


	if (!action.chat || action.chat.sessionId != chat.sessionId) {
		return chat;
	}

	if (action.type == FORWARD_CHAT_TO_AREA_SUCCEEDED) {
		return updateChatArea(chat, action.areaId, action.areaName);
	} else if (action.type == ECHAT_ANSWER_INVITE) {
		return updateChatJoined(chat);
	} else if (action.type == CHAT_ADD_FILE_ARCHIVE) {
		return addFileArchive(chat, action);
	} else if (action.type == CHAT_REMOVE_FILE_ARCHIVE) {
		return removeFileArchive(chat, action);
	} else if (action.type == CHAT_ADD_KB_FILE) {
		return addKnowledgeBasefile(chat, action);
	} else if (action.type == CHAT_REMOVE_KB_FILE) {
		return removeKnowledgeBasefile(chat, action);
	} else if (action.type == UPLOAD_CHAT_ATTACHMENT) {
		return addFileUpload(chat, action);
	} else if (action.type == CHAT_SENT_ONE_ATTACHMENT) {
		return removeOneAttachment(chat, action);
	} else if (action.type == CHAT_UPDATE_TAGS) {
		return updateTags(chat, action.Tags);
	} else if (action.type == CHAT_SET_CANDIDATES) {
		let s = updateGettingCandidates(chat, false);
		return updateAddAgent(s, action.response);
	} else if (action.type == CHAT_GETTING_CANDIDATES) {
		return updateGettingCandidates(chat, true);
	} else if (action.type == CHAT_DONE_GETTING_CANDIDATES) {
		return updateGettingCandidates(chat, false);
	} else if (action.type == CHAT_IP_BLOCKED) {
		return updateIPBlocked(chat, true);
	} else if (action.type == CHAT_SET_SEEN) {
		return updateMessageSeen(chat, action.ids)
	} else if (action.type == CHAT_SET_SUMMARY) {
		return updateSummary(chat, action.chat.summary);
	}

	return chat;
}

const createCurrentChatReducer = reducers => {
	const _currentChatReducer = createReducer(null, reducers)
	return (state, action) => {
		if (!state) {
			return state
		}
		return _currentChatReducer(state, action)
	}
}

const currentChatDataSyncReducer = createSetupCondtionReducer(
	(chat, action, condition, updater) => {
		if (!condition(chat, action)) {
			return chat
		}
		return updater(chat)
	},
	CURRENT_CHAT
)

const _currentChatReducers = {
	[DATA_SYNC]: currentChatDataSyncReducer
}

currentChatReducer = reduceReducers(
	currentChatReducer,
	createCurrentChatReducer(_currentChatReducers)
)

let chatForwardArea = (state = {}, action) => {
	switch (action.type) {
	case FETCHING_CHAT_AREA_CANDIDATES:
		return {progress: I("Retrieving areas ..."), ready: false};
	case FETCH_CHAT_AREA_CANDIDATES_FAILED:
		return {progress: I("Could not retrieve areas"), ready: false};
	case FETCHED_CHAT_AREA_CANDIDATES:
		return {areas: action.response, ready: true};
	case FORWARD_CHAT_TO_AREA_STARTED:
		return {forwarding: true};
	case FORWARD_CHAT_TO_AREA_FAILED:
		return {forwarding: false};
	case FORWARD_CHAT_TO_AREA_SUCCEEDED:
		return {};
	}
	return state;
};

let chatForwardAgent = (state = {}, action) => {
	switch (action.type) {
	case FETCHING_CHAT_AGENT_CANDIDATES:
		return {progress: I("Retrieving agents ..."), ready: false};
	case FETCH_CHAT_AGENT_CANDIDATES_FAILED:
		return {progress: I("Could not retrieve agent"), ready: false};
	case FETCHED_CHAT_AGENT_CANDIDATES:
		return {agents: action.response, ready: true};
	case FORWARD_CHAT_TO_AGENT_STARTED:
		return {forwarding: true};
	case FORWARD_CHAT_TO_AGENT_SUCCEEDED:
		return {};
	}
	return state;
}

export {
	errandListChat,
	chatForwardArea,
	chatForwardAgent,
	currentChatReducer,
};
